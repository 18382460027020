// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Tour from 'reactour'
import { observer, inject } from 'mobx-react';

import { loginStore, LoginStore } from '../../stores/login-store';
import { ProfileStore } from '../../stores/profile-store';
import { FeedWallStore } from '../../stores/feed-wall-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { ConfigurationStore } from '../../stores/configuration-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { UserAccountStore } from '../../stores/user-account-store';
import { PolicyDocsStore } from '../../stores/policy-docs-store';
import { CreditTransferInitiateStore } from '../../stores/credit-transfer-initiate-store';

import { TextInput, PasswordInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";
import { TermsOfServiceText, PrivacyPolicyText } from "../policy-documents/documents";


export const TermsOfServiceModal: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Terms of Service</h2>
        <p className="small-font">
          These are terms of use.
        </p>
        <TermsOfServiceText></TermsOfServiceText>
      </p>
    </div>
  );
};

export const PrivacyPolicyModal: React.FC = () => {
  return (
      <div className="readMoreContent">
        <p>
          <h2>Privacy Policy</h2>
          <p className="small-font">
            These are privacy policy.
          </p>
          <PrivacyPolicyText></PrivacyPolicyText>
        </p>
      </div>
  );
};

export const AddToHomeScreenBookmark: React.FC = () => {
  return (
      <div className="readMoreContent">
        <p>
          <h2>How to add the Freemason Travellers Guide (FTG) to the home screen of your smartphone or tablet</h2>
          <p className="small-font">
            You can use FTG on your smartphone or tablet by visiting the <a href="https://freemason-alpha.com">Freemason Travellers Guide</a> URL
            in your web browser app, but you can also add FTG as a web app to your
            home screen for quick access.
          </p>

          <p className="small-font">
            <b>iOS (iPhone, iPad, iPod)</b>
            <br/>
            To add Freemason Travellers Guide as a web app on your iOS device home screen, please follow these steps:
            <ol>
              <li>Open Safari and navigate to the Freemason Travellers Guide URL</li>
              <li>Tap on the share icon in the bottom toolbar.</li>
              <li>When share options appear, tap on 'Add to home screen'.</li>
              <li>Optionally, change the shortcut name</li>
              <li>See the shortcut appear.</li>
            </ol>
          </p>
          <img className="width-height-hundred" src="homescreen-steps-ios.png" />
          <p className="small-font">
            The FTG icon will appear on your home screen like any other app, so you can drag it around and put it where you like.
          </p>

          <p className="small-font">
            <b>Android</b>
            <br/>
            To add BlogIn as a web app on your Android device, please complete the following steps:
            <ol>
              <li>Launch Chrome for Android and navigate to the Freemason Travellers Guide URL</li>
              <li>Tap the Chrome menu button on the top right</li>
              <li>Tap Add to home screen.</li>
              <li>You’ll be able to enter a name for the shortcut.</li>
              <li>Chrome will add it to your home screen.</li>
            </ol>
          </p>
          <img className="width-height-hundred" src="homescreen-steps-android.png" />
          <p className="small-font">
            You will find FTG icon on your home screen and when you tap the icon, Chrome will load the Freemason Travellers Guide as a “web app” so it will get its own entry in the app switcher and won’t have any browser interface getting in the way.
          </p>
          <p className="small-font">
            Other popular Android browsers also offer this feature. For example, Firefox for Android can do this if you tap the menu button, tap the Page option, and tap Add to Home Screen.
          </p>


        </p>
      </div>
  );
};


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  registrationStep: () => void;
  profileStep: () => void;
  loginStep: () => void;
  addLodgeStep: () => void;
  forgotPasswordStep: () => void;
  changePasswordStep: () => void;
  feedWallStep: () => void;
  lodgeDetailsStep: () => void;
  creditTransferStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  getFirstLogInState: () => boolean;
  getLodgeQRCode: () => string;
  getReceivalCode: () => string;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
  configurationStore?: ConfigurationStore;
  profileStore?: ProfileStore;
  feedWallStore?: FeedWallStore;
  policyDocsStore?: PolicyDocsStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  userAccountStore?: UserAccountStore;
  creditTransferInitiateStore?: CreditTransferInitiateStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  isTourOpen: boolean;
  termsOfServiceModalOpen: boolean;
  privacyPolicyModalOpen: boolean;
  addToHomeScreenBookMarkOpen: boolean;
  username: string;
  password: string;
}

@inject('loginStore',
    'thankYouStore',
    'contributeStore',
    'configurationStore',
    'profileStore',
    'feedWallStore',
    'policyDocsStore',
    'lodgeDetailsStore',
    'userAccountStore',
    'creditTransferInitiateStore')
@observer
export class Login extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      isTourOpen: false,
      termsOfServiceModalOpen: false,
      privacyPolicyModalOpen: false,
      addToHomeScreenBookMarkOpen: false,
      username: '',
      password: '',
    };
    this.setTourIsClosed = this.setTourIsClosed.bind(this);

    //this.buttonClicked = this.buttonClicked.bind(this);
  }

  //componentDidMount(){
  //  setTimeout(this.buttonClicked, 5000);
  //}

  //async buttonClicked() {
  //  if (loginStore.isLoggedIn == false) {
  //    let t = Cookies.get("test_logged_in");
  //    if (t == "true") {
  //      if (this.props.loginStore.loginInput.username != "" && this.props.loginStore.loginInput.password != "") {
  //        console.log("clicked");
  //        await this.handleLoginClick(this.props.loginStore, this.props.profileStore, this.props.feedWallStore);
  //      }
  //    }
  //  }
  //}

  setTourIsOpen() {
    this.setState({ isTourOpen: true });
  }
  setTourIsClosed() {
    this.setState({ isTourOpen: false });
  }

  handleLoginClick = async (loginStore, profileStore, feedWallStore) => {
    this.props.setLoadingTrue();

    toast.info("Verifying credentials");
    loginStore.validate();
    const success = loginStore.checkValidation();
    console.log('----');
    console.log(success);
    console.log('----');
    if (success) {
      let t = await loginStore.loginUser();
      console.log('check login user');
      console.log(t);
      if (t) {
        if (loginStore.isLoggedIn) {
          if (loginStore.change_password) {
            toast.warning("Change your password!");
            this.props.changePasswordStep();
          } else {
            // load the configurations
            await this.props.configurationStore.getConfigurations();

            await profileStore.getUserWatchList(loginStore.miniToken);
            await profileStore.getUserProfileFeed(loginStore.miniToken);

            await feedWallStore.getUserFeed(loginStore.miniToken);


            toast.success("Welcome back!");
            //this.props.profileStep();
            //this.props.feedWallStep();
            let code = this.props.getLodgeQRCode();
            if (code != '') {
              //this.props.profileStep();
              try {
                //"TWVscm9zZV83MDM0XzE="
                let code_parts = atob(decodeURI(code)).split('_');
                let lodge_name = code_parts[0];
                let lodge_number = code_parts[1];
                let lodge_degree = code_parts[2];

                this.props.lodgeDetailsStore.clearLodgeInfo();
                await this.props.lodgeDetailsStore.setLodgeInfo(lodge_name, lodge_number, lodge_degree, loginStore.miniToken, loginStore.lodges);
                this.props.lodgeDetailsStep();
              } catch (error) {
                console.error(error);
                //return false;
                this.props.feedWallStep();
              } finally {
                this.props.setLoadingFalse();
                //this.props.feedWallStep();
                //this.props.lodgeDetailsStep();
              }
            } else {
              //this.props.setLoadingFalse();

              let rcode = this.props.getReceivalCode();
              if (rcode != '') {
                try {
                  // validate receivalcode
                  // get user name from receivalcode
                  let res = await this.props.userAccountStore.validateReceivalCode(rcode, loginStore.miniToken);

                  console.log('ASDFAWERFASDFASDF');
                  console.log(rcode);
                  console.log('res = ASDFAWERFASDFASDF');
                  console.log(res);
                  if (res['status']) {
                    this.props.creditTransferInitiateStore.receival_code = rcode;
                    this.props.creditTransferInitiateStore.username = res['username'];
                    this.props.creditTransferStep();
                  } else {
                    this.props.feedWallStep();
                  }
                } catch (error) {
                  console.error(error);
                  this.props.feedWallStep();
                } finally {
                  this.props.setLoadingFalse();
                }
              } else {
                this.props.feedWallStep();
              }
            }
          }
        } else {
          //this.props.loginStep();
          //notify();
          //this.props.setLoadingFalse();
          toast.error("Incorrect login details!");
        }
      }
      else {
        toast.error("Not connected to the Internet!");
      }
    }
    this.props.setLoadingFalse();
  }

  handleTermsClick = () => {
    console.log("handleTermsClick");
    console.log("this.props.policyDocsStore");
    console.log(this.props.policyDocsStore);
    console.log("=======this.props.policyDocsStore");
    this.props.policyDocsStore.toggleTermsModal; // Ensure this calls the function

    this.props.policyDocsStore.termsModalOpen = !this.props.policyDocsStore.termsModalOpen;
    this.props.policyDocsStore.termsModalError = false;
    this.props.policyDocsStore.termsModalSuccess = false;
  };
  handlePrivacyClick = () => {
    this.props.policyDocsStore.togglePrivacyModal(); // Ensure this calls the function
  };
  render() {
    if (!this.props.loginStore) {
      return;
    }

    const loginStore = this.props.loginStore;

    //console.log("------- loginStore.lodgeResults -------");
    //console.log(loginStore.lodgeResults);
    //console.log("---------------------------------------");

    const loginInput = loginStore.loginInput;
    const validationResult = loginStore.validationResult;

    const profileStore = this.props.profileStore;
    const feedWallStore = this.props.feedWallStore;

    const policyDocsStore = this.props.policyDocsStore;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;

    let firstLogIn = this.props.getFirstLogInState();

    let steps = [
      {
        selector: '.basic-step',
        content: 'You will need a referral code to register an account. Once registered you will be able to login.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.enter-email-step',
        content: 'Enter the email address you used to register your account.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.enter-password-step',
        content: 'Enter the password you set when setting up your account.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.click-login-step',
        content: 'Click login',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.forgot-password-step',
        content: 'If you have forgotten your password, click here to reset it.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.terms-of-service-step',
        content: 'You can find the Terms of Service here.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.privacy-policy-step',
        content: 'You can find the Privacy Policy here.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.basic-step',
        content: 'If you still having issues logging into the system, please contact support@freemason-alpha.com ',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    // Define the new step object
    const homeScreenStep = {
      selector: '.add-to-homescreen-step',
      content: 'Finally you might want to save the app to your home screen or bookmark it for easy access.',
      style: {
        borderRadius: '5px',
      }
    };

    if (firstLogIn) {
      steps.push(homeScreenStep);
    }

    return (
        <div className="basic-step">
          <Card>
            <CardBody>
              <Row>
                <Col sm="12">
                  <h1>Login</h1>
                </Col>
              </Row>

              <p className="small-font"><i>To see that none but masons are present. 2B1Ask1</i></p>

              <Row>
                <Col className="enter-email-step">
                  <TextInput
                      id="username"
                      label="Email"
                      value={this.state.username}
                      onChange={e => {
                          loginInput.username = e.target.value;
                          this.setState({username: e.target.value});
                        }
                      }
                      invalid={!!validationResult.username}
                      validationMessage={validationResult.username}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="enter-password-step">
                  <PasswordInput
                      id="password"
                      label="Password"
                      value={this.state.password}
                      onChange={e => {
                          loginInput.password = e.target.value;
                        this.setState({password: e.target.value});
                        }
                      }
                      onKeyPress={async (event) => {
                        if (event.key === 'Enter') {
                          await this.handleLoginClick(loginStore, profileStore, feedWallStore);
                        }
                      }}
                      invalid={!!validationResult.password}
                      validationMessage={validationResult.password}
                  />
                </Col>
              </Row>


              {firstLogIn ? (
                  <div>
            <span className="small-font-italic">
              <p>Congratulations on successfully registering with the Freemason Travellers Guide!</p>
              <p>We're thrilled to welcome you to our community!</p>
              <p><b>To get started, please use the random password you received via email to log in.</b></p>
              <p>Thank you for choosing Freemason Travellers Guide. We look forward to providing you with an exceptional experience!</p>
            </span>

                    <div className="row-justified-and-centered-flex">
                      <Button className="add-to-homescreen-step" color="" onClick={async () => {
                        // Button to display steps to add to home screen
                        this.setState({ addToHomeScreenBookMarkOpen: true })
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Bookmark--Streamline-Core" height="14" width="14"><desc>Bookmark Streamline Icon: https://streamlinehq.com</desc><g id="bookmark--bookmarks-tags-favorite"><path id="Union" fill="#8fbffa" d="M4 0c-0.39782 0 -0.77936 0.158035 -1.06066 0.43934C2.65804 0.720644 2.5 1.10218 2.5 1.5v12c0 0.2022 0.12182 0.3845 0.30866 0.4619 0.18684 0.0774 0.40189 0.0347 0.54489 -0.1083L7 10.2071l3.6464 3.6465c0.143 0.143 0.3581 0.1857 0.5449 0.1083 0.1869 -0.0774 0.3087 -0.2597 0.3087 -0.4619v-12c0 -0.39783 -0.158 -0.779356 -0.4393 -1.06066C10.7794 0.158035 10.3978 0 10 0H4Z" stroke-width="1"></path></g></svg>
                        <span className=""> Add to homescreen</span>
                      </Button>
                    </div>
                    <br/>
                  </div>
              ): ''}

              <div id='navigation_buttons'>
                <Button className="click-login-step" color="" disabled={loginStore.isLoading} onClick={async () => {
                  await this.handleLoginClick(loginStore, profileStore, feedWallStore);
                }}>
                  <Icon id="login" text="Login"></Icon>
                </Button>

                <Button className="forgot-password-step" color="" onClick={async () => {
                  //toast.error("Forgot your password!");
                  this.props.forgotPasswordStep();
                }}>
                  <Icon id="forgot-password" text="Forgot Password"></Icon>
                </Button>

                <Button color="" onClick={async () => {
                  this.setTourIsOpen();
                }}>
                  <Icon id="help" text="Help"></Icon>
                </Button>
              </div>
              <br/>
              <div className="row-justified-and-centered small-font">
                <span className="policy-docs terms-of-service-step" onClick={() =>
                  {
                    //this.props.policyDocsStore.toggleTermsModal();
                    //this.handleTermsClick();
                    this.setState({termsOfServiceModalOpen: true});
                  }
                }>Terms of Service</span>
                <span> * </span>
                <span className="policy-docs privacy-policy-step" onClick={() =>
                  {
                    //this.props.policyDocsStore.togglePrivacyModal();
                    //this.handlePrivacyClick();
                    this.setState({privacyPolicyModalOpen: true});
                  }
                }>Privacy Policy</span>
              </div>
            </CardBody>
          </Card>

          <Modal isOpen={this.state.termsOfServiceModalOpen}>
            <ModalHeader
                toggle={() => this.setState({ termsOfServiceModalOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <TermsOfServiceModal />
            </ModalBody>
            <ModalFooter>
              <Button
                  color="primary"
                  onClick={() => this.setState({ termsOfServiceModalOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.privacyPolicyModalOpen}>
            <ModalHeader
                toggle={() => this.setState({ privacyPolicyModalOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <PrivacyPolicyModal />
            </ModalBody>
            <ModalFooter>
              <Button
                  color="primary"
                  onClick={() => this.setState({ privacyPolicyModalOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.addToHomeScreenBookMarkOpen}>
            <ModalHeader
                toggle={() => this.setState({ addToHomeScreenBookMarkOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <AddToHomeScreenBookmark />
            </ModalBody>
            <ModalFooter>
              <Button
                  color="primary"
                  onClick={() => this.setState({ addToHomeScreenBookMarkOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Tour
              steps={steps}
              isOpen={this.state.isTourOpen}
              onRequestClose={this.setTourIsClosed}
              showNavigation={false}
          />

        </div>
    );
  }
}

export default Login;
