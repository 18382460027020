import { observable, action } from 'mobx';
import { Achievements } from '../domain/achievements';
import api from '../api';

export class AchievementsStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];
  @observable achievementResults: Partial<Achievements> = {
    achievement_contributions: [],
    achievement_levels: []
  };

  constructor() {}


  @action
  getAchievementsStructureData = async (loginStore) => {
    //this.page = 0;
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getAchievementsStructureData(dat, loginStore.authToken);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAchievementsStructureData =======');
      console.log(result);
      this.achievementResults.achievement_contributions = result['achievement_contributions'];
      this.achievementResults.achievement_levels = result['achievement_levels'];

      console.log('============================');

      this.isLoading = false;
    }
  };

  @action
  setAchievementsStructureData = async (loginStore) => {
    await this.getAchievementsStructureData(loginStore);
    console.log('getting all Achievements Structure Data');
  };



}

export const achievementsStore = new AchievementsStore();
(window as any).achievementsStore = achievementsStore;
