// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { RaffleStore } from '../../stores/raffle-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  raffleNameStep: () => void;
  addLodgeStep: () => void;
  raffleStore?: RaffleStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('raffleStore', 'contributeStore', 'loginStore',)
@observer
export class Raffle extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.raffleStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const raffleStore = this.props.raffleStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Raffle</u></h4>
          </Col>
        </Row>


        <Card className='post-option-active'>
        <CardBody>
        <Row>
          <Col sm="12">
            <p>Click Create to start a new raffle, or click to Join to join an existing raffle.</p>
          </Col>
        </Row>
        <div className="row row-justified">

          <div>
            <Button color="primary" onClick={this.props.raffleNameStep}>
              Create game
            </Button>
            <Button color="primary">
              Join a game
            </Button>
          </div>

        </div>
        </CardBody>
        </Card>


        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default Raffle;
