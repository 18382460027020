import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextInput } from './inputs/text';

export const How: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>How does it work?</h2>
        The <strong>Freemason Travellers Guide</strong> is an online platform designed to assist
        individuals who are interested in Freemasonry to explore and connect with lodges worldwide.
        The platform offers a range of features to facilitate this process.
        <br />
        <br />

        <strong>Lodge Search: </strong>
        <br />Users can search for lodges based on various criteria such as location,
        lodge name, or jurisdiction. This allows individuals to find lodges that are geographically
        convenient or aligned with their specific interests.
        <br />
        <br />
        <strong>Lodge Details: </strong>
        <br />Once a specific lodge is selected, the platform provides detailed
        information about the lodge, including its history, traditions, and membership
        requirements. Users can access essential details such as meeting schedules, lodge officers,
        and any upcoming events or ceremonies.
        <br />
        <br />
        <strong>Visit Arrangements: </strong>
        <br />The <strong>Freemason Travellers Guide</strong> enables users to initiate contact
        with lodges directly through the platform. Users can send inquiries or requests for
        visitation, expressing their interest in attending lodge meetings or events. This feature
        streamlines the process of arranging visits and allows users to establish connections with
        lodge representatives.
        <br />
        <br />
        <strong>Communication: </strong>
        <br />The platform may provide communication channels, such as messaging or email,
        to facilitate ongoing correspondence between users and lodges. This allows for further discussions,
        clarifications, and coordination regarding visitations or other matters related to Freemasonry.
        <br />
        <br />
        <strong>Community Engagement:</strong>
        <br />
        Additionally, the <strong>Freemason Travellers Guide</strong> may incorporate community
        features, such as forums or discussion boards, where users can engage in conversations, share
        experiences, and seek advice from fellow Freemasons or enthusiasts. This fosters a sense of
        community and provides a platform for knowledge exchange.
        <br />
        <br />

        Overall, the <strong>Freemason Travellers Guide</strong> aims to simplify the process
        of discovering, researching, and connecting with Masonic lodges worldwide. It assists
        individuals in exploring the masonic order, understanding the practices of specific lodges,
        and facilitating visitations to enhance their Masonic journey.
      </p>
    </div>
  );
};
export const Why: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>
          Why the <strong>Freemason Travellers Guide</strong>?
        </h2>
        Discover Freemasonry Worldwide with the Freemason Travellers Guide
        <br />
        <br />
        <strong>Introduction:</strong>
        <br />
        Are you a curious seeker of knowledge and traditions? Are you interested
        in Freemasonry and eager to connect with lodges around the globe? Look
        no further than the <strong>Freemason Travellers Guide</strong> an invaluable online resource
        designed to simplify your journey into the world of Freemasonry. With
        its comprehensive features and user-friendly interface, this guide is
        your key to unlocking the rich heritage and global network of Freemasonry.
        <br />
        <br />
        <strong>Unparalleled Accessibility:</strong>
        <br />
        The <strong>Freemason Travellers Guide</strong> is accessible from anywhere in the world,
        right at your fingertips. Whether you're at home, traveling, or simply
        on the go, this platform empowers you to explore Freemasonry without
        limitations. With a few clicks, you can access a vast repository of lodge
        information, enabling you to embark on a meaningful Masonic experience,
        regardless of your location.
        <br />
        <br />
        <strong>Extensive Lodge Database:</strong>
        <br />
        Say goodbye to endless web searches and fragmented information. The
        <strong>Freemason Travellers Guide</strong> boasts a comprehensive database of lodges
        worldwide. From major cities to remote regions, this guide ensures
        that no lodge remains undiscovered. By providing detailed profiles,
        meeting schedules, and historical insights, the guide equips you with
        in-depth knowledge to make informed decisions about the lodges you
        wish to explore.
        <br />
        <br />
        <strong>Curated Lodge Experiences:</strong>
        <br />
        Every lodge has its unique traditions and offerings. The <strong>Freemason Travellers Guide</strong>
         helps you navigate this rich tapestry by providing
        detailed lodge descriptions. Explore the diverse rituals, historical
        significance, and distinctive practices of each lodge. Tailor your
        Masonic journey by selecting lodges that resonate with your interests,
        allowing you to create meaningful connections and gain deeper insights
        into the global masonic order.
        <br />
        <br />
        <strong>Vibrant Community Engagement:</strong>
        <br />
        The <strong>Freemason Travellers Guide</strong> is more than just a directory;
        it's a thriving community of like-minded individuals. Engage in discussions,
        share experiences, and seek advice from fellow Freemasons through the platform's
        interactive features. Exchange ideas, expand your knowledge, and foster lifelong
        friendships, all while being part of a global network of Freemasonry enthusiasts.
        <br />
        <br />
        Embrace the boundless opportunities that Freemasonry offers with the <strong>Freemason Travellers Guide</strong>.
        Seamlessly navigate the global landscape of lodges, connect with passionate Freemasons,
        and embark on a transformative Masonic journey. Whether you're a novice or a seasoned Mason,
        this guide will be your trusted companion, empowering you to explore, learn, and
        experience the world of Freemasonry like never before. Begin your adventure today
        with the <strong>Freemason Travellers Guide</strong> and unlock the secrets of this ancient masonic order.
      </p>
    </div>
  );
};
export const Who: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>
          Who is using the <strong>Freemason Travellers Guide</strong>?
        </h2>
        Masons around the globe.
        <br />
        <br />
        <h2>
          Who has incorporated the <strong>Freemason Travellers Guide</strong> into their websites?
        </h2>
        <strong>Melrose Lodge No.7034 EC:</strong>
        <br />
        <a href="https://www.melroselodge.co.za/calendar" target="_blank"><img src="https://www.melroselodge.co.za/templates/yootheme/cache/4f/Melrose-Lodge-4f51de5e.webp" width="70px"/></a>
      </p>
    </div>
  );
};


interface Props {
  onClick: () => void;
  text: string;
}

export const ReadMoreButton: React.FC<Props> = props => {
  return (
    <Button
      style={{
        margin: '0 8px',
        color: 'white',
        borderColor: 'white',
        background: 'rgba(0,0,0,0)'
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};

class Landing extends Component {
  state = {
    howOpen: false,
    whyOpen: false,
    whoOpen: false
  };

  render() {
    return (
      <div className="fullscreen">
        <nav>
          <a href="/">
            <img alt='logo' src="logo.png" height="40px" />
          </a>
        </nav>

        <main className="landing-page-cover">
          <div id="section-landing-page-cover">
            <h2 className="outline">Discover Freemasonry Worldwide!</h2>
            <h4 className="outline">Embark Upon Your Masonic Expedition.</h4>
            <br />

            <Row>
              <Col sm="12">
                <a
                  style={{ margin: 10 }}
                  className="btn btn-lg btn-primary"
                  href="/explore"
                >
                  Enter
                </a>
              </Col>
            </Row>

            <br />
            <br />

            <p
              style={{
                background: 'rgba(50,50,50,0.6)',
                padding: '16px',
                borderRadius: '4px'
              }}
            >
              <h2>
                <strong>Freemason Travellers Guide</strong>
              </h2>
              <p>
                How can you locate a lodge or plan your schedule without ensuring
                the accuracy of the available information? This project aims to eradicate
                the risk of errors arising from using outdated information from various
                sources. The data is meticulously organized into a user-friendly
                format and curated by the very individuals who rely on it and use it daily, ensuring
                that you have access to the most up to date information available.
              </p>

              <p>
                The <strong>Freemason Travellers Guide</strong> is one of a kind.
                All is provided for free and is open to anyone who might want to
                make use of it and add it to their websites. Please contact support
                to get your account setup.
              </p>

              <ReadMoreButton
                onClick={() => this.setState({ howOpen: true })}
                text={'How does it work?'}
              />
              <ReadMoreButton
                onClick={() => this.setState({ whyOpen: true })}
                text={'Why the Freemason Travellers Guide?'}
              />
              <ReadMoreButton
                onClick={() => this.setState({ whoOpen: true })}
                text={'Who\'s using it?'}
              />

            </p>

          </div>
          <br></br>

          {/* how modal */}
          <Modal isOpen={this.state.howOpen}>
            <ModalHeader
              toggle={() => this.setState({ howOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <How />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ howOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* why modal */}
          <Modal isOpen={this.state.whyOpen}>
            <ModalHeader
              toggle={() => this.setState({ whyOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <Why />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ whyOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* who modal */}
          <Modal isOpen={this.state.whoOpen}>
            <ModalHeader
              toggle={() => this.setState({ whoOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <Who />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ whoOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

        </main>
        <div id="footer"style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
          <a href="https://www.tempelhoffenterprises.com">
            <img alt='logo-te' src="footer_logo.png" height="40px"/>
          </a>
        </div>
      </div>
    );
  }
}

export default Landing;
