import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

interface Props {
  id: string;
  label: string;
  value: any;
  onChange: (selectedOption: any) => void; // Change the type to accept the selected option directly
  invalid: boolean;
  validationMessage: string;
  options: { value: string; label: string }[];
}

export const SearchableSelectInput: React.FC<Props> = observer((props: Props) => {
  const { id, label, value, onChange, invalid, validationMessage, options } = props;

  // Convert the options array to the format required by react-select
  const selectOptions = options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <div className={invalid ? 'is-invalid' : ''}>
        <Select
          options={selectOptions}
          value={selectOptions.find((option) => option.value === value)}
          onChange={onChange} // Pass the selected option directly
          isSearchable // This enables the search functionality
          placeholder="Please select"
          isClearable // Allows the user to clear the selection
          inputId={id}
        />
      </div>
      {invalid && <div style={{ color: 'red' }}>{validationMessage}</div>}
    </FormGroup>
  );
});
