// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { RegistrationPreStore } from '../../stores/registration-pre-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, PasswordInput } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  addLodgeStep: () => void;
  getRefCode: () => string;
  registrationPreStore?: RegistrationPreStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('registrationPreStore', 'thankYouStore', 'contributeStore', 'loginStore')
@observer
export class RegistrationPre extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  handleAutoNextClick = async (referral_code) => {
      const { registrationPreStore } = this.props;

      registrationPreStore.setReferralCode(referral_code);
      if(await registrationPreStore.confirmReferralCode()) {
        this.props.nextStep();
      }
      else {
        toast.error("Invalid Referral Code!");
        this.props.loginStep();
      }
    };

  render() {
    if (!this.props.registrationPreStore) {
      return;
    }

    const registrationPreStore = this.props.registrationPreStore;
    //console.log("------- registrationStore.lodgeResults -------");
    //console.log(registrationStore.lodgeResults);
    //console.log("---------------------------------------");

    const registrationInput = registrationPreStore.registrationInput;
    const validationResult = registrationPreStore.validationResult;

    //console.log("------- this.props.getRefCode -------");
    //console.log(this.props.getRefCode());
    //console.log("---------------------------------------");

    let referral_code = this.props.getRefCode();

    const loginStore = this.props.loginStore;

    this.handleAutoNextClick(referral_code);

    return (

      <div>
      <Row>
        <Col sm="12">
          <h1>Please wait...</h1>
        </Col>
      </Row>
      {/*
        <Row>
          <Col sm="12">
            <h1>Referral Code</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <PasswordInput
              id="referral_code"
              label="Enter referral code"
              value={(referral_code.toString() != '') ? referral_code : registrationInput.referral_code}
              onChange={e =>
                (registrationInput.referral_code = e.target.value)
              }
              invalid={!!validationResult.referral_code}
              validationMessage={validationResult.referral_code}
            />
          </Col>
        </Row>

        <Row>
          <Col>
          <span className="small-font-italic">
          <p>Thank you for choosing to register with the Freemason Travellers Guide!</p>
          <p>
            Here's what to expect once you proceed:
            <ol>
              <li><b>Enter Your Email Address:</b> You will be asked to provide an email address. This is crucial, as all future communications will be sent to this email. Please ensure you have access to it.</li>
              <li><b>Verify Your Email:</b> A verification link will be sent to the email address you provided. Click on this link to confirm your identity and ensure you have access to the email.</li>
              <li><b>Receive a Random Password:</b> After confirming your email address, a random password will be sent to your email inbox.</li>
              <li><b>Log In:</b> You'll use the random password you received to log in to your account. Don't worry, you'll have the opportunity to change it later.</li>
              <li><b>Change Your Password:</b> Upon logging in with the random password, you'll be prompted to change it to something more memorable.</li>
              <li><b>Log In Again:</b> Finally, you'll be redirected to log in again, this time using the password you've chosen.</li>
            </ol>
          </p>
          <p>We appreciate your cooperation and look forward to welcoming you aboard the Freemason Travellers Guide!</p>
          </span>
          </Col>
        </Row>
        <br/>

        <div id='navigation_buttons'>
          <Button color="primary" onClick={async () => {
            //registrationPreStore.validate();
            //const success = registrationPreStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            if (true) { //} (success) {
              //console.log('++++++++++ referral_code');
              //console.log(referral_code);
              //console.log('++++++++++');
              registrationPreStore.setReferralCode(referral_code);
              if(await registrationPreStore.confirmReferralCode()) {
                this.props.nextStep();
              }
              else {
                toast.error("Invalid Referral Code!");
              }
            }
          }}>
            Next
          </Button>

          {loginStore.isLoggedIn ? (
            <Button color="" onClick={async () => {
              this.props.searchStep();
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              Back
            </Button>
          ): ''}

        </div>*/}
      </div>
    );
  }
}

export default RegistrationPre;
