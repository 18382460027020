import { observable, action } from 'mobx';
import { PolicyDocs } from '../domain/policy-docs';
import api from '../api';

export class PolicyDocsStore {

  @observable termsModalOpen: boolean = false;
  @observable termsModalError: boolean = false;
  @observable termsModalSuccess: boolean = false;

  @observable privacyModalOpen: boolean = false;
  @observable privacyModalError: boolean = false;
  @observable privacyModalSuccess: boolean = false;

  constructor() {
    this.toggleTermsModal = this.toggleTermsModal.bind(this);
    this.termsModalOpen = false; // Initialize your state here
    this.togglePrivacyModal = this.togglePrivacyModal.bind(this);
    this.privacyModalOpen = false; // Initialize your state here
  }

  toggleTermsModal = () => {
    console.log("toggleTermsModal");
    this.termsModalOpen = !this.termsModalOpen;
    this.termsModalError = false;
    this.termsModalSuccess = false;
    return this.termsModalOpen;
  };

  togglePrivacyModal = () => {
    console.log("togglePrivacyModal");
    this.privacyModalOpen = !this.privacyModalOpen;
    this.privacyModalError = false;
    this.privacyModalSuccess = false;
    return this.privacyModalOpen;
  };
}

export const policyDocsStore = new PolicyDocsStore();
(window as any).policyDocsStore = policyDocsStore;
