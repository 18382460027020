// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { UserAccountStore } from '../../stores/user-account-store';
import { creditTransferInitiateStore, CreditTransferInitiateStore } from '../../stores/credit-transfer-initiate-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { QRCodeCanvas } from 'qrcode.react';
import { constructWAShareLink } from '../../helpers/utilities';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  userAccountStore?: UserAccountStore;
  creditTransferInitiateStore?: CreditTransferInitiateStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('userAccountStore', 'contributeStore', 'loginStore', 'creditTransferInitiateStore')
@observer
export class CreditTransferInitiate extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.userAccountStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const userAccountStore = this.props.userAccountStore;//typeOfThankYou

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Credit Transfer</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>
            <Row className=''>
              <Col>
                <span className='small-font'>
                  Your current balance is: <b>{loginStore.user_credits}</b> Credits
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <p>
          You are in the process of sending credits to {creditTransferInitiateStore.username}.
        </p>
        <p>
          Click confirm to proceed.
        </p>

        <hr />
        <Button color="primary" onClick={() => {
          //suggestEditMeetingStore.validate();
          //const success = suggestEditMeetingStore.checkValidation();
          //console.log('----');
          //console.log(success);
          //console.log('----');
          //if (success) {
          this.props.nextStep();
          //}
        }}>
          Confirm
        </Button>
        <Button color='' onClick={this.props.profileStep}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 14'
            id='Delete-1--Streamline-Core'
            height='14'
            width='14'
          >
            <desc>Delete 1 Streamline Icon: https://streamlinehq.com</desc>
            <g id='delete-1--remove-add-button-buttons-delete-cross-x-mathematics-multiply-math'>
              <path
                id='Union'
                fill='#8fbffa'
                fill-rule='evenodd'
                d='M1.707 0.293A1 1 0 0 0 0.293 1.707L5.586 7 0.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414 -1.414L8.414 7l5.293 -5.293A1 1 0 0 0 12.293 0.293L7 5.586 1.707 0.293Z'
                clip-rule='evenodd'
                stroke-width='1'
              ></path>
            </g>
          </svg>
          <span> Cancel</span>
        </Button>
      </div>
    )
      ;
  }
}

export default CreditTransferInitiate;
