import { observable, action } from 'mobx';
import { Raffle } from '../domain/raffle';
import api from '../api';

export class RaffleStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {

  }




}

export const raffleStore = new RaffleStore();
(window as any).raffleStore = raffleStore;
