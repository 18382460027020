import { observable, action } from 'mobx';
import { SuggestEditLodge } from '../domain/suggest-edit-lodge';
import api from '../api';

export class SuggestEditLodgeStore {
  @observable isLoading = false;

  @observable lodgeInput: Partial<SuggestEditLodge> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    language: '',
    degree: '',
    address: '',
    address_id: '',
    lodge_id: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  setSuggestEditLodgeData = (lodgeData: any) => {
    if (lodgeData.length > 0) {
      const lodge = lodgeData[0];
      this.lodgeInput.lodge_id = lodge.id;
      this.lodgeInput.name = lodge.name;
      this.lodgeInput.number = lodge.number;
      this.lodgeInput.country = lodge.country;
      this.lodgeInput.district = lodge.district;
      this.lodgeInput.constitution = lodge.constitution;
      this.lodgeInput.language = lodge.language;
      this.lodgeInput.degree = lodge.degree;
      this.lodgeInput.address = lodge.address;
      this.lodgeInput.address_id = lodge.address_id;
    }
  };

  clearLodgeInput = () => {
    this.lodgeInput.lodge_id = '';
    this.lodgeInput.country = '';
    this.lodgeInput.district = '';
    this.lodgeInput.name = '';
    this.lodgeInput.number = '';
    this.lodgeInput.constitution = '';
    this.lodgeInput.language = '';
    this.lodgeInput.degree = '';
    this.lodgeInput.address = '';
    this.lodgeInput.address_id = '';
    console.log('clearing edit lodge input');
  };

}

export const suggestEditLodgeStore = new SuggestEditLodgeStore();
(window as any).suggestEditLodgeStore = suggestEditLodgeStore;
