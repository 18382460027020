import { observable, action } from 'mobx';
import { Search } from '../domain/search';
import api from '../api';

export class SearchStore {
  @observable isLoading = false;
  @observable isExpanded = false;

  @observable searchInput: Partial<Search> = {
    country: '',
    district: '',
    name_number: '',
    search: '',
    constitution: '',
    degree: '',
    today_only: false,
    address: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  toggleExpand = () => {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded == false)
    {
      this.searchInput.country = '';
      this.searchInput.district = '';
      this.searchInput.constitution = '';
      this.searchInput.degree = '';
      this.searchInput.today_only = false;
      this.searchInput.address = '';
    }
  };
}

export const searchStore = new SearchStore();
(window as any).searchStore = searchStore;
