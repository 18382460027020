import * as React from 'react';
import {
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
} from 'reactstrap';
import { observer } from 'mobx-react';
import {Icon} from "../svg_icons/icon";
import {toast} from "react-toastify";

import {
    checkIfFuture,
    checkIfMeetingNotLogged,
    getDescription
} from '../../helpers/utilities';

interface Props {
    post?: {
        attendance: number;
        lodge_number: string;
        lodge_name: string;
        address_id: number;
        meeting_description_extra: string;
        description_id: number;
        lodge_id: number;
        time: string;
        date: string;

        post_type: number;
        meeting_type: string;
        public_id: any;
        title: any;
        date_time: any;
        address: any;
        gps: string;
        description: any;
        image: string;
        liked: boolean;
        likes_count: any;
        comments: any;
    };
    context: string;
    index: 0;
    feedWallStep?: () => void;
    viewProfileStore: any;
    loginStore: any;
    profileStore?: any;
}



export const Post: React.FC<Props> = observer((props: Props) => {
        return (
            <>
                <Card className="post-option-active">
                    <CardBody className="card-padding-override">

                        {props.profileStore != null && props.context == 'personal' && props.post.meeting_type == '' ? (
                            <div
                                className='pointing-cursor promoted-sponsored-info-button'
                                onClick={() => {
                                    props.profileStore.showContextMenu(props.index);
                                    //profileStore.postsContextMenu[index] = !profileStore.postsContextMenu[index];

                                    //this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                    //this.setState({postsContextMenu: this.state.postsContextMenu});
                                }}
                            >
                                <Icon id="options"></Icon>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* Post Header */}
                        <Row>
                            <Col className="col-sm-1 post_header_logo">
                                <img
                                    src='logo.png'
                                    alt='logo'
                                    height='40px'
                                    onClick={() => {
                                        //props.feedWallStep();
                                    }}
                                />
                            </Col>
                            <Col className="col-sm-11 post_header_title pointing-cursor">
                                <span
                                    onClick={() => {
                                        //props.feedWallStep();
                                    }}
                                >
                                  {/*loginStore.username*/}
                                    {props.post.title}
                                </span>
                                <br/>
                                <Icon id="clock"></Icon>
                                <span className='x-small-font'> {props.post.date_time} </span>
                                {props.post.post_type == 1 ? (
                                    <Icon id="globe"></Icon>
                                ) : (
                                    ''
                                )}
                                {props.post.post_type == 1 ? (
                                    <span className='x-small-font'>
                                        <a
                                            href={`http://maps.google.com/maps?q=${props.post.gps !== '' ? props.post.gps : ''}`}
                                            target='_blank'
                                        >
                                          {props.post.address}
                                        </a>
                                      </span>
                                ) : (
                                    ''
                                )}
                            </Col>
                        </Row>

                        {/* Post Text */}
                        <Row>
                            <Col>
                                {
                                    props.post.description.length > 100
                                        ? <span id={`meeting_description_cut_${props.index}`}
                                                className='show_post_description pre pre-code'>{props.post.description.substring(0, 200) + "..."}
                                            <Button className="small-font" color="#007bff" onClick={() => {
                                                getDescription(props.index);
                                            }}>
                                              see more
                                            </Button>
                                          </span>
                                        : ''
                                }
                                <span id={`meeting_description_full_${props.index}`} className={`${
                                    props.post.description.length < 100
                                        ? 'show_post_description pre pre-code'
                                        : 'hide_post_description pre pre-code'
                                }`}>
                                  {props.post.description}
                                </span>
                            </Col>
                        </Row>

                        {/* Post Media */}
                        {props.post.post_type == 1 ? (
                            <Row>
                                <Col sm='12' className='post-restricted-height'>
                                    <img className='feed-wall-image' src={props.post.image}/>
                                </Col>
                            </Row>
                        ) : props.post.image !== '' ? (
                            <Row>
                                <Col sm="12" className="post-restricted-height">
                                    {props.post.image.includes('.pdf') ? (
                                            <embed src={process.env.REACT_APP_API_URL + props.post.image + "#toolbar=0"}
                                                   type="application/pdf" className="width-height-hundred"/>
                                        ) :
                                        props.post.image.includes('.mp3') ? (
                                                <audio controls>
                                                    <source src={process.env.REACT_APP_API_URL + props.post.image}
                                                            type="audio/mp3"/>
                                                    Your browser does not support the audio tag.
                                                </audio>
                                            ) :
                                            props.post.image.includes('.mp4') ? (
                                                    <video controls className="video-container">
                                                        <source src={process.env.REACT_APP_API_URL + props.post.image}
                                                                type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) :
                                                (
                                                    <img className="feed-wall-image"
                                                         src={process.env.REACT_APP_API_URL + props.post.image}/>
                                                )}
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}

                        {/* Post Buttons */}
                        { /*props.post.meeting_type == '' ? (
                            <div>
                                <hr/>
                                <Row className="row-justified-and-centered small-font">
                                    <Col>
                                <Button className="small-font" color=""
                                        onClick={async () => {
                                            let res = await props.viewProfileStore.likePost(props.loginStore.miniToken, props.post.public_id);
                                            if (res) {
                                                toast.success("You have liked this post!");

                                                props.post.liked = !props.post.liked;

                                                // alternative to this.forceUpdate solution
                                                //this.state.postsLiked[props.index] = !this.state.postsLiked[props.index];
                                                //this.setState({postsLiked: this.state.postsLiked});

                                                // NB!!! not what I want to do here
                                                //this.forceUpdate();
                                                //viewProfileStore.setLiked(props.index);
                                            } else {
                                                toast.warning("You are unable to like this post!");
                                            }
                                        }}>

                                    {props.post.liked ?
                                        <Icon id="like" accent_color="#2859C5" base_color="#8FBFFA" text="Liked"></Icon>
                                        :
                                        <Icon id="like" text="Like"></Icon>
                                    }
                                    {props.post.likes_count > 0 ?
                                        <sup> ({props.post.likes_count})</sup>
                                        :
                                        ''}
                                </Button>
                            </Col>
                                    <Col>
                                <Button className="small-font" color=""
                                        onClick={async () => {
                                            console.log("sending post data to comment modal");
                                            console.log(props.post);
                                            props.viewProfileStore.setPostData(props.post, props.index);
                                            props.viewProfileStore.toggleUserPostCommentModal();
                                        }}>
                                    <Icon id="comment" text="Comment"></Icon>
                                    {props.post.comments && props.post.comments.length > 0 ?
                                        <sup> ({props.post.comments.length})</sup>
                                        :
                                        ''}
                                </Button>
                            </Col>
                                    <Col>
                                <Button className="small-font" color=""
                                        onClick={async () => {
                                            //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                            //this.props.contactStep();
                                        }}>
                                    <Icon id="share" text="Share"></Icon>
                                </Button>
                            </Col>
                                </Row>
                            </div>
                        ) : '' */}
                        <>
                            <PostButtons
                                post={props.post}
                                index={props.index}
                                context={props.context}
                                feedWallStep={props.feedWallStep}
                                viewProfileStore={props.viewProfileStore}
                                loginStore={props.loginStore}
                                /*profileStore={null}*/
                            ></PostButtons>
                        </>

                        {/* Post Comments */}
                        {props.post.meeting_type == '' && props.post.comments && props.post.comments.length > 0 ?
                            <div>
                                <hr/>
                                {/* comments sections - show the only one comment, the last/latest one */}

                                {props.post.comments.slice(0, 2).map((comment, index) => (
                                        <div>
                                            <Row>
                                                <Col className="col-sm-1 post_header_logo">
                                                    <img
                                                        className="user-post-comment-image"
                                                        src='logo.png'
                                                        alt='logo'
                                                        height='20px'
                                                        onClick={() => {
                                                            //this.props.profileStep();
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <div className="user-post-comment">
                                                        <span className="x-small-font"><b>{comment.name}</b></span>
                                                        <br/>
                                                        <span className="small-font">{comment.comment}</span>
                                                    </div>
                                                    <br/>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                )}
                            </div>
                            : ''
                        }

                    </CardBody>
                </Card>

            </>
        );
})

export const PostButtons: React.FC<Props> = observer((props: Props) => {
    return (
        <>
        {props.post.meeting_type == '' ? (
            <UserPostButtons
                post={props.post}
                index={props.index}
                context={props.context}
                feedWallStep={props.feedWallStep}
                viewProfileStore={props.viewProfileStore}
                loginStore={props.loginStore}
                /*profileStore={null}*/
            ></UserPostButtons>
            ) :
            ''
        }
        </>
    );
})

export const UserPostButtons: React.FC<Props> = observer((props: Props) => {
    return (
        <>
            <div>
                <hr/>
                <Row className="row-justified-and-centered small-font">
                    <Col>
                        <Button className="small-font" color=""
                                onClick={async () => {
                                    let res = await props.viewProfileStore.likePost(props.loginStore.miniToken, props.post.public_id);
                                    if (res) {
                                        toast.success("You have liked this post!");

                                        props.post.liked = !props.post.liked;

                                        // alternative to this.forceUpdate solution
                                        //this.state.postsLiked[props.index] = !this.state.postsLiked[props.index];
                                        //this.setState({postsLiked: this.state.postsLiked});

                                        // NB!!! not what I want to do here
                                        //this.forceUpdate();
                                        //viewProfileStore.setLiked(props.index);
                                    } else {
                                        toast.warning("You are unable to like this post!");
                                    }
                                }}>

                            {props.post.liked ?
                                <Icon id="like" accent_color="#2859C5" base_color="#8FBFFA" text="Liked"></Icon>
                                :
                                <Icon id="like" text="Like"></Icon>
                            }
                            {props.post.likes_count > 0 ?
                                <sup> ({props.post.likes_count})</sup>
                                :
                                ''}
                        </Button>
                    </Col>
                    <Col>
                        <Button className="small-font" color=""
                                onClick={async () => {
                                    console.log("sending post data to comment modal");
                                    console.log(props.post);
                                    props.viewProfileStore.setPostData(props.post, props.index);
                                    props.viewProfileStore.toggleUserPostCommentModal();
                                }}>
                            <Icon id="comment" text="Comment"></Icon>
                            {props.post.comments && props.post.comments.length > 0 ?
                                <sup> ({props.post.comments.length})</sup>
                                :
                                ''}
                        </Button>
                    </Col>
                    <Col>
                        <Button className="small-font" color=""
                                onClick={async () => {
                                    //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                    //this.props.contactStep();
                                }}>
                            <Icon id="share" text="Share"></Icon>
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
})

