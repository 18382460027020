// @ts-check
import React, {Component} from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup, ModalHeader, ModalBody, ModalFooter, Modal, Alert
} from 'reactstrap';

import {QRCodeCanvas} from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import { Line, Circle } from 'rc-progress';

import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeLogoFromId,
  orderLogoFromConstitutionAndOrder,
  constructWAShareLink
} from '../../helpers/utilities';
import { MultiSelectInput } from '../inputs/multi-select'
import { Icon } from '../svg_icons/icon';

import { ProfileStore } from '../../stores/profile-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { ConfigurationStore } from '../../stores/configuration-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { LoginStore } from '../../stores/login-store';
import { LogVisitMeetingStore } from '../../stores/log-visit-meeting-store';
import { DiagramStore } from '../../stores/diagram-store';
import { AchievementsStore } from '../../stores/achievements-store';
import { ViewProfileStore } from '../../stores/view-profile-store';
import { UserAccountStore } from '../../stores/user-account-store';
import { schedulePlannerStore, SchedulePlannerStore } from '../../stores/schedule-planner-store';
import {TextArea} from "../inputs/text";
import {Post} from "../display-components/post";


export const times = [
  {
    value: '08:00',
    label: '08:00'
  },
  {
    value: '08:30',
    label: '08:30'
  },
  {
    value: '09:00',
    label: '09:00'
  },
  {
    value: '09:30',
    label: '09:30'
  },
  {
    value: '10:00',
    label: '10:00'
  },
  {
    value: '10:30',
    label: '10:30'
  },
  {
    value: '11:00',
    label: '11:00'
  },
  {
    value: '11:30',
    label: '11:30'
  },
  {
    value: '12:00',
    label: '12:00'
  },
  {
    value: '12:30',
    label: '12:30'
  },
  {
    value: '13:00',
    label: '13:00'
  },
  {
    value: '13:30',
    label: '13:30'
  },
  {
    value: '14:00',
    label: '14:00'
  },
  {
    value: '14:30',
    label: '14:30'
  },
  {
    value: '15:00',
    label: '15:00'
  },
  {
    value: '15:30',
    label: '15:30'
  },
  {
    value: '16:00',
    label: '16:00'
  },
  {
    value: '16:30',
    label: '16:30'
  },
  {
    value: '17:00',
    label: '17:00'
  },
  {
    value: '17:30',
    label: '17:30'
  },
  {
    value: '18:00',
    label: '18:00'
  },
  {
    value: '18:30',
    label: '18:30'
  },
  {
    value: '19:00',
    label: '19:00'
  },
  {
    value: '19:30',
    label: '19:30'
  },
  {
    value: '20:00',
    label: '20:00'
  },
  {
    value: '20:30',
    label: '20:30'
  },
  {
    value: '21:00',
    label: '21:00'
  }
];

export const types = [
  {
    value: '1',
    label: 'Meeting'
  },
  {
    value: '2',
    label: 'Installation'
  }/*,
  {
    value: '3',
    label: 'Social'
  }*/
];

interface ModalProps {
  profileStore?: ProfileStore;
  loginStore?: LoginStore;
}

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  logoutStep: () => void;
  addLodgeStep: () => void;
  lodgeDetailsStep: () => void;
  diagramStep: () => void;
  viewProfileStep: () => void;
  achievementsStep: () => void;
  userAccountStep: () => void;
  schedulePlannerStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  profileStore?: ProfileStore;
  diagramStore?: DiagramStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  logVisitMeetingStore?: LogVisitMeetingStore;
  achievementsStore?: AchievementsStore;
  viewProfileStore?: ViewProfileStore;
  userAccountStore?: UserAccountStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  page: number;
  toggleDivs: Array<Boolean>;
  postsContextMenu: Array<Boolean>;
}

@inject(
    'profileStore',
    'thankYouStore',
    'contributeStore',
    'loginStore',
    'configurationStore',
    'lodgeDetailsStore',
    'diagramStore',
    'logVisitMeetingStore',
    'achievementsStore',
    'viewProfileStore',
    'userAccountStore'
)
@observer
export class Profile extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      page: 4,
      toggleDivs: [],
      postsContextMenu: []
  };

    this.scrollToTop();
  }

  componentDidMount() {
    //this.scrollToTop();
    this.props.profileStore.emailNotification(
        this.props.loginStore.enable_notifications,
        this.props.loginStore.email_notification_daily_meetings,
        this.props.loginStore.all_lodge_notifications,
        this.props.loginStore.watch_list_notifications,
        this.props.loginStore.custom_notifications,
        this.props.loginStore.filter_constitutions,
        this.props.loginStore.all_socials_notifications,
        this.props.loginStore.day_socials_notifications,

        this.props.loginStore.email_notification_weekly_meetings,
        this.props.loginStore.all_lodge_weekly_notifications,
        this.props.loginStore.watch_list_weekly_notifications,
        this.props.loginStore.custom_weekly_notifications,
        this.props.loginStore.filter_weekly_constitutions,
        this.props.loginStore.all_socials_weekly_notifications,
        this.props.loginStore.week_socials_weekly_notifications,

        this.props.loginStore.email_notification_monthly_meetings,
        this.props.loginStore.all_lodge_monthly_notifications,
        this.props.loginStore.watch_list_monthly_notifications,
        this.props.loginStore.custom_monthly_notifications,
        this.props.loginStore.filter_monthly_constitutions,
        this.props.loginStore.all_socials_monthly_notifications,
        this.props.loginStore.month_socials_monthly_notifications,
        this.props.loginStore.email_notification_lodge_attendance_report,
        this.props.loginStore.email_notification_for_suggestions,
        this.props.loginStore.email_notification_for_new_socials,
        this.props.loginStore.display_on_leaderboard,
        this.props.loginStore.display_public_profile,
        this.props.loginStore.customize_feed
    );
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  yourBadge = (number, what) => {
    let achievement = "";
    if (what == 'Votes') {
      if (number >= 10 && number < 50) {
        achievement = "Novice Voter";
      }
      else if (number >= 50 && number < 100) {
        achievement = "Active Voter";
      }
      else if (number >= 100 && number < 250) {
        achievement = "Dedicated Voter";
      }
      else if (number >= 250 && number < 500) {
        achievement = "Expert Voter";
      }
      else if (number >= 500) {
        achievement = "Master Voter";
      }
    }
    else if (what == 'Edits') {
      if (number >= 5 && number < 20) {
        achievement = "Novice Editor";
      }
      else if (number >= 20 && number < 50) {
        achievement = "Active Editor";
      }
      else if (number >= 50 && number < 100) {
        achievement = "Dedicated Editor";
      }
      else if (number >= 100 && number < 250) {
        achievement = "Expert Editor";
      }
      else if (number >= 250) {
        achievement = "Master Editor";
      }
    }
    else if (what == 'Additions')
    {
      if (number >= 1 && number < 5) {
        achievement = "Novice Contributor";
      }
      else if (number >= 5 && number < 10) {
        achievement = "Active Contributor";
      }
      else if (number >= 10 && number < 20) {
        achievement = "Dedicated Contributor";
      }
      else if (number >= 20 && number < 50) {
        achievement = "Expert Contributor";
      }
      else if (number >= 50) {
        achievement = "Master Contributor";
      }
    }
    else if (what == 'Referrals')
    {
      if (number >= 1 && number < 5) {
        achievement = "Novice Advocate";
      }
      else if (number >= 5 && number < 10) {
        achievement = "Active Advocate";
      }
      else if (number >= 10 && number < 20) {
        achievement = "Dedicated Advocate";
      }
      else if (number >= 20 && number < 50) {
        achievement = "Expert Advocate";
      }
      else if (number >= 50) {
        achievement = "Master Advocate";
      }
    }
    return achievement;
  }

  yourBadgeIconOnLevel = (number, what, level) => {
    let achievement = "/badges";
    if (what == 'Votes') {
      achievement += "/voter";
      if (number >= 10 && level == 1) {
        achievement += "/novice_1.png";
      }
      else if (number >= 50 && level == 2) {
        achievement += "/active_1.png";
      }
      else if (number >= 100 && level == 3) {
        achievement += "/dedicated_1.png";
      }
      else if (number >= 250 && level == 4) {
        achievement += "/expert_1.png";
      }
      else if (number >= 500 && level == 5) {
        achievement += "/master_1.png";
      }
      else {
        //achievement += "/unlock.png";
        achievement = "/orders/small/ec/craft.png";
      }
    }
    else if (what == 'Edits') {
      achievement += "/editor";
      if (number >= 5 && level == 1) {
        achievement += "/novice.png";
      }
      else if (number >= 20 && level == 2) {
        achievement += "/active.png";
      }
      else if (number >= 50 && level == 3) {
        achievement += "/dedicated.png";
      }
      else if (number >= 100 && level == 4) {
        achievement += "/expert.png";
      }
      else if (number >= 250 && level == 5) {
        achievement += "/master.png";
      }
      else {
        //achievement += "/unlock.png";
        achievement = "/orders/small/ec/craft.png";
      }
    }
    else if (what == 'Additions')
    {
      if (number >= 1 && number < 5) {
        achievement = "Novice Contributor";
      }
      else if (number >= 5 && number < 10) {
        achievement = "Active Contributor";
      }
      else if (number >= 10 && number < 20) {
        achievement = "Dedicated Contributor";
      }
      else if (number >= 20 && number < 50) {
        achievement = "Expert Contributor";
      }
      else if (number >= 50) {
        achievement = "Master Contributor";
      }
    }
    else if (what == 'Referrals')
    {
      if (number >= 1 && number < 5) {
        achievement = "Novice Advocate";
      }
      else if (number >= 5 && number < 10) {
        achievement = "Active Advocate";
      }
      else if (number >= 10 && number < 20) {
        achievement = "Dedicated Advocate";
      }
      else if (number >= 20 && number < 50) {
        achievement = "Expert Advocate";
      }
      else if (number >= 50) {
        achievement = "Master Advocate";
      }
    }
    return achievement;
  }

  yourBadgeIcon = (number, what) => {
    let achievement = "/badges";
    if (what == 'Votes') {
      achievement += "/voter";
      if (number >= 10 && number < 50) {
        achievement += "/novice_1.png";
      }
      else if (number >= 50 && number < 100) {
        achievement += "/active_1.png";
      }
      else if (number >= 100 && number < 250) {
        achievement += "/dedicated_1.png";
      }
      else if (number >= 250 && number < 500) {
        achievement += "/expert_1.png";
      }
      else if (number >= 500) {
        achievement += "/master_1.png";
      }
      else {
        achievement += "/unlock.png";
      }
    }
    else if (what == 'Edits') {
      achievement += "/editor";
      if (number >= 5 && number < 20) {
        achievement += "/novice.png";
      }
      else if (number >= 20 && number < 50) {
        achievement += "/active.png";
      }
      else if (number >= 50 && number < 100) {
        achievement += "/dedicated.png";
      }
      else if (number >= 100 && number < 250) {
        achievement += "/expert.png";
      }
      else if (number >= 250) {
        achievement += "/master.png";
      }
      else {
        achievement += "/unlock.png";
      }
    }
    else if (what == 'Additions')
    {
      achievement += "/contributor";
      if (number >= 1 && number < 5) {
        achievement += "/novice.png";
      }
      else if (number >= 5 && number < 10) {
        achievement += "/active.png";
      }
      else if (number >= 10 && number < 20) {
        achievement += "/dedicated.png";
      }
      else if (number >= 20 && number < 50) {
        achievement += "/expert.png";
      }
      else if (number >= 50) {
        achievement += "/master.png";
      }
      else {
        achievement += "/unlock.png";
      }
    }
    else if (what == 'Referrals')
    {
      achievement += "/advocate";
      if (number >= 1 && number < 5) {
        achievement += "/novice.png";
      }
      else if (number >= 5 && number < 10) {
        achievement += "/active.png";
      }
      else if (number >= 10 && number < 20) {
        achievement += "/dedicated.png";
      }
      else if (number >= 20 && number < 50) {
        achievement += "/expert.png";
      }
      else if (number >= 50) {
        achievement += "/master.png";
      }
      else {
        achievement += "/unlock.png";
      }
    }
    return achievement;
  }


  getUniqueOrders = (lodges) => {
    var res = [];
    var temp = [];
    // Iterate through the lodges array to populate the Set
    lodges.forEach((lodge) => {
      if (!temp.includes(lodge.degree_id))
        temp.push(lodge.degree_id);
    });

    temp.forEach((degree) => {
      res.push({"degree":degree});
    });
    return res;
  }

  getUniqueShortConstitutions = (lodges) => {
    var res = [];
    var temp = [];
    // Iterate through the lodges array to populate the Set
    lodges.forEach((lodge) => {
      if (!temp.includes(lodge.short_constitution))
        temp.push(lodge.short_constitution);
    });

    temp.forEach((short_constitution) => {
      res.push({"short_constitution":short_constitution});
    });
    return res;
  }


  getUniqueConstitutionsAndOrders = (lodges) => {
    var res = [];
    var temp = [];
    // Iterate through the lodges array to populate the Set
    lodges.forEach((lodge) => {
      if (!temp.includes(lodge.constitution_id + "_" + lodge.degree_id))
        temp.push(lodge.constitution_id + "_" + lodge.degree_id);
    });

    temp.forEach((combo) => {
      let t = combo.split('_')
      res.push({"constitution": t[0], "degree":t[1]});
    });
    return res;
  }

  degreeLogoFromId = (id) => {
    let degree_string = '';
    switch (id) {
      case 1:
        degree_string = '/orders/small/craft.png';
        break;
      case 2:
        degree_string = '/orders/small/mark.png';
        break;
      case 3:
        degree_string = '/orders/small/ra.png';
        break;
      case 4:
        degree_string = '/orders/small/ram.png';
        break;
      case 5:
        degree_string = '/orders/small/cryptic.png';
        break;
      case 6:
        degree_string = '/orders/small/osm.png';
        break;
      case 7:
        degree_string = '/orders/small/kt.png';
        break;
      case 8:
        degree_string = '/orders/small/ktp.png';
        break;
      case 9:
        degree_string = '/orders/small/rcc.png';
        break;
      case 10:
        degree_string = '/orders/small/rx.png';
        break;
      default:
        degree_string = '';
    }

    return degree_string;
  };

  groupMeetingsByYearAndMonth = (meetings) => {
    const groupedMeetings = {};

    meetings.forEach((visit) => {
      const date = new Date(visit.datetime);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are zero-based

      if (!groupedMeetings[year]) {
        groupedMeetings[year] = {};
      }

      if (!groupedMeetings[year][month]) {
        groupedMeetings[year][month] = [];
      }

      groupedMeetings[year][month].push(visit);
    });

    return groupedMeetings;
  };


  getMonthName = (monthNumber) => {
    const date = new Date(Date.UTC(2000, monthNumber - 1, 1));
    return date.toLocaleString('en-US', { month: 'long' });
  }

  removeDuplicates = (array) => {
    const uniqueValues = new Set();
    const uniqueArray = [];

    array.forEach(item => {
      if (!uniqueValues.has(item.value)) {
        uniqueValues.add(item.value);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  }

  getDescription = (type, id) => {
    document.getElementById(type+'description_cut_'+id).style.display = 'none';
    document.getElementById(type+'description_full_'+id).style.display = 'inherit';
    document.getElementById(type+'image_'+id).classList.remove("post-restricted-height");
  }


  render() {
    if (!this.props.thankYouStore) {
      return;
    }
    const loginStore = this.props.loginStore;

    const logVisitMeetingStore = this.props.logVisitMeetingStore;

    const profileStore = this.props.profileStore;
    profileStore.referralCode(loginStore.referral_code);

    const viewProfileStore = this.props.viewProfileStore;

    const settingsInput = profileStore.settingsInput;

    const validationResult = profileStore.validationResult;

    const configurationStore = this.props.configurationStore;

    const achievementsStore = this.props.achievementsStore;

    const userAccountStore = this.props.userAccountStore;

    const formattedMeetings = this.groupMeetingsByYearAndMonth(loginStore.visitationMeetingsPast);

    const selectedOptionsDaily = settingsInput.filter_constitutions;

    return (
        <div>
          <div className="profile-header">
            <Row>
              <Col sm='12' className='text-center'>
                <div className='profile_schedule_planner_holder'>
                  <div className='profile_account_credits'>
                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          userAccountStore.page = 1;
                          userAccountStore.receival_code = loginStore.receival_code;
                          await userAccountStore.setUserTransactions(loginStore);
                          this.props.userAccountStep();
                        }}
                    >
                      <Icon id="coin"></Icon>
                      <span className='small-font-italic'>{loginStore.user_credits}</span>
                      <span className='feed-button-text'> Credits</span>
                    </Button>
                  </div>
                </div>

                <div className='d-flex justify-content-center'>
                  <img className='profile-picture' src='logo.png' alt='logo' height='60px'/>
                </div>
                <h5>{loginStore.username}</h5>
                <div className='d-flex justify-content-center profile-text'>
                  {this.getUniqueConstitutionsAndOrders(loginStore.lodges).map((lodge, index) => (
                      <img
                          alt='logo'
                          className='orderlogo-profile'
                          src={`${orderLogoFromConstitutionAndOrder(lodge.constitution, lodge.degree)}`}
                          height='40px'
                      />
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='level-point-progress-level'>
                <a
                    href='#'
                    onClick={async () => {
                      await achievementsStore.setAchievementsStructureData(loginStore);
                      this.props.achievementsStep();
                    }}
                >
                  Level {loginStore.level}
                </a>
              </Col>
              <Col>
                <div
                    title={
                        parseFloat(loginStore.percentage.toString())
                            .toFixed(2)
                            .toString() + '%'
                    }
                >
                  <Line percent={loginStore.percentage} strokeWidth={4} trailWidth={3} strokeColor='green'/>
                </div>
              </Col>
              <Col className='level-point-progress-points'>
                <a
                    href='#'
                    onClick={async () => {
                      await achievementsStore.setAchievementsStructureData(loginStore);
                      this.props.achievementsStep();
                    }}
                    title={parseInt(loginStore.points.toString()) + ' / ' + parseInt(loginStore.points_high.toString())}
                >
                  Points {parseInt(loginStore.points.toString())}
                </a>
              </Col>
            </Row>

            <Card className='post-option-active'>
              <CardBody className='top-buttons'>
                <Row className='row-justified-and-centered'>
                  <Col>
                    <div id='navigation_buttons'>
                      <Button
                          className=''
                          color=''
                          onClick={async () => {
                            this.setState({page: 4});
                            profileStore.page = 4;
                            //await profileStore.getUserWatchList(loginStore.miniToken);

                            const initializeArrayWithValues = (n, val = false) => Array(n).fill(val);
                            this.setState({postsContextMenu: initializeArrayWithValues(profileStore.feed.length, false)});
                          }}
                      >
                        <Icon id="feed" text="Posts"></Icon>
                      </Button>

                      <Button
                          className=''
                          color=''
                          onClick={async () => {
                            this.setState({page: 0});
                            profileStore.page = 0;
                          }}
                      >
                        <Icon id="profile" text="Profile"></Icon>
                      </Button>

                      <Button
                          className=''
                          color=''
                          onClick={async () => {
                            this.setState({page: 1});
                            profileStore.page = 1;
                            const initializeArrayWithValues = (n, val = false) => Array(n).fill(val);
                            this.setState({toggleDivs: initializeArrayWithValues(loginStore.lodges.length, false)});
                          }}
                      >
                        <Icon id="membership" text="Membership"></Icon>
                      </Button>

                      <Button
                          className=''
                          color=''
                          onClick={async () => {
                            this.setState({page: 2});
                            profileStore.page = 2;
                          }}
                      >
                        <Icon id="passport" text="Passport"></Icon>
                      </Button>

                      {loginStore.sponsored_posts.length > 0 ? (
                          <Button
                              className=''
                              color=''
                              onClick={async () => {
                                this.setState({page: 5});
                                profileStore.page = 5;
                                //await profileStore.getUserWatchList(loginStore.miniToken);
                              }}
                          >
                            <Icon id="adverts" text="Adverts"></Icon>
                          </Button>
                      ) : (
                          ''
                      )}

                      <Button
                          className=''
                          color=''
                          onClick={async () => {
                            this.setState({page: 3});
                            profileStore.page = 3;
                            await profileStore.getUserWatchList(loginStore.miniToken);
                          }}
                      >
                        <Icon id="settings" text="Settings"></Icon>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <hr/>

          {this.state.page == 0 ? (
              <div>
                <span>Number of contributions</span>
                <br/>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="lodge" text=""></Icon>
                        <span className='small-font'> Lodges added</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_lodges}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="edits" text=""></Icon>
                        <span className='small-font'> Edits</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_suggestions}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="votes" text=""></Icon>
                        <span className='small-font'> Votes</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_votes}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="referrals" text=""></Icon>
                        <span className='small-font'> Referrals</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_referrals}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                  </CardBody>
                </Card>
                <br/>
                <div>
                  <Row>
                    <Col sm='12'>
                      <h6>Your Referral Code:</h6>
                      <div>
                        <QRCodeCanvas value={'https://freemason-alpha.com/ftg?refcode=' + profileStore.referral_code}/>
                        <Button
                            className='small-font'
                            color=''
                            title='Share your referral QR code link'
                            onClick={async () => {
                              if (window.confirm('Are you sure you want to share your referral QR code link?')) {
                                let lnk = constructWAShareLink(
                                    'https://freemason-alpha.com/ftg?refcode=' + profileStore.referral_code,
                                );

                                //e.preventDefault();
                                //window.location.href=lnk;
                                window.open(lnk, '_blank');
                              }
                            }}
                        >
                          <Icon id="whats-app"></Icon>
                        </Button>
                        <span className='small-font-italic'>
                      {'https://freemason-alpha.com/ftg?refcode=' + profileStore.referral_code}
                    </span>
                      </div>
                    </Col>
                  </Row>
                  <br/>
                  <p>Share this QR code with a fellow mason for them to gain access.</p>
                  <p>This QR refreshes to make sure that no one can reuse or share your code with others!</p>
                </div>
                <br/>
                <span className='small-font-italic'>Your last login was on: {loginStore.last_login}</span>
              </div>
          ) : this.state.page == 1 ? (
              <div>
                <div>
                  {loginStore.lodges.map((lodge, index) => (
                      <Card className='post-option-active'>
                        <CardBody>
                          <Row>
                            <Col sm='12'>
                              <a
                                  href='#'
                                  onClick={async () => {
                                    this.props.setLoadingTrue();

                                    this.props.lodgeDetailsStore.previousPage = 'profile';
                                    this.props.lodgeDetailsStore.clearLodgeInfo();
                                    await this.props.lodgeDetailsStore.setLodgeInfo(
                                        lodge.name,
                                        lodge.number,
                                        lodge.degree_id,
                                        loginStore.miniToken,
                                        loginStore.lodges,
                                    );
                                    this.props.lodgeDetailsStep();

                                    this.props.setLoadingFalse();
                                  }}
                              >
                                <b>
                                  {lodge.name} No. {lodge.number} {lodge.short_constitution} {lodge.degree}{' '}
                                </b>
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm='12'>
                              <b
                                  className='small-font pointing-cursor'
                                  onClick={async () => {
                                    profileStore.toggleMembershipDivs[lodge.id] = !profileStore.toggleMembershipDivs[lodge.id];
                                    this.state.toggleDivs[index] = !this.state.toggleDivs[index];
                                    this.setState({toggleDivs: this.state.toggleDivs});
                                  }}
                              >
                                Members ({loginStore.lodge_data[lodge.id].length})
                              </b>
                              <div
                                  className={`toggle-email-setting-div${
                                      this.state.toggleDivs[index] ? '-open' : '-closed'
                                  }`}
                              >
                                <div className='small-font members-listing'>
                                  {loginStore.lodge_data[lodge.id].map((user, index) => (
                                      <div key={index}>
                                <span
                                    className='pointing-cursor'
                                    onClick={async () => {
                                      if (user.public_id != null) {
                                        await this.props.viewProfileStore.setProfile(
                                            loginStore.miniToken,
                                            user.public_id,
                                        );
                                        this.props.viewProfileStep();
                                      }
                                    }}
                                >
                                  {user.name}
                                </span>

                                        {/*
                                <Button
                                  className='members-listing-btn1'
                                  color=''
                                  onClick={async () => {
                                    if (window.confirm('Are you sure you wish to vote for removal of this member?')) {

                                    }
                                  }}
                                >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 14 14'
                                  id='Mail-Send-Envelope--Streamline-Core'
                                  height='14'
                                  width='14'
                                >
                                  <desc>Mail Send Envelope Streamline Icon: https://streamlinehq.com</desc>
                                  <g id='mail-send-envelope--envelope-email-message-unopened-sealed-close'>
                                    <path
                                      id='Union'
                                      fill='#8fbffa'
                                      fill-rule='evenodd'
                                      d='M1.5 1.25A1.5 1.5 0 0 0 0 2.75v8.5a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5 -1.5v-8.5a1.5 1.5 0 0 0 -1.5 -1.5h-11Z'
                                      clip-rule='evenodd'
                                      stroke-width='1'
                                    ></path>
                                    <path
                                      id='Intersect'
                                      fill='#2859c5'
                                      fill-rule='evenodd'
                                      d='M14 3.092 7.383 7.504A0.72 0.72 0 0 1 7 7.607a0.72 0.72 0 0 1 -0.383 -0.103L0 3.092v1.502l5.927 3.952 0.005 0.004c0.315 0.205 0.693 0.307 1.068 0.307 0.375 0 0.753 -0.102 1.068 -0.307L14 4.594V3.092Z'
                                      clip-rule='evenodd'
                                      stroke-width='1'
                                    ></path>
                                  </g>
                                </svg>
                                </Button>
                                */}
                                        <Button
                                            className='members-listing-btn'
                                            color=''
                                            onClick={async () => {
                                              if (window.confirm('Are you sure you wish to vote for removal of this member?')) {
                                                if (await profileStore.suggestMembershipRemoval(lodge.id, user.id, loginStore)) {
                                                  toast.success('Your suggestion has been logged!');
                                                } else {
                                                  toast.warning('Your suggestion could not be logged!');
                                                }
                                              }
                                            }}
                                        >
                                          <Icon id="user-remove"></Icon>
                                        </Button>
                                      </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                  ))}
                </div>
              </div>
          ) : this.state.page == 2 ? (
              <div>
                <span>Number of visits</span>
                <br/>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="lodge"></Icon>
                        <span className='small-font'> Lodges</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_lodges_visited}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="location"></Icon>
                        <span className='small-font'> Locations</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_locations_visited}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                    <Row>
                      <Col sm='2' className='col-2'></Col>
                      <Col sm='6' className='col-6'>
                        <Icon id="total"></Icon>
                        <span className='small-font'> Total</span>
                      </Col>
                      <Col sm='2' className='col-2'>
                        <span className='small-font'>{loginStore.number_of_total_visited}</span>
                      </Col>
                      <Col sm='2' className='col-2'></Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className='profile_schedule_planner_holder'>
                  <div className='profile_account_credits'>
                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          //await userAccountStore.setUserTransactions(loginStore);
                          schedulePlannerStore.setDate(loginStore);
                          this.props.schedulePlannerStep();
                        }}
                    >
                      <Icon id="schedule" text="Schedule Planner"></Icon>
                    </Button>
                  </div>
                </div>
                <br/>
                <u>Activity Timeline:</u>
                <br/>
                <span className='small-font'>
              {loginStore.visitationMeetingsFuture.length < 1
                  ? 'Currently no scheduled meetings.'
                  : 'Scheduled meetings: (' + loginStore.visitationMeetingsFuture.length + ')'}
            </span>
                {loginStore.visitationMeetingsFuture.map((visit, index) => (
                    <Card className={`quote-option-active`} style={{cursor: 'default'}}>
                      <CardBody>
                        <div
                            className='pointing-cursor promoted-sponsored-info-button'
                            onClick={async () => {
                              if (window.confirm('Are you sure you wish to remove this meeting from your timeline?')) {
                                /*if (await lodgeDetailsStore.suggestEmergencyMeetingRemoval(
                                        lodgeDetailsStore.lodgeInfo[0].id, //lodge.id,
                                        meeting.date,
                                        meeting.time,
                                        loginStore)) {*/
                                logVisitMeetingStore.removeLogAttendance(
                                    visit.datetime,
                                    0,
                                    visit.lodge_id,
                                    visit.meeting_description_id,
                                    visit.location_id,
                                    loginStore,
                                    'future',
                                );
                                toast.success('Your timeline has been adjusted!');
                                /*}
                                      else {
                                        toast.warning("Your timeline could not be adjusted!");
                                      }*/
                              }
                            }}
                        >
                          <Icon id="trash"></Icon>
                        </div>

                        <span className='small-font'>
                    <b>
                      {visit.datetime} - {visit.lodge_name} No. {visit.lodge_number} -{' '}
                      {parseInt(visit.meeting_type_id) === 1
                          ? `Meeting -  ${visit.meeting_description}`
                          : parseInt(visit.meeting_type_id) === 3
                              ? `Emergency Meeting -  ${visit.meeting_description}`
                              : 'Installation'}
                    </b>
                    <b>{parseInt(visit.meeting_description_id) === 9 ? ' - ' + visit.meeting_description_extra : ''}</b>
                    <br/>
                          {visit.location_name}, {visit.location_address}, {visit.location_city}
                  </span>

                      </CardBody>
                    </Card>
                ))}
                <hr/>
                <span className='small-font'>
              {loginStore.visitationMeetingsPast.length < 1
                  ? 'Currently no visits logged.'
                  : 'Recorded attendance: (' + loginStore.visitationMeetingsPast.length + ')'}
            </span>
                {Object.keys(formattedMeetings)
                    .reverse()
                    .map(year => (
                        <div key={year}>
                          <div className='centered-header'>
                            <h5 className='lined-heading'>{year}</h5>
                          </div>
                          {Object.keys(formattedMeetings[year])
                              .reverse()
                              .map(month => (
                                  <div key={month}>
                                    {/*<h3>{this.getMonthName(`${parseInt(month) < 10 ? '0' : ''}${month}`)}</h3>*/}
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                      <h6>{this.getMonthName(parseInt(month))}</h6>
                                    </div>
                                    {formattedMeetings[year][month].map((visit, index) => (
                                        <Card key={index} className={`quote-option-active`} style={{cursor: 'default'}}>
                                          <CardBody>
                                            <div
                                                className='pointing-cursor promoted-sponsored-info-button'
                                                onClick={async () => {
                                                  if (
                                                      window.confirm('Are you sure you wish to remove this meeting from your timeline?')
                                                  ) {
                                                    logVisitMeetingStore.removeLogAttendance(
                                                        visit.datetime,
                                                        0,
                                                        visit.lodge_id,
                                                        visit.meeting_description_id,
                                                        visit.location_id,
                                                        loginStore,
                                                        'past',
                                                    );
                                                    toast.success('Your timeline has been adjusted!');
                                                  }
                                                }}
                                            >
                                              <Icon id="trash"></Icon>
                                            </div>

                                            <span className='small-font'>
                                <b>
                                  {visit.datetime} - {visit.lodge_name} No. {visit.lodge_number} -{' '}
                                  {parseInt(visit.meeting_type_id) === 1
                                      ? `Meeting -  ${visit.meeting_description}`
                                      : parseInt(visit.meeting_type_id) === 3
                                          ? `Emergency Meeting -  ${visit.meeting_description}`
                                          : 'Installation'}
                                </b>
                                <br/>
                                              {visit.location_name}, {visit.location_address}, {visit.location_city}
                              </span>
                                          </CardBody>
                                        </Card>
                                    ))}
                                  </div>
                              ))}
                        </div>
                    ))}
                <br/>
              </div>
          ) : this.state.page == 3 ? (
              <div>
                <div>
                  <h5>Personal details:</h5>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                    type='checkbox'
                                    name='display_on_leaderboard'
                                    checked={settingsInput.display_on_leaderboard}
                                    disabled={false}
                                    onClick={() => {
                                      settingsInput.display_on_leaderboard = !settingsInput.display_on_leaderboard;
                                      /*if (!settingsInput.send_emails) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        }*/
                                    }}
                                />{' '}
                                Display on Leaderboard
                              </Label>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Display your statistics on the global leaderboard)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                    type='checkbox'
                                    name='display_public_profile'
                                    checked={settingsInput.display_public_profile}
                                    disabled={false}
                                    onClick={() => {
                                      settingsInput.display_public_profile = !settingsInput.display_public_profile;
                                      /*if (!settingsInput.send_emails) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        }*/
                                    }}
                                />{' '}
                                Display Public Profile
                              </Label>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Users will be able to see your public profile)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <hr/>
                <div>
                  <h5>Customization:</h5>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                    type='checkbox'
                                    name='customize_feed'
                                    checked={settingsInput.customize_feed}
                                    disabled={false}
                                    onClick={() => {
                                      settingsInput.customize_feed = !settingsInput.customize_feed;
                                      /*if (!settingsInput.send_emails) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        }*/
                                    }}
                                />{' '}
                                Customize Feed
                              </Label>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Use your activity within the system to filter your feed accordingly)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <hr/>
                <div>
                  <h5>Notifications:</h5>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                                type='checkbox'
                                name='enable_notifications'
                                checked={settingsInput.enable_notifications}
                                onClick={() => {
                                  settingsInput.enable_notifications = !settingsInput.enable_notifications;
                                  //this.props.applicationStore.validationResult = {};
                                  /*if (!settingsInput.enable_notifications) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                          settingsInput.send_emails = false;
                                        }*/
                                }}
                            />{' '}
                            Enable Notifications
                          </Label>
                        </FormGroup>
                        <br/>
                        <span className='small-font'>
                      <i> (Global setting to enable or disable all email notifications)</i>
                    </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <h5>Updates</h5>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <h5>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='send_emails'
                                      checked={settingsInput.send_emails}
                                      disabled={!settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.send_emails = !settingsInput.send_emails;
                                      }}
                                  />{' '}
                                  Daily Email
                                </Label>
                              </h5>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Receive a daily email about upcoming masonic activities)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div
                          id='daily_email'
                          className={`toggle-email-setting-div${settingsInput.send_emails ? '-open' : '-closed'}`}
                      >
                        <b>Meetings</b>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.all_meetings}
                                      disabled={!settingsInput.send_emails || !settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.all_meetings = !settingsInput.all_meetings;

                                        if (settingsInput.all_meetings) {
                                          settingsInput.custom_notifications = false;
                                          settingsInput.only_watch_list = false;
                                        } else {
                                          settingsInput.all_meetings = true;
                                        }
                                        //settingsInput.only_watch_list = !settingsInput.all_meetings;
                                        //settingsInput.custom_notifications = !settingsInput.all_meetings;
                                      }}
                                  />{' '}
                                  All Lodges
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (For all meetings, not filtered)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.only_watch_list}
                                      disabled={!settingsInput.send_emails || !settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.only_watch_list = !settingsInput.only_watch_list;

                                        if (settingsInput.only_watch_list) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.custom_notifications = false;
                                        } else {
                                          settingsInput.all_meetings = true;
                                        }
                                        //settingsInput.all_meetings = !settingsInput.only_watch_list;
                                        //settingsInput.custom_notifications = !settingsInput.only_watch_list;
                                      }}
                                  />{' '}
                                  Only My Watch List
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (For meetings, filtered based on your watch list)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>

                        {settingsInput.only_watch_list ? (
                            <div>
                              <h6>Lodge Watch List:</h6>
                              {profileStore.userWatchList.map((lodge, index) => (
                                  <Card className='quote-option-active'>
                                    <CardBody>
                                      <Row>
                                        <Col sm='12'>
                                          {lodge.name} No. {lodge.number} {lodge.short_constitution} {lodge.degree}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                              ))}
                            </div>
                        ) : (
                            ''
                        )}

                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='custom_notifications'
                                      checked={settingsInput.custom_notifications}
                                      disabled={!settingsInput.send_emails || !settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.custom_notifications = !settingsInput.custom_notifications;

                                        if (settingsInput.custom_notifications) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        } else {
                                          settingsInput.all_meetings = true;
                                        }
                                        //settingsInput.all_meetings = !settingsInput.custom_notifications;
                                        //settingsInput.only_watch_list = !settingsInput.custom_notifications;
                                      }}
                                  />{' '}
                                  Custom
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Filter meetings)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        {settingsInput.custom_notifications ? (
                            <Row>
                              <Col>
                                <MultiSelectInput
                                    id='constitutions'
                                    label='Constitutions'
                                    invalid={!!validationResult.filter_constitutions}
                                    validationMessage={validationResult.filter_constitutions}
                                    value={selectedOptionsDaily}
                                    onChange={selectedOptions => {
                                      if (selectedOptions != null) {
                                        // Extract the values from selectedOptions and build an array
                                        const selectedValues = selectedOptions.map(option => option.value);
                                        settingsInput.filter_constitutions = selectedValues;
                                      }
                                    }}
                                    options={this.removeDuplicates(configurationStore.constitutionResults)} // needs to be unique values
                                    //options={[{ "value": "5", "label": "Label for ID 5" }]} // needs to be unique values
                                />
                              </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        <hr/>
                        <b>Socials</b>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='all_socials_notifications'
                                      checked={settingsInput.all_socials_notifications}
                                      disabled={!settingsInput.send_emails || !settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.all_socials_notifications = !settingsInput.all_socials_notifications;

                                        settingsInput.day_socials_notifications = !settingsInput.all_socials_notifications;
                                      }}
                                  />{' '}
                                  All Socials
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Include all listed socials)</i>
                          </span>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='day_socials_notifications'
                                      checked={settingsInput.day_socials_notifications}
                                      disabled={!settingsInput.send_emails || !settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.day_socials_notifications = !settingsInput.day_socials_notifications;

                                        settingsInput.all_socials_notifications = !settingsInput.day_socials_notifications;
                                      }}
                                  />{' '}
                                  Todays Socials
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Include socials which are happening on the day)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <h5>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='email_notification_weekly_meetings'
                                      checked={settingsInput.email_notification_weekly_meetings}
                                      disabled={!settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.email_notification_weekly_meetings = !settingsInput.email_notification_weekly_meetings;
                                      }}
                                  />{' '}
                                  Weekly Email
                                </Label>
                              </h5>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Receive a weekly email about upcoming masonic activities, sent on Sunday)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div
                          id='weekly_email'
                          className={`toggle-email-setting-div${
                              settingsInput.email_notification_weekly_meetings ? '-open' : '-closed'
                          }`}
                      >
                        <b>Meetings</b>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.all_lodge_weekly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_weekly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.all_lodge_weekly_notifications = !settingsInput.all_lodge_weekly_notifications;

                                        if (settingsInput.all_lodge_weekly_notifications) {
                                          settingsInput.watch_list_weekly_notifications = false;
                                          settingsInput.custom_weekly_notifications = false;
                                        } else {
                                          settingsInput.all_lodge_weekly_notifications = true;
                                        }
                                        //settingsInput.watch_list_weekly_notifications = !settingsInput.email_notification_weekly_meetings;
                                        //settingsInput.custom_weekly_notifications = !settingsInput.email_notification_weekly_meetings;
                                      }}
                                  />{' '}
                                  All Lodges
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (For all meetings, not filtered)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.watch_list_weekly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_weekly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.watch_list_weekly_notifications = !settingsInput.watch_list_weekly_notifications;

                                        if (settingsInput.watch_list_weekly_notifications) {
                                          settingsInput.all_lodge_weekly_notifications = false;
                                          settingsInput.custom_weekly_notifications = false;
                                        } else {
                                          settingsInput.all_lodge_weekly_notifications = true;
                                        }
                                        //settingsInput.all_lodge_weekly_notifications = !settingsInput.watch_list_weekly_notifications;
                                        //settingsInput.custom_weekly_notifications = !settingsInput.watch_list_weekly_notifications;
                                      }}
                                  />{' '}
                                  Only My Watch List
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (For meetings, filtered based on your watch list)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>

                        {settingsInput.watch_list_weekly_notifications ? (
                            <div>
                              <h6>Lodge Watch List:</h6>
                              {profileStore.userWatchList.map((lodge, index) => (
                                  <Card className='quote-option-active'>
                                    <CardBody>
                                      <Row>
                                        <Col sm='12'>
                                          {lodge.name} No. {lodge.number} {lodge.short_constitution} {lodge.degree}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                              ))}
                            </div>
                        ) : (
                            ''
                        )}

                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.custom_weekly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_weekly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.custom_weekly_notifications = !settingsInput.custom_weekly_notifications;
                                        if (settingsInput.custom_weekly_notifications) {
                                          settingsInput.all_lodge_weekly_notifications = false;
                                          settingsInput.watch_list_weekly_notifications = false;
                                        } else {
                                          settingsInput.all_lodge_weekly_notifications = true;
                                        }
                                        //settingsInput.all_lodge_weekly_notifications = !settingsInput.custom_weekly_notifications;
                                        //settingsInput.watch_list_weekly_notifications = !settingsInput.custom_weekly_notifications;
                                      }}
                                  />{' '}
                                  Custom
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Filter meetings)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        {settingsInput.custom_weekly_notifications ? (
                            <Row>
                              <Col>
                                <MultiSelectInput
                                    id='constitutions'
                                    label='Constitutions'
                                    invalid={!!validationResult.filter_weekly_constitutions}
                                    validationMessage={validationResult.filter_weekly_constitutions}
                                    value={settingsInput.filter_weekly_constitutions}
                                    onChange={selectedOptions => {
                                      if (selectedOptions != null) {
                                        // Extract the values from selectedOptions and build an array
                                        const selectedValues = selectedOptions.map(option => option.value);
                                        settingsInput.filter_weekly_constitutions = selectedValues;
                                      }
                                    }}
                                    options={this.removeDuplicates(configurationStore.constitutionResults)} // needs to be unique values
                                />
                              </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        <hr/>
                        <b>Socials</b>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='all_socials_weekly_notifications'
                                      checked={settingsInput.all_socials_weekly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_weekly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.all_socials_weekly_notifications = !settingsInput.all_socials_weekly_notifications;

                                        settingsInput.week_socials_weekly_notifications = !settingsInput.all_socials_weekly_notifications;
                                      }}
                                  />{' '}
                                  All Socials
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Include all listed socials)</i>
                          </span>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='send_emails'
                                      checked={settingsInput.week_socials_weekly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_weekly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.week_socials_weekly_notifications = !settingsInput.week_socials_weekly_notifications;

                                        settingsInput.all_socials_weekly_notifications = !settingsInput.week_socials_weekly_notifications;
                                      }}
                                  />{' '}
                                  Weeks Socials
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Include socials which are happening for the current week)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <h5>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='email_notification_monthly_meetings'
                                      checked={settingsInput.email_notification_monthly_meetings}
                                      disabled={!settingsInput.enable_notifications}
                                      onClick={() => {
                                        settingsInput.email_notification_monthly_meetings = !settingsInput.email_notification_monthly_meetings;
                                      }}
                                  />{' '}
                                  Monthly Email
                                </Label>
                              </h5>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Receive a monthly email about upcoming masonic activities)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div
                          id='monthly_email'
                          className={`toggle-email-setting-div${
                              settingsInput.email_notification_monthly_meetings ? '-open' : '-closed'
                          }`}
                      >
                        <b>Meetings</b>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.all_lodge_monthly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_monthly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.all_lodge_monthly_notifications = !settingsInput.all_lodge_monthly_notifications;

                                        if (settingsInput.all_lodge_monthly_notifications) {
                                          settingsInput.watch_list_monthly_notifications = false;
                                          settingsInput.custom_monthly_notifications = false;
                                        } else {
                                          settingsInput.all_lodge_monthly_notifications = true;
                                        }
                                        //settingsInput.watch_list_monthly_notifications = !settingsInput.email_notification_monthly_meetings;
                                        //settingsInput.custom_monthly_notifications = !settingsInput.email_notification_monthly_meetings;
                                      }}
                                  />{' '}
                                  All Lodges
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (For all meetings, not filtered)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.watch_list_monthly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_monthly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.watch_list_monthly_notifications = !settingsInput.watch_list_monthly_notifications;

                                        if (settingsInput.watch_list_monthly_notifications) {
                                          settingsInput.all_lodge_monthly_notifications = false;
                                          settingsInput.custom_monthly_notifications = false;
                                        } else {
                                          settingsInput.all_lodge_monthly_notifications = true;
                                        }
                                        //settingsInput.all_lodge_monthly_notifications = !settingsInput.watch_list_monthly_notifications;
                                        //settingsInput.custom_monthly_notifications = !settingsInput.watch_list_monthly_notifications;
                                      }}
                                  />{' '}
                                  Only My Watch List
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (For meetings, filtered based on your watch list)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>

                        {settingsInput.watch_list_monthly_notifications ? (
                            <div>
                              <h6>Lodge Watch List:</h6>
                              {profileStore.userWatchList.map((lodge, index) => (
                                  <Card className='quote-option-active'>
                                    <CardBody>
                                      <Row>
                                        <Col sm='12'>
                                          {lodge.name} No. {lodge.number} {lodge.short_constitution} {lodge.degree}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                              ))}
                            </div>
                        ) : (
                            ''
                        )}

                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='radioToday'
                                      checked={settingsInput.custom_monthly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_monthly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.custom_monthly_notifications = !settingsInput.custom_monthly_notifications;
                                        if (settingsInput.custom_monthly_notifications) {
                                          settingsInput.all_lodge_monthly_notifications = false;
                                          settingsInput.watch_list_monthly_notifications = false;
                                        } else {
                                          settingsInput.all_lodge_monthly_notifications = true;
                                        }
                                        //settingsInput.all_lodge_monthly_notifications = !settingsInput.custom_monthly_notifications;
                                        //settingsInput.watch_list_monthly_notifications = !settingsInput.custom_monthly_notifications;
                                      }}
                                  />{' '}
                                  Custom
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Filter meetings)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        {settingsInput.custom_monthly_notifications ? (
                            <Row>
                              <Col>
                                <MultiSelectInput
                                    id='constitutions'
                                    label='Constitutions'
                                    invalid={!!validationResult.filter_monthly_constitutions}
                                    validationMessage={validationResult.filter_monthly_constitutions}
                                    value={settingsInput.filter_monthly_constitutions}
                                    onChange={selectedOptions => {
                                      if (selectedOptions != null) {
                                        // Extract the values from selectedOptions and build an array
                                        const selectedValues = selectedOptions.map(option => option.value);
                                        settingsInput.filter_monthly_constitutions = selectedValues;
                                      }
                                    }}
                                    options={this.removeDuplicates(configurationStore.constitutionResults)} // needs to be unique values
                                />
                              </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        <hr/>
                        <b>Socials</b>
                        <Row>
                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='all_socials_monthly_notifications'
                                      checked={settingsInput.all_socials_monthly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_monthly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.all_socials_monthly_notifications = !settingsInput.all_socials_monthly_notifications;

                                        settingsInput.month_socials_monthly_notifications = !settingsInput.all_socials_monthly_notifications;
                                      }}
                                  />{' '}
                                  All Socials
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Include all listed socials)</i>
                          </span>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                      type='checkbox'
                                      name='send_emails'
                                      checked={settingsInput.month_socials_monthly_notifications}
                                      disabled={
                                          !settingsInput.email_notification_monthly_meetings ||
                                          !settingsInput.enable_notifications
                                      }
                                      onClick={() => {
                                        settingsInput.month_socials_monthly_notifications = !settingsInput.month_socials_monthly_notifications;

                                        settingsInput.all_socials_monthly_notifications = !settingsInput.month_socials_monthly_notifications;
                                      }}
                                  />{' '}
                                  Months Socials
                                </Label>
                              </FormGroup>
                              <br/>
                              <span className='small-font'>
                            <i> (Include socials which are happening for the current month)</i>
                          </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>

                  <h5>Reports</h5>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                    type='checkbox'
                                    name='email_notification_lodge_attendance_report'
                                    checked={settingsInput.email_notification_lodge_attendance_report}
                                    disabled={!settingsInput.enable_notifications}
                                    onClick={() => {
                                      settingsInput.email_notification_lodge_attendance_report = !settingsInput.email_notification_lodge_attendance_report;
                                      /*if (!settingsInput.send_emails) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        }*/
                                    }}
                                />{' '}
                                Monthly Attendance
                              </Label>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Receive an email with all your lodges attendance records)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <h5>System</h5>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                    type='checkbox'
                                    name='email_notification_for_suggestions'
                                    checked={settingsInput.email_notification_for_suggestions}
                                    disabled={!settingsInput.enable_notifications}
                                    onClick={() => {
                                      settingsInput.email_notification_for_suggestions = !settingsInput.email_notification_for_suggestions;
                                      /*if (!settingsInput.send_emails) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        }*/
                                    }}
                                />{' '}
                                Enable Email Notifications for Suggestions
                              </Label>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Receive email notification when a new suggestion is added)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                    type='checkbox'
                                    name='email_notification_for_new_socials'
                                    checked={settingsInput.email_notification_for_new_socials}
                                    disabled={!settingsInput.enable_notifications}
                                    onClick={() => {
                                      settingsInput.email_notification_for_new_socials = !settingsInput.email_notification_for_new_socials;
                                      /*if (!settingsInput.send_emails) {
                                          settingsInput.all_meetings = false;
                                          settingsInput.only_watch_list = false;
                                        }*/
                                      // !!! TESTING
                                      this.forceUpdate();
                                    }}
                                />{' '}
                                Enable Email Notifications for New Socials
                              </Label>
                            </FormGroup>
                            <br/>
                            <span className='small-font'>
                          <i> (Receive email notification when a new social is added)</i>
                        </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <br/>
                  <hr/>
                  <Button
                      color='primary'
                      onClick={async () => {
                        // return a true or flase based on succcess
                        this.props.profileStore.updateNotifications(loginStore.miniToken);
                        // GLOBAL
                        loginStore.enable_notifications = settingsInput.enable_notifications;
                        //DAILY
                        loginStore.email_notification_daily_meetings = settingsInput.send_emails;
                        // meeting specific
                        loginStore.all_lodge_notifications = settingsInput.all_meetings;
                        loginStore.watch_list_notifications = settingsInput.only_watch_list;
                        loginStore.custom_notifications = settingsInput.custom_notifications;
                        loginStore.filter_constitutions = settingsInput.filter_constitutions;
                        // social specific
                        loginStore.all_socials_notifications = settingsInput.all_socials_notifications;
                        loginStore.day_socials_notifications = settingsInput.day_socials_notifications;

                        // WEEKLY
                        loginStore.email_notification_weekly_meetings = settingsInput.email_notification_weekly_meetings;
                        // meeting specific
                        loginStore.all_lodge_weekly_notifications = settingsInput.all_lodge_weekly_notifications;
                        loginStore.watch_list_weekly_notifications = settingsInput.watch_list_weekly_notifications;
                        loginStore.custom_weekly_notifications = settingsInput.custom_weekly_notifications;
                        loginStore.filter_weekly_constitutions = settingsInput.filter_weekly_constitutions;
                        // social specific
                        loginStore.all_socials_weekly_notifications = settingsInput.all_socials_weekly_notifications;
                        loginStore.week_socials_weekly_notifications = settingsInput.week_socials_weekly_notifications;

                        // MONTHLY
                        loginStore.email_notification_monthly_meetings = settingsInput.email_notification_monthly_meetings;
                        // meeting specific
                        loginStore.all_lodge_monthly_notifications = settingsInput.all_lodge_monthly_notifications;
                        loginStore.watch_list_monthly_notifications = settingsInput.watch_list_monthly_notifications;
                        loginStore.custom_monthly_notifications = settingsInput.custom_monthly_notifications;
                        loginStore.filter_monthly_constitutions = settingsInput.filter_monthly_constitutions;
                        // social specific
                        loginStore.all_socials_monthly_notifications = settingsInput.all_socials_monthly_notifications;
                        loginStore.month_socials_monthly_notifications = settingsInput.month_socials_monthly_notifications;
                        //REPORTS
                        loginStore.email_notification_lodge_attendance_report =
                            settingsInput.email_notification_lodge_attendance_report;
                        // SYSTEM
                        loginStore.email_notification_for_suggestions = settingsInput.email_notification_for_suggestions;
                        loginStore.email_notification_for_new_socials = settingsInput.email_notification_for_new_socials;

                        loginStore.display_on_leaderboard = settingsInput.display_on_leaderboard;
                        loginStore.display_public_profile = settingsInput.display_public_profile;
                        loginStore.customize_feed = settingsInput.customize_feed;
                        // show if successfully saved
                        toast.success('Settings saved.');
                        // show if unsuccessful
                        //toast.warning("Settings could not be saved.");
                      }}
                  >
                    Save Settings
                  </Button>
                </div>
              </div>
          ) : this.state.page == 4 ? (
              <div>
                <div>
                  <Card className='post-option-active'>
                    <CardBody>
                      <Row>
                        <Col className='user-post-box'>
                          <img
                              className='user-post-box-image'
                              src='logo.png'
                              alt='logo'
                              height='30px'
                              onClick={() => {
                                //this.props.profileStep();
                              }}
                          />
                          <div
                              className='user-post-box-text-field'
                              onClick={async () => {
                                profileStore.toggleUserPostModal();
                              }}
                          >
                            <span>What's on your mind, {loginStore.username}?</span>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                {profileStore.feed.length > 0 ? (
                    <div>
                        <div>
                          {profileStore.feed.map((post, index) => (
                              <Card className='post-option-active'>
                                <CardBody className="card-padding-override">
                                  {post.meeting_type == '' ? (
                                      <div
                                          className='pointing-cursor promoted-sponsored-info-button'
                                          onClick={() => {
                                            //profileStore.showContextMenu(index);
                                            //profileStore.postsContextMenu[index] = !profileStore.postsContextMenu[index];

                                            this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                            this.setState({postsContextMenu: this.state.postsContextMenu});
                                          }}
                                      >
                                        <Icon id="options"></Icon>
                                      </div>
                                  ) : (
                                      ''
                                  )}
                                  {post.meeting_type == '' ? (
                                      <div
                                          className={`pointing-cursor promoted-sponsored-info-button context-menu ${
                                              this.state.postsContextMenu[index] ? 'context-menu-open' : 'context-menu-closed'
                                          }`}
                                      >
                            <span
                                onClick={() => {
                                  //profileStore.hideContextMenu(index);
                                  //profileStore.postsContextMenu[index] = !profileStore.postsContextMenu[index];

                                  this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                  this.setState({postsContextMenu: this.state.postsContextMenu});
                                }}
                            >
                              <Icon id="edit"></Icon>
                              <span> Edit post</span>
                            </span>
                                        <br/>
                                        <span
                                            onClick={() => {
                                              //profileStore.hideContextMenu(index);
                                              //profileStore.postsContextMenu[index] = !profileStore.postsContextMenu[index];
                                              this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                              this.setState({postsContextMenu: this.state.postsContextMenu});
                                            }}
                                        >
                              <Icon id="membership"></Icon>
                                      <span> Edit audience</span>
                            </span>
                                        <br/>
                                        <span
                                            onClick={() => {
                                              //profileStore.hideContextMenu(index);
                                              //profileStore.postsContextMenu[index] = !profileStore.postsContextMenu[index];

                                              this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                              this.setState({postsContextMenu: this.state.postsContextMenu});
                                            }}
                                        >
                              <Icon id="calendar"></Icon>
                                      <span> Edit date</span>
                            </span>
                                        <hr/>
                                        <span
                                            onClick={() => {

                                              //--
                                              let success;
                                              if (post.visible) {
                                                success = profileStore.editUserPost(loginStore.miniToken, post.public_id, 'hide');
                                                if (success) {
                                                  toast.success('Post has been hidden!');
                                                } else {
                                                  toast.warning('Could not hide this post!');
                                                }
                                              } else {
                                                success = profileStore.editUserPost(loginStore.miniToken, post.public_id, 'show');
                                                if (success) {
                                                  toast.success('Post is now visible!');
                                                } else {
                                                  toast.warning('Could not set post to visible!');
                                                }
                                              }

                                              //profileStore.hideContextMenu(index);
                                              this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                              this.setState({postsContextMenu: this.state.postsContextMenu});
                                              post.visible = !post.visible;
                                            }}
                                        >
                              {post.visible ? (
                                  <Icon id="invisible" text=""></Icon>
                              ) : (
                                  <Icon id="visible" text=""></Icon>
                              )}
                                          {post.visible ? ' Hide ' : ' Show '} post
                            </span>
                                        <br/>
                                        <span
                                            onClick={async () => {
                                              if (window.confirm('Are you sure you want to delete this meeting?')) {
                                                //profileStore.hideContextMenu(index);
                                                let success = await profileStore.editUserPost(
                                                    loginStore.miniToken,
                                                    post.public_id,
                                                    'delete',
                                                );
                                                // if success

                                                if (success) {
                                                  delete profileStore.feed[index];
                                                  // might need to remove from context menu array aswell
                                                  //delete profileStore.postsContextMenu[index];
                                                  toast.success('Post has been deleted!');
                                                } else {
                                                  toast.warning('Could not delete this post!');
                                                }
                                              }
                                              // hide context menu
                                              //profileStore.hideContextMenu(index);
                                              this.state.postsContextMenu[index] = !this.state.postsContextMenu[index];
                                              this.setState({postsContextMenu: this.state.postsContextMenu});
                                            }}
                                        >
                              <Icon id="trash"></Icon>
                                      <span> Delete post</span>
                            </span>
                                      </div>
                                  ) : (
                                      ''
                                  )}
                                  <Row>
                                    <Col className='col-sm-1 post_header_logo'>
                                      <img
                                          src='logo.png'
                                          alt='logo'
                                          height='40px'
                                          onClick={() => {
                                            //this.props.profileStep();
                                          }}
                                      />
                                    </Col>
                                    <Col className='col-sm-11 post_header_title pointing-cursor'>
                          <span
                              onClick={() => {
                                //this.props.profileStep();
                              }}
                          >
                            {/*loginStore.username*/}
                            {post.title}
                          </span>
                                      <br/>
                                      <Icon id="clock"></Icon>
                                      <span className='x-small-font'> {post.date_time} </span>
                                      {post.post_type == 1 ? (
                                          <Icon id="globe"></Icon>
                                      ) : (
                                          ''
                                      )}
                                      {post.post_type == 1 ? (
                                          <span className='x-small-font'>
                                        <a
                                            href={`http://maps.google.com/maps?q=${post.gps !== '' ? post.gps : ''}`}
                                            target='_blank'
                                        >
                                          {post.address}
                                        </a>
                                      </span>
                                      ) : (
                                          ''
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      {post.description.length > 100 ? (
                                          <span id={`meeting_description_cut_${index}`}
                                                className='show_post_description pre pre-code'>
                              {post.description.substring(0, 200) + '...'}
                                            <Button
                                                className='small-font'
                                                color='#007bff'
                                                onClick={() => {
                                                  this.getDescription('meeting_', index);
                                                }}
                                            >
                                see more
                              </Button>
                            </span>
                                      ) : (
                                          ''
                                      )}
                                      <span
                                          id={`meeting_description_full_${index}`}
                                          className={`${
                                              post.description.length < 100
                                                  ? 'show_post_description pre pre-code'
                                                  : 'hide_post_description pre pre-code'
                                          }`}
                                      >
                            {post.description}
                          </span>
                                    </Col>
                                  </Row>
                                  {post.post_type == 1 ? (
                                      <Row>
                                        <Col sm='12' className='post-restricted-height'>
                                          <img className='feed-wall-image' src={post.image}/>
                                        </Col>
                                      </Row>
                                  ) : post.image !== '' ? (
                                      <Row>
                                        <Col sm='12' className='post-restricted-height'>
                                          {post.image.includes('.pdf') ? (
                                              <div>
                                                <embed
                                                    src={process.env.REACT_APP_API_URL + post.image + '#toolbar=0'}
                                                    type='application/pdf'
                                                    className='width-height-hundred'
                                                />
                                                <br/>
                                                <iframe
                                                    src={process.env.REACT_APP_API_URL + post.image + '#toolbar=0'}
                                                    className='width-height-hundred'
                                                />
                                              </div>
                                          ) : post.image.includes('.mp3') ? (
                                              <audio controls>
                                                <source src={process.env.REACT_APP_API_URL + post.image} type='audio/mp3'/>
                                                Your browser does not support the audio tag.
                                              </audio>
                                          ) : post.image.includes('.mp4') ? (
                                              <video controls className='video-container'>
                                                <source src={process.env.REACT_APP_API_URL + post.image} type='video/mp4'/>
                                                Your browser does not support the video tag.
                                              </video>
                                          ) : (
                                              <img className='feed-wall-image'
                                                   src={process.env.REACT_APP_API_URL + post.image}/>
                                          )}
                                        </Col>
                                      </Row>
                                  ) : (
                                      ''
                                  )}
                                  {post.meeting_type == '' ? (
                                      <div>
                                        <hr/>
                                        <Row className="row-justified-and-centered small-font">
                                          <Col>
                                            <Button className="small-font" color=""
                                                    onClick={async () => {
                                                      //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                                      //this.props.contactStep();
                                                      let res = await viewProfileStore.likePost(loginStore.miniToken, post.public_id);
                                                      if (res) {
                                                        toast.success("You have liked this post!");

                                                        post.liked = !post.liked;

                                                        // alternative to this.forceUpdate solution
                                                        //this.state.postsLiked[index] = !this.state.postsLiked[index];
                                                        //this.setState({postsLiked: this.state.postsLiked});

                                                        // NB!!! not what I want to do here
                                                        //this.forceUpdate();
                                                        //viewProfileStore.setLiked(index);
                                                      } else {
                                                        toast.warning("You are unable to like this post!");
                                                      }
                                                    }}>

                                              {post.liked ?
                                                  <Icon id="like" accent_color="#2859C5" base_color="#8FBFFA"
                                                        text="Liked"></Icon>
                                                  :
                                                  <Icon id="like" text="Like"></Icon>
                                              }
                                              {post.likes_count > 0 ?
                                                  <sup> ({post.likes_count})</sup>
                                                  :
                                                  ''}
                                            </Button>
                                          </Col>
                                          <Col>
                                            <Button className="small-font" color=""
                                                    onClick={async () => {
                                                      //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                                      //this.props.contactStep();
                                                      console.log("sending post data to comment modal");
                                                      console.log(post);
                                                      viewProfileStore.setPostData(post, index);
                                                      viewProfileStore.toggleUserPostCommentModal();
                                                    }}>
                                              <Icon id="comment" text="Comment"></Icon>
                                              {post.comments.length > 0 ?
                                                  <sup> ({post.comments.length})</sup>
                                                  :
                                                  ''}
                                            </Button>
                                          </Col>
                                          <Col>
                                            <Button className="small-font" color=""
                                                    onClick={async () => {
                                                      //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                                      //this.props.contactStep();
                                                    }}>
                                              <Icon id="share" text="Share"></Icon>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                  ) : ''}
                                  {post.meeting_type == '' && post.comments && post.comments.length > 0 ?
                                      <div>
                                        <hr/>
                                        {/* comments sections - show the only one comment, the last/latest one */}

                                        {post.comments.slice(0, 2).map((comment, index) => (
                                                <div>
                                                  <Row>
                                                    <Col className="col-sm-1 post_header_logo">
                                                      <img
                                                          className="user-post-comment-image"
                                                          src='logo.png'
                                                          alt='logo'
                                                          height='20px'
                                                          onClick={() => {
                                                            //this.props.profileStep();
                                                          }}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <div className="user-post-comment">
                                                        <span className="x-small-font"><b>{comment.name}</b></span>
                                                        <br/>
                                                        <span className="small-font">{comment.comment}</span>
                                                      </div>
                                                      <br/>
                                                    </Col>
                                                  </Row>
                                                </div>
                                            )
                                        )}
                                      </div>
                                      : ''
                                  }

                                </CardBody>
                              </Card>
                          ))}
                        </div>
                        <hr/>
                        <div>
                            {profileStore.feed.map((post, index) => (
                              <Post
                                  post={post}
                                  index={index}
                                  context="personal"
                                  /*feedWallStep={null}*/
                                  viewProfileStore={this.props.viewProfileStore}
                                  loginStore={this.props.loginStore}
                                  profileStore={this.props.profileStore}
                            ></Post>
                          ))}
                        </div>
                    </div>
    )
  :
    (
        'You have made no posts yet'
    )
  }
  </div>
  ) :
    this.state.page == 5 ? (
        <div>
          Sponsored Posts
          <div>
            {loginStore.sponsored_posts.map((advert, index) => (
                <Card className='post-option-active'>
                  <CardBody>
                    <Row>
                      <Col sm='6'>
                        <b>{advert.title}</b>
                        <br/>
                        {advert.description.length > 100 ? (
                            <span id={`sponsored_post_description_cut_${index}`}
                                        className='show_post_description'>
                            {advert.description.substring(0, 200) + '...'}
                                    <Button
                                        className='small-font'
                                        color='#007bff'
                                        onClick={() => {
                                          this.getDescription('sponsored_post_', index);
                                        }}
                                    >
                              see more
                            </Button>
                          </span>
                              ) : (
                                  ''
                              )}
                              <span
                                  id={`sponsored_post_description_full_${index}`}
                                  className={`${
                                      advert.description.length < 100
                                          ? 'show_post_description pre pre-code'
                                          : 'hide_post_description pre pre-code'
                                  }`}
                              >
                          {advert.description}
                                {/*lodge_meeting.post_type == 2
                                    ?
                                    <div>
                                      <br/>
                                      Host: {lodge_meeting.contact_name}
                                      <br/>
                                      Tel: {lodge_meeting.contact_number}
                                      <br/>
                                      Email: {lodge_meeting.contact_email}
                                    </div>
                                     : ''
                                   */}
                        </span>
                              <br/>
                              <img
                                  className='feed-wall-image'
                                  src={process.env.REACT_APP_API_URL + '/sponsors/' + advert.image}
                              />
                            </Col>
                            <Col sm='6'>
                        <span className='small-font-italic'>
                          Started: {advert.started}
                          <br/>
                          Ends: {advert.ends}
                          <br/>
                          Running for: {advert.duration}
                          <br/>
                          Total views: {advert.all_views}
                          <br/>
                          Total clicks: {advert.all_clicks}
                          <br/>
                          Click Through Rate (CTR): {advert.click_through_rate.toFixed(2)}%
                          <br/>
                          Spent: {advert.cost} of Initial budget: {advert.initial_budget}
                          <br/>
                          Remaining Budget: {advert.active ? advert.remaining_budget : '0.00'}
                          <br/>
                          Avg views pd: {advert.avg_views_per_day}
                          <br/>
                          Avg clicks pd: {advert.avg_clicks_per_day}
                          <br/>
                          Stats updated at: {advert.stats_updated_at}
                          <br/>
                          Status: {advert.active ? 'Active' : 'Inactive'}
                        </span>
                            </Col>
                          </Row>
                          {advert.active ? (
                              <Row>
                                <Col sm='12'>
                                  <Button
                                      className='small-font'
                                      onClick={async () => {
                                        if (window.confirm('You are sure you want to cancel this sponsored post!')) {
                                          //promotePostStore.setPostData(lodge_meeting);
                                          //this.props.promotePostStep();

                                          profileStore.cancelSponsoredPost(loginStore.miniToken, advert.public_id);
                                        }
                                      }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                              </Row>
                          ) : (
                              ''
                          )}
                        </CardBody>
                      </Card>
                  ))}
                </div>
              </div>
          ) : (
              ''
          )}
        </div>

    );
  }
}

export default Profile;
