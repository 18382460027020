// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  loginStep: () => void;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('thankYouStore', 'contributeStore', 'loginStore',)
@observer
export class ThankYou extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.thankYouStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const thankYouStore = this.props.thankYouStore;//typeOfThankYou

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Thank you for your {thankYouStore.typeOfThankYou}</h1>
          </Col>
        </Row>
        <br />
        <div id='navigation_buttons'>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          ): ''}
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
          {!loginStore.isLoggedIn ? (
          <Button color="primary" onClick={this.props.loginStep}>
            Login
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default ThankYou;
