// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';

import { LeaderboardStore } from '../../stores/leaderboard-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { ViewProfileStore } from '../../stores/view-profile-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  viewProfileStep: () => void;
  leaderboardStore?: LeaderboardStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  viewProfileStore?: ViewProfileStore;
}

interface State {
    clicked: boolean;
    valid: any; // change this
    page: number;
}

@inject('leaderboardStore', 'contributeStore', 'loginStore', 'viewProfileStore')
@observer
export class Leaderboard extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
        valid: {},
        clicked: false,
        page: 0,
    };
  }

    componentDidMount() {
        this.scrollToTop();
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

  getCurrentYear = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  }



  render() {
    if (!this.props.leaderboardStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const leaderboardStore = this.props.leaderboardStore;
    const viewProfileStore = this.props.viewProfileStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Leaderboards</u></h4>
          </Col>
        </Row>
        <h5>{leaderboardStore.isLoading ? 'Loading...' : ''}</h5>


          <Card className='post-option-active'>
              <CardBody className='top-buttons'>
                  <Row className="row-justified-and-centered navigation-toolbar-step">
                      <Col>
                          <div id='navigation_buttons'>
                              <Button className="" color="" onClick={async () => {
                                  leaderboardStore.page = 0;
                                  this.setState({page: 0});
                              }}>
                                  <Icon id="crown" text="Global leaderboard"></Icon>
                              </Button>

                              <Button className="" color="" onClick={async () => {
                                  leaderboardStore.page = 1;
                                  this.setState({page: 1});
                              }}>
                                  <Icon id="graph" text="Top 5s for "></Icon>
                                  <span className="feed-button-text"> {this.getCurrentYear()}</span>
                              </Button>
                          </div>
                      </Col>
                  </Row>
              </CardBody>
          </Card>

          {
              leaderboardStore.page == 0 ? (
                      <Card className='post-option-active'>
                          <CardBody>
                              <Row>
                                  <Col>
                                      <div className='leaderboard-scroll'>
                                          <div className='leaderboard'>
                                              <table >
                                                  <tr>
                                                      <th>Rank</th>
                                                      <th>User</th>
                                                      {/*<th title='Suggestions'>Suggs.</th>
                    <th>Votes</th>
                    <th title='Attendance'>Attd.</th>
                    <th title='Referrals'>Refs.</th>*/}
                                                      <th>Points</th>
                                                      {/*<th title='Level'>Lvl.</th>*/}
                                                  </tr>
                                                  <tbody>
                                                  {leaderboardStore.leaderboardResults.users_leaderboard.map((user_data, index) => (
                                                      <tr className='pointing-cursor' onClick={async () => {
                                                          if(user_data.public_id != null) {
                                                              await this.props.viewProfileStore.setProfile(loginStore.miniToken, user_data.public_id);
                                                              this.props.viewProfileStep();
                                                          }
                                                      }}>
                                                          <td className='rank'>{
                                                              user_data.rank
                                                          }</td>
                                                          <td className='username'>{user_data.name} (Level {user_data.level})</td>
                                                          {/*<td>{user_data.suggestion_count}</td>
                      <td>{user_data.vote_count}</td>
                      <td>{user_data.visit_count}</td>
                      <td>{user_data.referral_count}</td>*/}
                                                          <td>{parseInt(user_data.points.toString())}</td>
                                                          {/*<td className='rank'>{user_data.level}</td>*/}
                                                      </tr>
                                                  ))}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </Col>
                              </Row>
                          </CardBody>
                      </Card>
                  ) :
                  leaderboardStore.page == 1 ? (
                      <div>
                          <Card className='post-option-active'>
                              <CardBody>
                                  <Row>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>Lodge Attendance:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.lodges_attendance_numbers.map((lodge_attendance, index) => (
                                                      <li>{lodge_attendance.lodge}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>User Attendance:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.users_attendance_numbers.map((user_attendance, index) => (
                                                      <li>{user_attendance.user}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>Most active:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.users_login_numbers.map((user_login, index) => (
                                                      <li>{user_login.user}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>Suggestions made:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.users_suggestion_numbers.map((user_suggestion, index) => (
                                                      <li>{user_suggestion.user}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>Visitation:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.users_visitation_numbers.map((user_visitation, index) => (
                                                      <li>{user_visitation.user}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>Constitution and Order Attendance:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.constitution_order_attendance_numbers.map((constitution_order, index) => (
                                                      <li>{constitution_order.constitution_order}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col sm='6'>
                                          <div className='small-font-italic'>
                                              <b>Meeting Types:</b>
                                              <br/>
                                              <ol>
                                                  {leaderboardStore.leaderboardResults.meeting_type_attendance_numbers.map((meeting_type, index) => (
                                                      <li>{meeting_type.meeting_type}</li>
                                                  ))}
                                              </ol>
                                          </div>
                                      </Col>
                                      <Col sm='6'>
                                          <span>extra</span>
                                      </Col>
                                  </Row>

                              </CardBody>
                          </Card>
                      </div>

                  ) : ''
          }

      </div>
    );
  }
}

export default Leaderboard;
