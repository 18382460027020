import { observable, action } from 'mobx';
import { AddLodge } from '../domain/add-lodge';
import api from '../api';
import {loginStore} from "./login-store";

export class AddLodgeStore {
  @observable isLoading = false;
  @observable isValid = false;

  @observable lodgeInput: Partial<AddLodge> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    degree: '',
    address: '',
    language: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.lodgeInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.name = (request.name == '')
      ? '* Required'
      : '';

    this.validationResult.number = (request.number == '')
      ? '* Required'
      : '';

    this.validationResult.country = (request.country == '')
      ? '* Required'
      : '';

    this.validationResult.district = (request.district == '')
      ? '* Required'
      : '';

    this.validationResult.constitution = (request.constitution == '')
      ? '* Required'
      : '';

    this.validationResult.degree = (request.degree == '')
      ? '* Required'
      : '';

    this.validationResult.address = (request.address == '')
      ? '* Required'
      : '';

    this.validationResult.language = (request.language == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getSelectedLodge = () => {
    return this.lodgeResults[this.selectedLodgeIndex];
  };

  getAllLodges = async () => {

    //this.validate();
    //if (this.hasValidationError()) {
    //  return;
    //}
/*
    const quoteInCents = this.getApiQuoteInput();
    */
    this.isLoading = true;
    try {
      const result = await api.getAllLodges([], loginStore.authToken);
      console.log('======= getAllLodges =======');
      this.lodgeResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setSelectedLodgeIndex = (index: number) => {
    this.selectedLodgeIndex = index;
    console.log('setting lodge index');
  };

  setLodges = () => {
    this.getAllLodges();
    console.log('getting all lodges');
  };

  clearLodgeInput = () => {
    this.lodgeInput.country = '';
    this.lodgeInput.district = '';
    this.lodgeInput.name = '';
    this.lodgeInput.number = '';
    this.lodgeInput.constitution = '';
    this.lodgeInput.degree = '';
    this.lodgeInput.address = '';
    this.lodgeInput.language = '';
    console.log('clearing lodge input');
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  get selectedLodge () {
    return this.lodgeResults[this.selectedLodgeIndex || 0];
  }
}

export const addLodgeStore = new AddLodgeStore();
(window as any).addLodgeStore = addLodgeStore;
