import moment from 'moment'


export const districtTitleSwitch = (country_id, constitution_id, constitutions) => {
  let title = 'Jurisdiction';

  constitutions.forEach(element => {
    if (element.country == country_id && element.value == constitution_id) {
      title = element.jurisdiction_title;
    }
  });

  return title;
};

export const getUniqueConstitutionsAndOrders = (lodges) => {
  var res = [];
  var temp = [];
  // Iterate through the lodges array to populate the Set
  lodges.forEach((lodge) => {
    if (!temp.includes(lodge.constitution_id + "_" + lodge.degree_id))
      temp.push(lodge.constitution_id + "_" + lodge.degree_id);
  });

  temp.forEach((combo) => {
    let t = combo.split('_')
    res.push({"constitution": t[0], "degree":t[1]});
  });
  return res;
}


export const constructWAShareLink = (share_text) => {
  // mobile
  //<a href={`whatsapp://send?text=${encodeURI(lodge_meeting.lodge_name + ' ' + lodge_meeting.lodge_number)}`}>Share this</a>
  // desktop
  //<a href={`https://web.whatsapp.com/send?l=en&text=${encodeURI(lodge_meeting.lodge_name + ' ' + lodge_meeting.lodge_number)}`} target="_blank">Share this</a>
  //return encodeURI(lodge_name + ' No. ' + lodge_number);
  //return `https://web.whatsapp.com/send?l=en&text=${encodeURI(lodge_name + ' No. ' + lodge_number)}`;
  return `https://wa.me/?text=${encodeURI(share_text)}`;
}

export const checkIfFuture = (the_date) => {
  // Create a new Date object for the given date
  const givenDate = new Date(the_date);
  // Get the current date
  const currentDate = new Date();
  // Compare givenDate and currentDate
  const isFuture = (
    givenDate > currentDate
  );
  return (isFuture) ? 'future' : 'past';
}

export const checkIfMeetingNotLogged = (the_date, lodge_id, visits) => {
  return !visits.some(visit => {
      return visit.datetime.includes(the_date) && visit.lodge_id === lodge_id;
  });
};

export const degreeFromId = (id) => {
  let degree_string = '';
  if (typeof id != "undefined") {
    switch (id.toString()) {
      case '1':
        degree_string = 'Craft Lodges';
        break;
      case '2':
        degree_string = 'Mark Lodges';
        break;
      case '3':
        degree_string = 'Royal Arch Chapters';
        break;
      case '4':
        degree_string = 'Royal Ark Mariner Lodges';
        break;
      case '5':
        degree_string = 'Royal and Select Masters Councils';
        break;
      case '6':
        degree_string = 'Secret Monitor Conclaves';
        break;
      case '7':
        degree_string = 'Knights Templar Preceptories';
        break;
      case '8':
        degree_string = 'Holy Royal Arch Knights Templar Priests Tabernacles';
        break;
      case '9':
        degree_string = 'Red Cross of Constantine Conclaves';
        break;
      case '10':
        degree_string = 'Ancient and Accepted Rite Chapters';
        break;
      default:
        degree_string = '';
    }
  }

  return degree_string;
};

export const degreeFromIdNoCollective = (id) => {
  let degree_string = '';
  switch (id.toString()) {
    case '1':
      degree_string = 'Craft';
      break;
    case '2':
      degree_string = 'Mark';
      break;
    case '3':
      degree_string = 'Royal Arch';
      break;
    case '4':
      degree_string = 'Royal Ark Mariner';
      break;
    case '5':
      degree_string = 'Royal and Select Masters';
      break;
    case '6':
      degree_string = 'Secret Monitor';
      break;
    case '7':
      degree_string = 'Knights Templar';
      break;
    case '8':
      degree_string = 'Holy Royal Arch Knights Templar Priests';
      break;
    case '9':
      degree_string = 'Red Cross of Constantine';
      break;
    case '10':
      degree_string = 'Ancient and Accepted Rite';
      break;
    default:
      degree_string = '';
  }

  return degree_string;
};

export const degreeLogoFromId = (id) => {
  let degree_string = '';
  switch (id.toString()) {
    case '1':
      degree_string = '/orders/small/craft.png';
      break;
    case '2':
      degree_string = '/orders/small/mark.png';
      break;
    case '3':
      degree_string = '/orders/small/ra.png';
      break;
    case '4':
      degree_string = '/orders/small/ram.png';
      break;
    case '5':
      degree_string = '/orders/small/cryptic.png';
      break;
    case '6':
      degree_string = '/orders/small/osm.png';
      break;
    case '7':
      degree_string = '/orders/small/kt.png';
      break;
    case '8':
      degree_string = '/orders/small/ktp.png';
      break;
    case '9':
      degree_string = '/orders/small/rcc.png';
      break;
    case '10':
      degree_string = '/orders/small/rx.png';
      break;
    default:
      degree_string = '';
  }

  return degree_string;
};

export const orderLogoFromConstitutionAndOrder = (constitution_id, order_id) => {
  let image_string = '/orders/small/';

  switch (parseInt(constitution_id)) {
    case 1:
      image_string += 'ec/';
      break;
    case 2:
      image_string += 'ic/';
      break;
    case 3:
      image_string += 'glsa/';
      break;
    case 4:
      image_string += 'sc/';
      break;
    case 5:
      image_string += 'nc/';
      break;
    case 6:
      image_string += 'ldh/';
      break;
    case 7:
      image_string += 'owf/';
      break;
    case 8:
      image_string += 'grc/';
      break;
    case 14:
      image_string += 'gl_sc_afm/';
      break;
    case 18:
      image_string += 'gl_nc_afam/';
      break;
    case 19:
      image_string += 'gl_fl_fam/';
      break;
    default:
      image_string += '';
  }

  switch (parseInt(order_id)) {
    case 1:
      image_string += 'craft.png';
      break;
    case 2:
      image_string += 'mark.png';
      break;
    case 3:
      image_string += 'ra.png';
      break;
    case 4:
      image_string += 'ram.png';
      break;
    case 5:
      image_string += 'cryptic.png';
      break;
    case 6:
      image_string += 'osm.png';
      break;
    case 7:
      image_string += 'kt.png';
      break;
    case 8:
      image_string += 'ktp.png';
      break;
    case 9:
      image_string += 'rcc.png';
      break;
    case 10:
      image_string += 'rx.png';
      break;
    default:
      image_string += '';
  }

  return image_string;
};

export const meetingStringToDate = (pos,day,mon,type) => {
  // Define the desired occurrence and day of the week
  const occurrence = pos; //3; // The third occurrence
  const dayOfWeek = day; //4; // 1 = Monday, ..., 7 = Sunday

  // Get the current year and month
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = mon-1;//currentDate.getMonth();

  // Create a new date for the first day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  // Find the day of the week for the first day of the month
  const firstDayOfWeek = firstDayOfMonth.getDay() || 7;

  // Calculate the number of days to reach the desired occurrence of the day of the week
  let daysToAdd = dayOfWeek - firstDayOfWeek;
  if (daysToAdd < 0) {
    daysToAdd += 7;
  }

  // Adjust the days to match the desired occurrence
  daysToAdd += (occurrence - 1) * 7;

  // Create the date for the desired occurrence of the day in the month
  const desiredDate = new Date(currentYear, currentMonth, 1 + daysToAdd);

  const formattedDate = desiredDate.getFullYear() + '-' +
                       ('0' + (desiredDate.getMonth() + 1)).slice(-2) + '-' +
                       ('0' + desiredDate.getDate()).slice(-2);

  let type_string = '';
  switch (type.toString()) {
    case '1':
      type_string = 'Meeting';
      break;
    case '2':
      type_string = 'Installation';
      break;
    case '3':
      type_string = 'Social';
      break;
    default:
      type_string = '';
  }

  return formattedDate + ' - ' + type_string;
};

export const meetingString = (meetings) => {
  let long_string = '';
  let installation_string = '';
  const hashmap = {};

  meetings.map((meeting, index) => {
    let pos_string = '';
    let day_string = '';
    let mon_string = '';

    switch (meeting.position.toString()) {
      case '1':
        pos_string = '1st';
        break;
      case '2':
        pos_string = '2nd';
        break;
      case '3':
        pos_string = '3rd';
        break;
      case '4':
        pos_string = '4th';
        break;
      case '5':
        pos_string = '5th';
        break;
      default:
        pos_string = '';
    }

    switch (meeting.day.toString()) {
      case '1':
        day_string = 'Monday';
        break;
      case '2':
        day_string = 'Tuesday';
        break;
      case '3':
        day_string = 'Wednesday';
        break;
      case '4':
        day_string = 'Thursday';
        break;
      case '5':
        day_string = 'Friday';
        break;
      case '6':
        day_string = 'Saturday';
        break;
      case '7':
        day_string = 'Sunday';
        break;
      default:
        day_string = '';
    }

    switch (meeting.month.toString()) {
      case '1':
        mon_string = 'January';
        break;
      case '2':
        mon_string = 'February';
        break;
      case '3':
        mon_string = 'March';
        break;
      case '4':
        mon_string = 'April';
        break;
      case '5':
        mon_string = 'May';
        break;
      case '6':
        mon_string = 'June';
        break;
      case '7':
        mon_string = 'July';
        break;
      case '8':
        mon_string = 'August';
        break;
      case '9':
        mon_string = 'September';
        break;
      case '10':
        mon_string = 'October';
        break;
      case '11':
        mon_string = 'November';
        break;
      case '12':
        mon_string = 'December';
        break;
      default:
        mon_string = '';
    }

    // if the meeting of type type meeting
    if (meeting.type.toString() == '1') {
      if (meeting.time + ' ' + pos_string + ' ' + day_string in hashmap) {
        hashmap[meeting.time + ' ' + pos_string + ' ' + day_string] += mon_string + ', ';
      }
      else {
        hashmap[meeting.time + ' ' + pos_string + ' ' + day_string] = mon_string + ', ';
      }
    }
    else if (meeting.type.toString() == '2'){
      installation_string = meeting.time + ' ' + pos_string + ' ' + day_string + ' ' + mon_string + ' (Installation)';
    }
    else {

    }
  });

  for (const key in hashmap) {
    if (hashmap.hasOwnProperty(key)) {
      long_string += key + ' of ' + hashmap[key];
    }
  }

  return long_string + ';' + installation_string;
};

export const meetingStringToDateWithDescription = (pos,day,mon,type,description, description_extra, configs) => {
  // Define the desired occurrence and day of the week
  const occurrence = pos; //3; // The third occurrence
  const dayOfWeek = day; //4; // 1 = Monday, ..., 7 = Sunday

  // Get the current year and month
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = mon-1;//currentDate.getMonth();

  // Create a new date for the first day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  // Find the day of the week for the first day of the month
  const firstDayOfWeek = firstDayOfMonth.getDay() || 7;

  // Calculate the number of days to reach the desired occurrence of the day of the week
  let daysToAdd = dayOfWeek - firstDayOfWeek;
  if (daysToAdd < 0) {
    daysToAdd += 7;
  }

  // Adjust the days to match the desired occurrence
  daysToAdd += (occurrence - 1) * 7;

  // Create the date for the desired occurrence of the day in the month
  const desiredDate = new Date(currentYear, currentMonth, 1 + daysToAdd);

  const formattedDate = desiredDate.getFullYear() + '-' +
                       ('0' + (desiredDate.getMonth() + 1)).slice(-2) + '-' +
                       ('0' + desiredDate.getDate()).slice(-2);

  let type_string = '';
  switch (type.toString()) {
    case '1':
      type_string = 'Meeting';
      break;
    case '2':
      type_string = 'Installation';
      break;
    //case '3':
    //  type_string = 'Social';
    //  break;
    default:
      type_string = '';
  }

  let description_string = getDescriptionFromConfigsById(description, configs);
  /*switch (description.toString()) {
    case '0':
      description_string = 'No Working';
      break;
    case '1':
      description_string = '1st°/Initiation';
      break;
    case '2':
      description_string = '2nd°/Passing';
      break;
    case '3':
      description_string = '3rd°/Raising';
      break;
    case '4':
      description_string = 'Exaltation';
      break;
    case '5':
      description_string = 'Advancement';
      break;
    case '6':
      description_string = 'Demo 1st°/Initiation';
      break;
    case '7':
      description_string = 'Demo 2nd°/Passing';
      break;
    case '8':
      description_string = 'Demo 3rd°/Raising';
      break;
    case '9':
      description_string = 'Lecture';
      break;
    case '10':
      description_string = 'Elevation';
      break;
    default:
      description_string = '';
  }*/

  if (type.toString() == '1') {
    if (description.toString() == '9') {
      return formattedDate + ' - ' + type_string + ' (' + description_string + ' - ' + description_extra + ')';
    }
    return formattedDate + ' - ' + type_string + ' (' + description_string + ')';
  }
  else {
    return formattedDate + ' - ' + type_string;
  }
};

export const socialString = (meetings) => {
  let long_string = '';
  let installation_string = '';
  const hashmap = {};

  if (meetings.length <= 0) {
    return '';
  }
  else {
    meetings.map((meeting, index) => {
      let pos_string = '';
      let day_string = '';
      let mon_string = '';

      switch (meeting.position.toString()) {
        case '1':
          pos_string = '1st';
          break;
        case '2':
          pos_string = '2nd';
          break;
        case '3':
          pos_string = '3rd';
          break;
        case '4':
          pos_string = '4th';
          break;
        case '5':
          pos_string = '5th';
          break;
        default:
          pos_string = '';
      }

      switch (meeting.day.toString()) {
        case '1':
          day_string = 'Monday';
          break;
        case '2':
          day_string = 'Tuesday';
          break;
        case '3':
          day_string = 'Wednesday';
          break;
        case '4':
          day_string = 'Thursday';
          break;
        case '5':
          day_string = 'Friday';
          break;
        case '6':
          day_string = 'Saturday';
          break;
        case '7':
          day_string = 'Sunday';
          break;
        default:
          day_string = '';
      }

      switch (meeting.month.toString()) {
        case '1':
          mon_string = 'January';
          break;
        case '2':
          mon_string = 'February';
          break;
        case '3':
          mon_string = 'March';
          break;
        case '4':
          mon_string = 'April';
          break;
        case '5':
          mon_string = 'May';
          break;
        case '6':
          mon_string = 'June';
          break;
        case '7':
          mon_string = 'July';
          break;
        case '8':
          mon_string = 'August';
          break;
        case '9':
          mon_string = 'September';
          break;
        case '10':
          mon_string = 'October';
          break;
        case '11':
          mon_string = 'November';
          break;
        case '12':
          mon_string = 'December';
          break;
        default:
          mon_string = '';
      }

      // if the meeting of type type social
      if (meeting.type.toString() == '3') {
        if (meeting.time + ' ' + pos_string + ' ' + day_string in hashmap) {
          hashmap[meeting.time + ' ' + pos_string + ' ' + day_string] += mon_string + ', ';
        }
        else {
          hashmap[meeting.time + ' ' + pos_string + ' ' + day_string] = mon_string + ', ';
        }
      }
      //else if (meeting.type.toString() == '2'){
      //  installation_string = meeting.time + ' ' + pos_string + ' ' + day_string + ' ' + mon_string + ' (Installation)';
      //}
      else {

      }
    });

    for (const key in hashmap) {
      if (hashmap.hasOwnProperty(key)) {
        long_string += key + ' of ' + hashmap[key];
      }
    }

    return long_string;
  }
};

export const getAddressFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const address = configs[i];
    if (address.value.toString() == id.toString()) {
      return address.label;
    }
  }
}

export const getDescriptionFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const description = configs[i];
    if (description.value.toString() == id.toString()) {
      return description.label;
    }
  }
}

export const getConstitutionFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const constitution = configs[i];
    if (constitution.value.toString().split('_', 1)[0] == id.toString()) {
      return constitution.label;
    }
  }
}

export const getShortConstitutionFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const constitution = configs[i];
    if (constitution.value.toString().split('_', 1)[0] == id.toString()) {
      return constitution.short;
    }
  }
}

export const getConstitutionWebsiteFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const constitution = configs[i];
    if (constitution.value.toString().split('_', 1)[0] == id.toString()) {
      return constitution.website;
    }
  }
}

export const getDistrictFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const district = configs[i];
    if (district.value.toString().split('_', 1)[0] == id.toString()) {
      return district.label;
    }
  }
}

export const getCountryFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const country = configs[i];
    if (country.value.toString() == id.toString()) {
      return country.label;
    }
  }
}

export const getMeetingTypeFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const meeting_type = configs[i];
    if (meeting_type.value.toString() == id.toString()) {
      return meeting_type.label;
    }
  }
}

export const getLanguageFromConfigsById = (id, configs) => {
  for (let i = 0; i < configs.length; i++) {
    const language = configs[i];
    if (language.value.toString() == id.toString()) {
      return language.label;
    }
  }
}


export const isLocalhost= () => {
  return Boolean(
      window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
}


export const getDescription = (id) => {
  document.getElementById('meeting_description_cut_'+id).style.display = 'none';
  document.getElementById('meeting_description_full_'+id).style.display = 'inherit';
  //document.getElementById('meeting_image_'+id).classList.remove("post-restricted-height");
}

export const reDir = (website) => {
  window.open(website, '_blank');
}

export const addOneYearAndSetToBeginningOfYear = (date) => {
  // Clone the date to avoid mutating the original
  let newDate = new Date(date);

  // Add 1 year
  newDate.setFullYear(newDate.getFullYear() + 1);

  // Set the date to January 1st
  newDate.setMonth(0); // January is month 0
  newDate.setDate(1); // First day of the month
  newDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

  //return newDate;
  //return newDate.toLocaleString().split(' ')[0].replaceAll('/', '-');
  // Return the date in a specific format (e.g., YYYY-MM-DD)
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Adding 1 as months are 0-indexed
  const day = String(newDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}