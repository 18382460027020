import { observable, action } from 'mobx';
import { AddMeeting } from '../domain/add-meeting';
import api from '../api';

export class AddMeetingStore {
  @observable isLoading = false;

  @observable lodgeInput: Partial<AddMeeting> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    degree: '',
    meetings: [{
      time: '',
      position: '',
      day: '',
      month: '',
      type: ''
    }]
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = { };
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.lodgeInput;
    this.validationResult = { };
    this.fieldsToFill = [];

    let meetings_cnt = 0;
    let installation_cnt = 0;
    request.meetings.forEach((meeting, index) => {
        meetings_cnt+=1;
        if (meeting.type === '2') {
          installation_cnt+=1;
        }
        const meetingKey = `meeting_${index + 1}`;
        this.validationResult[`${meetingKey}.type`] = (meeting.type === '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.time`] = (meeting.time === '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.position`] = (meeting.position === '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.day`] = (meeting.day === '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.month`] = (meeting.month === '') ? '* Required' : '';
      });

    if (meetings_cnt < 2 || (installation_cnt > 1 || installation_cnt == 0)) {
      this.validationResult['enough_meetings'] = '* Minimum two meetings, one of which must be an Installation!'
    }

    //this.validationResult.meetings[0].type = '* Required';
    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getSelectedLodge = () => {
    return this.lodgeResults[this.selectedLodgeIndex];
  };

  @action
  addMeeting = () => {
      this.lodgeInput.meetings.push({
        time: '',
        position: '',
        day: '',
        month: '',
        type: ''
      });
  };

  @action
  removeMeeting = (indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < this.lodgeInput.meetings.length) {
      this.lodgeInput.meetings.splice(indexToRemove, 1);
    }
  };

  getAllLodges = async () => {
    this.isLoading = true;
    try {
      const result = await api.getAllLodges([]);
      console.log('======= getAllLodges =======');
      this.lodgeResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setSelectedLodgeIndex = (index: number) => {
    this.selectedLodgeIndex = index;
    console.log('setting lodge index');
  };

  setLodges = () => {
    this.getAllLodges();
    console.log('getting all lodges');
  };

  clearMeetingInput = () => {
    this.lodgeInput.country = '';
    this.lodgeInput.district = '';
    this.lodgeInput.name = '';
    this.lodgeInput.number = '';
    this.lodgeInput.constitution = '';
    this.lodgeInput.degree = '';
    this.lodgeInput.meetings = [];
    this.lodgeInput.meetings.push({
      time: '',
      position: '',
      day: '',
      month: '',
      type: ''
    });
    console.log('clearing meeting input');
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  get selectedLodge () {
    return this.lodgeResults[this.selectedLodgeIndex || 0];
  }
}

export const addMeetingStore = new AddMeetingStore();
(window as any).addMeetingStore = addMeetingStore;
