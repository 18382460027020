// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { EmergencyMeetingStore } from '../../stores/emergency-meeting-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { TimeInput } from '../inputs/time';
import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  emergencyMeetingStore?: EmergencyMeetingStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
    clicked: boolean;
    valid: any; // change this
    date: string;
    time: string;
    description: string;
    address: string;
}

@inject('emergencyMeetingStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore', 'configurationStore')
@observer
export class EmergencyMeeting extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
        valid: {},
        clicked: false,
        date: '',
        time: '',
        description: '',
        address: ''
    };
  }

  render() {
    if (!this.props.emergencyMeetingStore) {
      return;
    }

    const membershipClaimStore = this.props.emergencyMeetingStore;
    //console.log("------- membershipClaimStore.lodgeResults -------");
    //console.log(membershipClaimStore.lodgeResults);
    //console.log("---------------------------------------");

    const membershipClaimInput = membershipClaimStore.membershipClaimInput;
    const validationResult = membershipClaimStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'message';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Emergency Meeting</u></h4>
          </Col>
        </Row>

          <Card className='post-option-active'>
              <CardBody>
                <Row>
                  <Col sm="3">
                  <DateInput
                      label="Date of Meeting*"
                      invalid={!!validationResult.date}
                      validationMessage={validationResult.date}
                      value={this.state.date}
                      onChange={e => {
                        membershipClaimInput.date = e.target.value;
                        this.setState({date: e.target.value});
                      }}
                      id="date"
                    />
                  </Col>
                  <Col sm="3">
                    <TimeInput
                        label="Time of Meeting*"
                        invalid={!!validationResult.time}
                        validationMessage={validationResult.time}
                        value={this.state.time}
                        onChange={e => {
                          membershipClaimInput.time = e.target.value;
                          this.setState({time: e.target.value});
                        }}
                        id="time"
                      />
                  </Col>
                  <Col sm="6">
                    <SelectInput
                      id='description'
                      label='Description'
                      invalid={!!validationResult['description']}
                      validationMessage={validationResult['description']}
                      value={this.state.description}
                      onChange={e => {
                        membershipClaimStore.membershipClaimInput.description = e.target.value;
                        this.setState({description: e.target.value});
                      }}
                      options={configurationStore.filteredDescriptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SearchableSelectInput
                      id='address'
                      label='Address'
                      invalid={!!validationResult.address}
                      validationMessage={validationResult.address}
                      value={this.state.address}
                      onChange={(selectedOption) => {
                        if (selectedOption != null) {
                          membershipClaimStore.membershipClaimInput.address = selectedOption.value;
                          this.setState({address: selectedOption.value});
                        }
                      }}
                      options={configurationStore.filteredAddresses}
                    />
                  </Col>
                </Row>


                <br/>
                <Button color="" onClick={async () => {
                  //toast.info("TEST!");

                  membershipClaimStore.validate();
                  const success = membershipClaimStore.checkValidation();
                  console.log('----');
                  console.log(success);
                  console.log('----');
                  if (success) {
                    if (await membershipClaimStore.addEmergencyMeeting(
                          lodgeDetailsStore.lodgeInfo[0].id,
                          membershipClaimInput.date,
                          membershipClaimInput.time,
                          membershipClaimInput.description,
                          membershipClaimInput.address,
                          loginStore
                        )) {
                        toast.success("The emergency meeting has been proposed!");
                        this.props.thankYouStep();
                    }
                    else {
                      toast.warning("Could not log the emergency meeting!");
                    }
                  }
                }}>
                    <Icon id="send" text="Submit"></Icon>
                </Button>
                <Button color="" onClick={this.props.lodgeDetailsStep}>
                    <Icon id="back" text="Back"></Icon>
                </Button>
            </CardBody>
        </Card>

      </div>
    );
  }
}

export default EmergencyMeeting;
