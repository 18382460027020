import * as React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Alert,
    Card,
    CardBody,
} from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { ViewProfileStore } from '../../stores/view-profile-store';
import { ProfileStore } from '../../stores/profile-store';
import { LoginStore } from '../../stores/login-store';

import { TextInput, TextArea } from '../inputs/text';
import {Icon} from "../svg_icons/icon";
import {toast} from "react-toastify";

interface Props {
  viewProfileStore?: ViewProfileStore;
  profileStore?: ProfileStore;
  loginStore?: LoginStore;
}

export const UserPostCommentModal: React.FC<Props> = inject('viewProfileStore', 'profileStore', 'loginStore')(
  observer((props: Props) => {
    if (!props.viewProfileStore) {
      return null;
    }

    const userPostCommentModalInput = props.viewProfileStore.userPostCommentModalInput;
    const modalValidationResult = props.viewProfileStore.modalValidationResult;

    const viewProfileStore = props.viewProfileStore;
    const profileStore = props.profileStore;
    const loginStore = props.loginStore;


    const validateFileType = (event) => {
       var selectedFile = event.target.files[0]; //document.getElementById('fileInput').files[0];
       var allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'audio/mpeg', 'video/mp4'];

       if (!allowedTypes.includes(selectedFile.type)) {
          alert('Invalid file type. Please upload a JPEG, PNG, MP3, MP4, or PDF file.');
          //document.getElementById('fileInput').value = '';
          return false;
       } else {
         return true;
       }
    }

    const handleFileChange = (event) => {
        if (validateFileType(event))
        {
          let base64 = null;
          //console.log('! handleFileChange');
          const file = event.target.files[0];
          // Process the file
          console.log(file);

          // Create FormData object
          const formData = new FormData();
          formData.append('file', file);

          console.log(formData);
          console.log(formData);
          props.viewProfileStore.userPostCommentModalInput.image_file_name = file.name;
          console.log("userPostCommentModalInput.image_file_name:", props.viewProfileStore.userPostCommentModalInput.image_file_name);

          // Read the file data
          const reader = new FileReader();
          reader.onload = function(event) {
            const fileData = event.target.result as string;
            //console.log("File data:", fileData);
            // Encode file data as base64
            base64 = btoa(fileData);
            //console.log("Base64 encoded:", base64);
            props.viewProfileStore.userPostCommentModalInput.image_file_data = base64;
            //console.log("userPostModalInput.image_data:", props.profileStore.userPostModalInput.image_data);
          };

          // Assuming 'file' is a reference to the File object
          //reader.readAsText(file);
          //reader.readAsArrayBuffer(file);
          reader.readAsBinaryString(file);
        }
        else {
          props.viewProfileStore.userPostCommentModalInput.image_file_name = "";
          viewProfileStore.toggleUserPostCommentModal();
        }
    };

    const getDescription = (id) => {
        console.log('see more clicked');
      document.getElementById('meeting_description_cut').style.display = 'none';
      document.getElementById('meeting_description_full').style.display = 'inherit';
      //document.getElementById('meeting_image_0').classList.remove("post-restricted-height");
        console.log('seen more ???');
    }

    return (
      <Modal
        className="shrink"
        isOpen={props.viewProfileStore.userPostCommentModalOpen}
      >
        <ModalHeader
          toggle={() => props.viewProfileStore.toggleUserPostCommentModal()}
        >
            {viewProfileStore.postData.title}'s Post
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
              { viewProfileStore.postData && viewProfileStore.postData.description ?
            <Row>
                <Col>
                    <Card className="post-option-active">
                        <CardBody className="card-padding-override">
                            <Row>
                                <Col className="col-sm-1 post_header_logo">
                                    <img
                                        src='logo.png'
                                        alt='logo'
                                        height='40px'
                                        onClick={() => {
                                            //this.props.profileStep();
                                        }}
                                    />
                                </Col>
                                <Col className="col-sm-11 post_header_title pointing-cursor">
                                <span
                                    onClick={() => {
                                        //this.props.profileStep();
                                    }}
                                >
                                  {/*loginStore.username*/}
                                    {viewProfileStore.postData.title}
                                </span>
                                    <br/>
                                    <Icon id="clock"></Icon>
                                    <span className='x-small-font'> {viewProfileStore.postData.date_time} </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        viewProfileStore.postData.description.length > 100
                                            ? <span id='meeting_description_cut'
                                                    className='show_post_description pre pre-code'>{viewProfileStore.postData.description.substring(0, 200) + "..."}
                                                <Button className="small-font" color="#007bff" onClick={() => {
                                                    console.log('see more clicking');
                                                    getDescription(viewProfileStore.postDataIndex);
                                                }}>
                                                  see more
                                                </Button>
                                                </span>
                                            : ''
                                    }
                                    <span id='meeting_description_full' className={`${
                                        viewProfileStore.postData.description.length < 100
                                            ? 'show_post_description pre pre-code'
                                            : 'hide_post_description pre pre-code'
                                    }`}>
                                      {viewProfileStore.postData.description}
                                    </span>
                                </Col>
                            </Row>
                            {viewProfileStore.postData.image !== '' ? (
                                <Row>
                                    <Col sm="12" className="post-restricted-height">
                                        {viewProfileStore.postData.image.includes('.pdf') ? (
                                                <embed
                                                    src={process.env.REACT_APP_API_URL + viewProfileStore.postData.image + "#toolbar=0"}
                                                    type="application/pdf" className="width-height-hundred"/>
                                            ) :
                                            viewProfileStore.postData.image.includes('.mp3') ? (
                                                    <audio controls>
                                                        <source
                                                            src={process.env.REACT_APP_API_URL + viewProfileStore.postData.image}
                                                            type="audio/mp3"/>
                                                        Your browser does not support the audio tag.
                                                    </audio>
                                                ) :
                                                viewProfileStore.postData.image.includes('.mp4') ? (
                                                        <video controls className="video-container">
                                                            <source
                                                                src={process.env.REACT_APP_API_URL + viewProfileStore.postData.image}
                                                                type="video/mp4"/>
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    ) :
                                                    (
                                                        <img className="feed-wall-image"
                                                             src={process.env.REACT_APP_API_URL + viewProfileStore.postData.image}/>
                                                    )}
                                    </Col>
                                </Row>
                            ) : (
                                ''
                            )}
                            <hr/>
                            <Row className="row-justified-and-centered small-font">
                                <Col>
                                    <Button className="small-font" color=""
                                            onClick={async () => {
                                                //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                                //this.props.contactStep();
                                                let res = '';//await viewProfileStore.likePost(loginStore.miniToken, viewProfileStore.postData.public_id);
                                                if (res) {
                                                    toast.success("You have liked this post!");

                                                    viewProfileStore.postData.liked = !viewProfileStore.postData.liked;

                                                    // alternative to this.forceUpdate solution
                                                    //this.state.postsLiked[viewProfileStore.postDataIndex] = !this.state.postsLiked[viewProfileStore.postDataIndex];
                                                    //this.setState({postsLiked: this.state.postsLiked});

                                                    // NB!!! not what I want to do here
                                                    //this.forceUpdate();
                                                    //viewProfileStore.setLiked(index);
                                                } else {
                                                    toast.warning("You are unable to like this post!");
                                                }
                                            }}>

                                        {viewProfileStore.postData.liked ?
                                            <Icon id="like" accent_color="#2859C5" base_color="#8FBFFA"
                                                  text="Liked"></Icon>
                                            :
                                            <Icon id="like" text="Like"></Icon>
                                        }
                                        {viewProfileStore.postData.likes_count > 0 ?
                                            <span> ({viewProfileStore.postData.likes_count})</span>
                                            :
                                            ''}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className="small-font" color=""
                                            onClick={async () => {
                                                //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                                //this.props.contactStep();
                                                //viewProfileStore.toggleUserPostCommentModal();
                                            }}>
                                        <Icon id="comment" text="Comment"></Icon>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className="small-font" color=""
                                            onClick={async () => {
                                                //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                                //this.props.contactStep();
                                            }}>
                                        <Icon id="share" text="Share"></Icon>
                                    </Button>
                                </Col>
                            </Row>


                            <hr/>
                            { viewProfileStore.postData.comments.length > 0 ?
                                <div>

                                    {/* comments sections - show the only one comment, the last/latest one */}
                                    {viewProfileStore.postData.comments.map((comment, index) => (
                                            <div>
                                                <Row>
                                                    <Col className="col-sm-1 post_header_logo">
                                                        <img
                                                            className="user-post-comment-image"
                                                            src='logo.png'
                                                            alt='logo'
                                                            height='20px'
                                                            onClick={() => {
                                                                //this.props.profileStep();
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <div className="user-post-comment">
                                                            <span className="x-small-font"><b>{comment.name}</b></span>
                                                            <br/>
                                                            <span className="small-font">{comment.comment}</span>
                                                        </div>
                                                        <br/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    )}
                                </div>
                                : ''
                            }


                        </CardBody>
                    </Card>
                </Col>
            </Row>
                  : ''
              }
              <Col>
                  <img src="logo.png" alt="logo" height="30px" onClick={() => {
                  }}/> {loginStore.username}
              </Col>
              <Col sm="12">
                  <div>
                      <TextArea
                          id="input"
                          label=""
                          placeholder={"Comment as " + loginStore.username.substring(0, loginStore.username.indexOf(' '))}
                          value={userPostCommentModalInput.feed_input}
                          onChange={e =>
                              (userPostCommentModalInput.feed_input = e.target.value)
                          }
                          invalid={!!modalValidationResult.feed_input}
                          validationMessage={modalValidationResult.feed_input}
                      />
                  </div>
              </Col>
              {/*<Col>
              <input type="file" onChange={handleFileChange} />
            </Col>*/}
              <Col sm="12">
                  {props.viewProfileStore.userPostCommentModalSuccess && (
                      <Alert color="success">
                          Thank you for posting. Your post might take up to an hour to verify.
                </Alert>
              )}
              {props.viewProfileStore.userPostCommentModalError && (
                <Alert color="warning">
                  Something went wrong, please try again. If this persists please contact <a href="mailto:info@freemason-alpha.com">info@freemason-alpha.com</a>
                </Alert>
              )}
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color=""
            style={{
              width: '100%'
            }}
            onClick={async () => {
              let res = await viewProfileStore.commentOnUserPost(loginStore.miniToken, viewProfileStore.postData.public_id, userPostCommentModalInput.feed_input);
              console.log("res");
              console.log(res);
              console.log("resser");
              if (res) {
                //profileStore.toggleUserPostModal();
                userPostCommentModalInput.feed_input = '';
                userPostCommentModalInput.image_file_name = '';
                userPostCommentModalInput.image_file_data = '';
              }
            }}
          >
              <Icon id='send' text="Comment"></Icon>
          </Button>
        </ModalFooter>
      </Modal>
    );
  })
);
