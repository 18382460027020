// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeLogoFromId,
  orderLogoFromConstitutionAndOrder,
  checkIfFuture,
  checkIfMeetingNotLogged,
  constructWAShareLink
} from '../../helpers/utilities';
import { ToastContainer, toast } from 'react-toastify';
import Tour from 'reactour';

import { PromotePostStore } from '../../stores/promote-post-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LoginStore } from '../../stores/login-store';

import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  userAccountStep: () => void;
  feedWallStep: () => void;
  promotePostStore?: PromotePostStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  isTourOpen: boolean;
}

@inject('promotePostStore', 'contributeStore', 'loginStore',)
@observer
export class PromotePost extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      isTourOpen: false
    };
    this.setTourIsClosed = this.setTourIsClosed.bind(this);

    this.scrollToTop();
  }

  /*componentDidMount() {
    console.log('mounted did it?');
    this.scrollToTop();
  }*/

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setTourIsOpen() {
    this.setState({ isTourOpen: true });
  }
  setTourIsClosed() {
    this.setState({ isTourOpen: false });
  }

  render() {
    if (!this.props.promotePostStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const promotePostStore = this.props.promotePostStore;

    const today = new Date();
    const addOneDay = new Date(today.setDate(today.getDate() + 2));

    const steps = [
      {
        selector: '.basic-step',
        content: 'Check that you have selected the correct post. Ensure that the contact details are correct.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.cost-to-promote-step',
        content: 'Promoting the post will cost you 1 credit.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.current-balance-step',
        content: 'Your current account balance. You can click here to top up your account.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.promoting-ends-at-step',
        content: 'This post will be promoted for at least 24 hours. Appearing on the top of the feed.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.promote-step',
        content: 'If you are happy with the details, then you can click to promote this post.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Promote Post</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody className='top-buttons'>
            <Row className='row-justified-and-centered'>
              <Col>
                <div id='navigation_buttons'>

                  <Button
                      className=''
                      color=''
                      onClick={async () => {
                        this.props.feedWallStep();
                      }}
                  >
                    <Icon id="back" text="Back"></Icon>
                  </Button>

                  <Button className="" color="" onClick={async () => {
                    this.setTourIsOpen();
                  }}>
                    <Icon id="help" text="Help"></Icon>
                  </Button>

                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
        <Col sm="6">
          <Card className={`post-option-active ${
            promotePostStore.postData.sponsored
              ? 'sponsor-ad'
              : ''
            }`}>
          <CardBody className="card-padding-override">
          <Row>
            <Col className="post_header_logo">
              <img alt='logo' src={`${promotePostStore.postData.lodge_constitution != '' ? orderLogoFromConstitutionAndOrder(promotePostStore.postData.lodge_constitution, promotePostStore.postData.lodge_degree) : "logo.png"}`} height="40px"/>
            </Col>
            <Col className="post_header_title pointing-cursor">
            <span>
              {promotePostStore.postData.title}
            </span>
            <br/>
              <Icon id="clock"></Icon>
            <span className='x-small-font'> {promotePostStore.postData.date_time} </span>
              <Icon id="globe"></Icon>
            <span className='x-small-font'>
            <a href={`http://maps.google.com/maps?q=${
              promotePostStore.postData.gps !== ''
                ? promotePostStore.postData.gps
                : ''
              }`} target="_blank">
              {promotePostStore.postData.address}
            </a>
            </span>
            <div>
              <span className="small-font-italic">
                {promotePostStore.postData.sponsored ? 'Sponsored' : promotePostStore.postData.promoted ? 'Promoted' : ''}
              </span>
            </div>
            </Col>
          </Row>

          <Row className="small-font basic-step">
            <Col>
            <span>
            {promotePostStore.postData.description}
            {promotePostStore.postData.post_type == 2
              ?
              <div>
                <br/>
                Contact: {promotePostStore.postData.contact_name}
                <br/>
                Tel: {promotePostStore.postData.contact_number}
                <br/>
                Email: {promotePostStore.postData.contact_email}
              </div>
               : ''
             }
            </span>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="post-restricted-height">
            {
              promotePostStore.postData.post_type == 1 || promotePostStore.postData.post_type == 2
              ?
              <img className='feed-wall-image' src={promotePostStore.postData.image}/>
              :
              promotePostStore.postData.image != ''
              ?
                promotePostStore.postData.image.includes('.pdf') ? (
                    <embed src={process.env.REACT_APP_API_URL + promotePostStore.postData.image+"#toolbar=0"} type="application/pdf" className="width-height-hundred"/>
                ) : (
                  <img className="feed-wall-image" src={process.env.REACT_APP_API_URL + promotePostStore.postData.image} />
                )
              : ''
            }
            </Col>
          </Row>
          </CardBody>
          </Card>
        </Col>

        <Col sm="6">
          <Card className='post-option-active'>
          <CardBody>
          <Row>
            <Col sm="12">
              <span className='small-font'>
                <span className="cost-to-promote-step">Cost: 1 Credit</span>
                <br/>
                <span className="current-balance-step">Current balance: <a href="#" onClick={this.props.userAccountStep}>{loginStore.user_credits} Credits</a></span>
                <br/>
                <span className="promoting-ends-at-step">Ends At: {addOneDay.toISOString().split("T")[0]+" 00:00:00"}</span>
              </span>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12" className="promote-button-col">
            <Button className="small-font promote-button promote-step" color="primary"
              onClick={async () => {
                if (window.confirm('Are you sure you want to promote this meeting?')) {
                //if (window.confirm('You are currently unable to promote this post!')) {

                  //promotePostStore.setPostData(lodge_meeting);
                  //this.props.promotePostStep();
                  let res = await promotePostStore.promotePost(loginStore.miniToken);
                  console.log(res);
                  if (res.status) {
                    loginStore.user_credits = res.bal;
                    toast.success(res.message);
                  }
                  else {
                    if(res.error) {
                      toast.error(res.message);
                    }
                    else {
                      toast.warn(res.message);
                    }
                  }
                }
              }}>
                <span className="">Promote</span>
            </Button>
            </Col>
          </Row>
          </CardBody>
          </Card>
        </Col>
        </Row>

        <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.setTourIsClosed}
        showNavigation={false}
        />
      </div>
    );
  }
}

export default PromotePost;
