// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import { RegistrationPreStore } from '../../stores/registration-pre-store';
import { RegistrationStore } from '../../stores/registration-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { PolicyDocsStore } from '../../stores/policy-docs-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  thankYouStep: () => void;
  addLodgeStep: () => void;
  registrationPreStore?: RegistrationPreStore;
  registrationStore?: RegistrationStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
  policyDocsStore?: PolicyDocsStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('registrationStore', 'registrationPreStore', 'thankYouStore', 'contributeStore', 'policyDocsStore')
@observer
export class Registration extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  handleNextClick = async () => {
      const { registrationPreStore, registrationStore } = this.props;
      registrationStore.validate();
      const success = registrationStore.checkValidation();
      console.log('----');
      console.log(success);
      console.log('----');
      if (success) {
        //this.props.nextStep(); // Call nextStep as a function
        if(await registrationStore.registerUser(registrationPreStore, registrationStore)) {
          toast.success("Confirmation Code Sent");
          this.props.thankYouStep();
        }
        else {
          toast.error("Failed to register user!");
        }
      }
    };

  render() {
    if (!this.props.registrationStore) {
      return;
    }

    const registrationStore = this.props.registrationStore;
    //console.log("------- registrationStore.lodgeResults -------");
    //console.log(registrationStore.lodgeResults);
    //console.log("---------------------------------------");

    const registrationInput = registrationStore.registrationInput;
    const validationResult = registrationStore.validationResult;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'registration. A verification email has been sent to your email address.';

    const policyDocsStore = this.props.policyDocsStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Profile Details</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextInput
              id="first_name"
              label="First Name"
              value={registrationInput.first_name}
              onChange={e =>
                (registrationInput.first_name = e.target.value)
              }
              invalid={!!validationResult.first_name}
              validationMessage={validationResult.first_name}
            />
          </Col>
          <Col>
            <TextInput
              id="last_name"
              label="Last Name"
              value={registrationInput.last_name}
              onChange={e =>
                (registrationInput.last_name = e.target.value)
              }
              invalid={!!validationResult.last_name}
              validationMessage={validationResult.last_name}
            />
          </Col>
        </Row>

      <Row>
      <Col>
        <TextInput
          id="email"
          label="Email"
          value={registrationInput.email}
          onChange={e =>
            (registrationInput.email = e.target.value)
          }
          invalid={!!validationResult.email}
          validationMessage={validationResult.email}
        />
      </Col>
      </Row>

        <Row>
          <Col>
            <span>Contact Number</span>
            <PhoneInput
              defaultCountry="za"
              value={registrationInput.contact_number}
              onChange={(phone) => (registrationInput.contact_number = phone)}
            />
          </Col>
        </Row>

        <br/>
        <Row>
          <Col>
            <span className="small-font-italic">
            <p>
              Before we finalize your registration, we need some essential information from you:
              <ol>
                <li><b>Personal Details:</b> Please provide your name, surname, phone number, and email address. This information will help us personalize your experience and keep you updated on important announcements.</li>
                <li><b>Verify Your Email:</b> After submitting your details, a verification link will be sent to the email address you provided. Ensure that you have access to the email account and click on this link to confirm your identity.</li>
                <li><b>Receive Your Password:</b> Once your email is verified, a random password will be sent to your email inbox. You'll use this password to log in initially, but don't worry, you can change it later.</li>
              </ol>
            </p>

            <p>Please ensure that the information you provide is accurate to ensure seamless communication and access to your account.</p>

            <p>Let's get started!</p>
            </span>
          </Col>
        </Row>
        <br/>

        <div className="row-justified-and-centered small-font">
          <FormGroup check inline>
            <Label check>
              <Input
                type='checkbox'
                name='radioPolicyAgree'
                checked={registrationInput.policy_agree}
                onClick={() => {
                  registrationInput.policy_agree = !registrationInput.policy_agree;
                }}
              />{''}
              <span>I have read and agree to freemason-alpha.com's</span>
            </Label>
          </FormGroup>
          <span className="policy-docs" onClick={() =>
            policyDocsStore.toggleTermsModal()
          }>Terms of Service</span>
          <span> and </span>
          <span className="policy-docs" onClick={() =>
            policyDocsStore.togglePrivacyModal()
          }>Privacy Policy</span>
        </div>
        <br/>

        <div id='navigation_buttons'>
          <Button
          color="primary"
          disabled={!registrationInput.policy_agree}
          onClick={this.handleNextClick}>
            Next
          </Button>
        </div>



      </div>
    );
  }
}

export default Registration;
