import { observable, action } from 'mobx';
import { FeedWallLeftPanel } from '../domain/feed-wall-left-panel';
import api from '../api';

export class FeedWallLeftPanelStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {}

}

export const feedWallLeftPanelStore = new FeedWallLeftPanelStore();
(window as any).feedWallLeftPanelStore = feedWallLeftPanelStore;
