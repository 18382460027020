import { observable, action } from 'mobx';
import { SuggestEditMeeting } from '../domain/suggest-edit-meeting';
import api from '../api';

export class SuggestEditMeetingStore {
  @observable isLoading = false;

  @observable lodgeInput: Partial<SuggestEditMeeting> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    degree: '',
    meetings: [{
      time: '',
      position: '',
      day: '',
      month: '',
      type: '',
      description: '',
      description_extra: ''
    }]
  };
  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  @action
  addMeeting = () => {
      this.lodgeInput.meetings.push({
        time: '',
        position: '',
        day: '',
        month: '',
        type: '',
        description: '',
        description_extra: ''
      });
  };

  @action
  removeMeeting = (indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < this.lodgeInput.meetings.length) {
      this.lodgeInput.meetings.splice(indexToRemove, 1);
    }
  };

  setSuggestEditMeetingData = (meetingData: any) => {
    if (meetingData.length > 0) {
      this.lodgeInput.meetings = meetingData;
    }
  };

  clearMeetingInput = () => {
    this.lodgeInput.country = '';
    this.lodgeInput.district = '';
    this.lodgeInput.name = '';
    this.lodgeInput.number = '';
    this.lodgeInput.constitution = '';
    this.lodgeInput.degree = '';
    this.lodgeInput.meetings = [];
    /*this.lodgeInput.meetings.push({
      time: '',
      position: '',
      day: '',
      month: '',
      type: ''
    });*/
    console.log('clearing edit meeting input');
  };

}

export const suggestEditMeetingStore = new SuggestEditMeetingStore();
(window as any).suggestEditMeetingStore = suggestEditMeetingStore;
