// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { UserAccountStore } from '../../stores/user-account-store';
import { creditTransferAmountStore, CreditTransferAmountStore } from '../../stores/credit-transfer-amount-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


import { creditTransferInitiateStore, CreditTransferInitiateStore } from '../../stores/credit-transfer-initiate-store';
import { TextInput } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  userAccountStore?: UserAccountStore;
  creditTransferAmountStore?: CreditTransferAmountStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  creditTransferInitiateStore?: CreditTransferInitiateStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  amount: number;
}

@inject('userAccountStore',
  'contributeStore',
  'loginStore',
  'creditTransferAmountStore',
  'creditTransferInitiateStore')
@observer
export class CreditTransferAmount extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      amount: 0
    };
  }

  render() {
    if (!this.props.userAccountStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const userAccountStore = this.props.userAccountStore;//typeOfThankYou

    const validationResult = this.props.creditTransferAmountStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Credit Transfer - Amount</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>
            <Row className=''>
              <Col>
                <span className='small-font'>
                  Your current balance is: <b>{loginStore.user_credits}</b> Credits
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <p>
          Enter amount of credits you wish to send to {creditTransferInitiateStore.username}.
        </p>
        <Row>
          <Col>
            <CurrencyInput
              id="amount"
              label="Amount"
              value={this.state.amount}
              onChange={e => {
                //(this.props.creditTransferAmountStore.amount = e.target.value)
                const value = parseFloat(e.target.value);
                if (!isNaN(value) && value <= loginStore.user_credits && value > 0) {
                  let t = Math.round(value * 100) / 100;
                  this.props.creditTransferAmountStore.amount = t;
                  this.setState({amount: t});
                } else {
                  console.log("Value exceeds available credits or is not a valid number");
                }
              }}
              invalid={!!validationResult.lodge_name}
              validationMessage={validationResult.lodge_name}
            />
          </Col>
        </Row>
        <p>
          Click confirm to proceed.
        </p>

        <hr />
        <Button color="primary" onClick={async () => {
          const success = await creditTransferAmountStore.getTransferConfirmationCode(loginStore);
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            this.props.nextStep();
          }
        }}>
          Confirm
        </Button>
        <Button color='' onClick={this.props.profileStep}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 14'
            id='Delete-1--Streamline-Core'
            height='14'
            width='14'
          >
            <desc>Delete 1 Streamline Icon: https://streamlinehq.com</desc>
            <g id='delete-1--remove-add-button-buttons-delete-cross-x-mathematics-multiply-math'>
              <path
                id='Union'
                fill='#8fbffa'
                fill-rule='evenodd'
                d='M1.707 0.293A1 1 0 0 0 0.293 1.707L5.586 7 0.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414 -1.414L8.414 7l5.293 -5.293A1 1 0 0 0 12.293 0.293L7 5.586 1.707 0.293Z'
                clip-rule='evenodd'
                stroke-width='1'
              ></path>
            </g>
          </svg>
          <span> Cancel</span>
        </Button>
      </div>
    )
      ;
  }
}

export default CreditTransferAmount;
