// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import { ResetPasswordNewPasswordStore } from '../../stores/reset-password-new-password-store';

import { LoginStore } from '../../stores/login-store';

import { ResetPasswordConfirmStore } from '../../stores/reset-password-confirm-store';
import { ResetPasswordEmailStore } from '../../stores/reset-password-email-store';

//import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput, PasswordInput } from '../inputs/text';
import { NumberInput } from '../inputs/number';

//import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  loginStep: () => void;
  forgotPasswordStep: () => void;
  resetPasswordNewPasswordStore?: ResetPasswordNewPasswordStore;
  resetPasswordConfirmStore?: ResetPasswordConfirmStore;
  resetPasswordEmailStore?: ResetPasswordEmailStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  pass_one: string;
  pass_two: string;
}

@inject('resetPasswordNewPasswordStore', 'resetPasswordConfirmStore', 'resetPasswordEmailStore', 'loginStore')
@observer
export class ResetPasswordNewPassword extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      pass_one: '',
      pass_two: ''
    };
  }

  render() {
    if (!this.props.resetPasswordNewPasswordStore) {
      return;
    }

    const resetPasswordEmailStore = this.props.resetPasswordEmailStore;

    const loginStore = this.props.loginStore;

    const resetPasswordConfirmStore = this.props.resetPasswordConfirmStore;

    const resetPasswordNewPasswordStore = this.props.resetPasswordNewPasswordStore;
    const resetPasswordNewPasswordInput = resetPasswordNewPasswordStore.resetPasswordNewPasswordInput;
    const validationResult = resetPasswordNewPasswordStore.validationResult;

    return (
        <div>
          <Row>
            <Col sm="12">
              <h1>Reset Password</h1>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <PasswordInput
                  id="password1"
                  label="New Password"
                  value={this.state.pass_one}
                  onChange={e => {
                      resetPasswordNewPasswordInput.password1 = e.target.value;
                      this.setState({pass_one: e.target.value});
                    }
                  }
                  onKeyPress={e => {}}
                  invalid={!!validationResult.password1}
                  validationMessage={validationResult.password1}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PasswordInput
                  id="password2"
                  label="Confirm Password"
                  value={this.state.pass_two}
                  onChange={e => {
                      resetPasswordNewPasswordInput.password2 = e.target.value;
                      this.setState({pass_two: e.target.value});
                    }
                  }
                  onKeyPress={e => {}}
                  invalid={!!validationResult.password2}
                  validationMessage={validationResult.password2}
              />
            </Col>
          </Row>

          <div id='navigation_buttons'>
            <Button color="primary" onClick={async () => {

              resetPasswordNewPasswordStore.validate();
              const success = resetPasswordNewPasswordStore.checkValidation();
              //console.log('----');
              //console.log(success);
              //console.log('----');
              if (success) {
                //toast.info("Confirming status");
                if (resetPasswordNewPasswordInput.password1 == resetPasswordNewPasswordInput.password2 ) {
                  if (await resetPasswordNewPasswordStore.changePassword(
                      (resetPasswordEmailStore.resetPasswordEmailInput.username != '')
                          ? resetPasswordEmailStore.resetPasswordEmailInput.username
                          : loginStore.email,
                      (loginStore.two_fa != '')
                          ? loginStore.two_fa
                          : resetPasswordConfirmStore.resetPasswordConfirmInput.code
                  )) {
                    //toast.success("Welcome back!");
                    //toast.success("Hi!!!");
                    resetPasswordConfirmStore.resetPasswordConfirmInput.code = "";
                    this.props.loginStep();
                  } else {
                    toast.error("An error has occurred!");
                  }
                }
              }
            }}>
              Next
            </Button>

          </div>
        </div>
    );
  }
}

export default ResetPasswordNewPassword;
