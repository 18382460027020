import { observable, action } from 'mobx';
import { SchedulePlanner } from '../domain/schedule-planner';
import api from '../api';
import { convertDate, getCurrentDate } from '../helpers/calendar';
import { forEach, includes } from 'lodash';
import { loginStore } from './login-store';

export class SchedulePlannerStore {
  @observable isLoading = false;
  @observable page?: number;
  @observable theViewedDay?: string;
  @observable currentDate?: Date;
  @observable selectedDate?: Date;
  @observable selectedDateString?: string;

  @observable calendarMeetings?: any;

  @observable dayLodgesMeet?: any;

  constructor() {
    this.calendarMeetings = {};
  }

  setDate = (loginStore) => {
    this.page = 2; // default to list view
    this.selectedDateString = getCurrentDate();
    //this.selectedDateString = convertDate(new Date());
    this.selectedDate = new Date(this.selectedDateString);
    this.currentDate = new Date(this.selectedDateString);

    this.selectedDateString = convertDate(this.selectedDate);

    this.getMonthsMeetings(loginStore);

    console.log("this.selectedDate");
    console.log(this.selectedDate);
  };

  getMonthsMeetings = async (loginStore) => {
    console.log("getMonthsMeetings - this.selectedDate");
    console.log(this.selectedDate);
    //console.log("this.calendarMeetings");
    //console.log(this.calendarMeetings);

    const selectedDate = schedulePlannerStore.selectedDate.getFullYear() + '-' + ('0' + (schedulePlannerStore.selectedDate.getMonth() + 1)).slice(-2);
    // Log selected date for debugging
    console.log("Selected Date:", selectedDate);
    // Check if data already exists for the selected month
    if (this.calendarMeetings && this.calendarMeetings[selectedDate]) {
      console.log("Data already exists for", selectedDate);
      return true; // or you might want to return the existing data or some status
    }
    this.isLoading = true;

    try {
      let dat = {
        miniToken: loginStore.miniToken,
        month: schedulePlannerStore.selectedDate
      };
      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');
      const result = await api.getSpecifiedMonthsCalendar(dat, loginStore.authToken);
      console.log('======= getSpecifiedMonthsCalendar =======');
      console.log(result);
      this.calendarMeetings[selectedDate] = result;
      //console.log(result.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  getDaysMeetings = async (d, visitationMeetingsFuture) => {
    console.log("getDaysMeetings - this.selectedDate");
    console.log(this.selectedDate);
    console.log("getDaysMeetings - d");
    console.log(d);

    const selectedDate = schedulePlannerStore.selectedDate.getFullYear() + '-' + ('0' + (schedulePlannerStore.selectedDate.getMonth() + 1)).slice(-2);
    console.log("getDaysMeetings - this.calendarMeetings[selectedDate]");
    console.log(this.calendarMeetings[selectedDate]);

    //console.log("getDaysMeetings - this.calendarMeetings[selectedDate][0]");
    //console.log(this.calendarMeetings[selectedDate][0]);
    const meetings = this.calendarMeetings[selectedDate].filter(item => item.date === d);
    console.log("getDaysMeetings - lodges from all meetings");
    // Collect all lodges from the filtered meetings
    const allLodges = meetings.map(meeting => meeting.lodges);
    console.log(allLodges);

    //console.log("getDaysMeetings - visitationMeetingsFuture");
    //console.log(visitationMeetingsFuture);

    const c_meetings = visitationMeetingsFuture.filter(visit => visit.datetime.includes(d));
    console.log("getDaysMeetings - c_meetings");
    console.log(c_meetings);

    // Create a Set for faster lookups
    const cMeetingsSet = new Set(c_meetings.map(meeting => `${meeting.lodge_name}|${meeting.lodge_number}`));
    console.log(cMeetingsSet);
    const allLodgesTemp = allLodges.flat();
    console.log(allLodgesTemp);
    // Filter the lodges that match the criteria
    // Map allLodgesTemp to include a matched key
    const lodgesWithMatchStatus = allLodgesTemp.map(lodge => {
      const isMatched = cMeetingsSet.has(`${lodge.name}|${lodge.number}`);
      return {
        ...lodge, // Spread the existing lodge properties
        matched: isMatched // Add the matched key
      };
    });

    console.log("lodgesWithMatchStatus");
    console.log(lodgesWithMatchStatus);

    //datetime
    //d time


    this.dayLodgesMeet = lodgesWithMatchStatus; // allLodges;

  };




}

export const schedulePlannerStore = new SchedulePlannerStore();
(window as any).schedulePlannerStore = schedulePlannerStore;
