// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { FeedbackStore } from '../../stores/feedback-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  thankYouStep: () => void;
  loginStep: () => void;
  feedbackStore?: FeedbackStore;
  contributeStore?: ContributeStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
}

interface State {
    clicked: boolean;
    valid: any; // change this
    message: string;
}

@inject('feedbackStore', 'contributeStore', 'loginStore', 'thankYouStore')
@observer
export class Feedback extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
        valid: {},
        clicked: false,
        message: ""
    };
  }

  render() {
    if (!this.props.feedbackStore) {
      return;
    }

    const thankYouStore = this.props.thankYouStore;
    //thankYouStore.typeOfThankYou = 'feedback';// throwing an error after feedback submitted, then renavigating to feedback page

    const loginStore = this.props.loginStore;
    const feedbackStore = this.props.feedbackStore;
    const feedbackInput = feedbackStore.feedbackInput;
    const validationResult = feedbackStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Feedback</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <span className="small-font-italic">
        Your feedback stands as a cornerstone in our quest for excellence within
        the Freemason Travellers Guide. Thank you for your contributions and valued
        input, which creates a more enriching experience for fellow masons, and guides
        us as we sculpt our shared path of exploration and discovery.
        </span>
        <br/><br/>
        <Row>
          <Col>
            <TextArea
              id="message"
              label="Message:"
              value={this.state.message}
              onChange={e => {
                    feedbackInput.message = e.target.value;
                    this.setState({message: e.target.value});
                }
              }
              invalid={!!validationResult.message}
              validationMessage={validationResult.message}
            />
          </Col>
        </Row>
        <br/>
        <Button color="" onClick={async () => {
            let res = await feedbackStore.provideFeedback(feedbackInput.message, loginStore);
            if (res) {
                toast.success("Your feedback has been submitted!");
                //thankYouStore.typeOfThankYou = 'feedback';
                //this.props.thankYouStep();
                feedbackInput.message = ""; // reset the feedback message after submission
                this.setState({message: ""});
            }
            else if (res == "goto_login") {
                this.props.loginStep();
            }
            else {
                toast.warning("Could not submit your feedback!");
            }
        }}>
            <Icon id="send" text="Submit"></Icon>
        </Button>

        </CardBody>
        </Card>


          <Card>
              <CardBody>
                  <Icon id="feed"></Icon>
                  <Icon id="profile" accent_color="" base_color="" text=""></Icon>
                  <Icon id="membership" accent_color="" base_color="" text=""></Icon>
                  <Icon id="passport" accent_color="" base_color="" text=""></Icon>
                  <Icon id="adverts" accent_color="" base_color="" text=""></Icon>
                  <Icon id="settings" accent_color="" base_color="" text=""></Icon>
                  <Icon id="search" accent_color="" base_color="" text=""></Icon>
                  <Icon id="magnifying-glass" accent_color="" base_color="" text=""></Icon>
                  <Icon id="contribute" accent_color="" base_color="" text=""></Icon>
                  <Icon id="events" accent_color="" base_color="" text=""></Icon>
                  <Icon id="leaderboard" accent_color="" base_color="" text=""></Icon>
                  <Icon id="faqs" accent_color="" base_color="" text=""></Icon>
                  <Icon id="feedback" accent_color="" base_color="" text=""></Icon>
                  <Icon id="info" accent_color="" base_color="" text=""></Icon>
                  <Icon id="logout" accent_color="" base_color="" text=""></Icon>
                  <Icon id="login" accent_color="" base_color="" text=""></Icon>
                  <Icon id="forgot-password" accent_color="" base_color="" text=""></Icon>
                  <Icon id="help" accent_color="" base_color="" text=""></Icon>
                  <Icon id="back" accent_color="" base_color="" text=""></Icon>
                  <Icon id="continue" accent_color="" base_color="" text=""></Icon>
                  <Icon id="add-lodge" accent_color="" base_color="" text=""></Icon>
                  <Icon id="contact" accent_color="" base_color="" text=""></Icon>
                  <Icon id="claim-membership" accent_color="" base_color="" text=""></Icon>
                  <Icon id="emergency-meeting" accent_color="" base_color="" text=""></Icon>
                  <br/>
                  <Icon id="suggest-edit" accent_color="" base_color="" text=""></Icon>
                  <br/>
                  <Icon id="promote" accent_color="" base_color="" text=""></Icon>
                  <Icon id="send" accent_color="" base_color="" text=""></Icon>
                  <Icon id="clock" accent_color="" base_color="" text=""></Icon>
                  <Icon id="globe" accent_color="" base_color="" text=""></Icon>
                  <Icon id="attend" accent_color="" base_color="" text=""></Icon>
                  <Icon id="attending" accent_color="" base_color="" text=""></Icon>
                  <Icon id="share" accent_color="" base_color="" text=""></Icon>
                  <Icon id="lodge" accent_color="" base_color="" text=""></Icon>
                  <br/>
                  <Icon id="edits" accent_color="" base_color="" text=""></Icon>
                  <br/>
                  <Icon id="votes" accent_color="" base_color="" text=""></Icon>
                  <Icon id="referrals" accent_color="" base_color="" text=""></Icon>
                  <Icon id="attendance" accent_color="" base_color="" text=""></Icon>
                  <Icon id="create-event" accent_color="" base_color="" text=""></Icon>
                  <Icon id="crown" accent_color="" base_color="" text=""></Icon>
                  <Icon id="graph" accent_color="" base_color="" text=""></Icon>
                  <Icon id="plus" accent_color="" base_color="" text=""></Icon>
                  <Icon id="minus" accent_color="" base_color="" text=""></Icon>
                  <Icon id="schedule" accent_color="" base_color="" text=""></Icon>
                  <Icon id="options" accent_color="" base_color="" text=""></Icon>
                  <Icon id="coin" accent_color="" base_color="" text=""></Icon>
                  <Icon id="whats-app" accent_color="" base_color="" text=""></Icon>
                  <Icon id="location" accent_color="" base_color="" text=""></Icon>
                  <Icon id="total" accent_color="" base_color="" text=""></Icon>
                  <Icon id="trash" accent_color="" base_color="" text=""></Icon>
                  <Icon id="user-remove" accent_color="" base_color="" text=""></Icon>
                  <Icon id="calendar" accent_color="" base_color="" text=""></Icon>
                  <Icon id="list" accent_color="" base_color="" text=""></Icon>
                  <Icon id="prev" accent_color="" base_color="" text=""></Icon>
                  <Icon id="next" accent_color="" base_color="" text=""></Icon>
                  <Icon id="visible" accent_color="" base_color="" text=""></Icon>
                  <Icon id="invisible" accent_color="" base_color="" text=""></Icon>
                  <Icon id="edit" accent_color="" base_color="" text=""></Icon>
                  <Icon id="transactions" accent_color="" base_color="" text=""></Icon>
                  <Icon id="piggy-bank" accent_color="" base_color="" text=""></Icon>
                  <Icon id="receive" accent_color="" base_color="" text=""></Icon>
                  <Icon id="link" accent_color="" base_color="" text=""></Icon>
                  <Icon id="like" accent_color="" base_color="" text=""></Icon>
                  <Icon id="dislike" accent_color="" base_color="" text=""></Icon>
                  <Icon id="comment" accent_color="" base_color="" text=""></Icon>
                  <Icon id="reload" accent_color="" base_color="" text=""></Icon>


              </CardBody>
          </Card>

      </div>
    );
  }
}

export default Feedback;
