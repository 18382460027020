// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeLogoFromId,
  meetingStringToDate,
  meetingString,
  meetingStringToDateWithDescription,
  degreeFromIdNoCollective,
  getAddressFromConfigsById,
  getShortConstitutionFromConfigsById,
  getDistrictFromConfigsById,
  getCountryFromConfigsById,
  getLanguageFromConfigsById
} from '../../helpers/utilities';

import { SuggestEditConfirmationStore } from '../../stores/suggest-edit-confirmation-store';
import { SuggestEditLodgeStore } from '../../stores/suggest-edit-lodge-store';
import { SuggestEditMeetingStore } from '../../stores/suggest-edit-meeting-store';
import { SuggestEditContactStore } from '../../stores/suggest-edit-contact-store';
import { ConfigurationStore } from '../../stores/configuration-store';
import { LoginStore } from '../../stores/login-store';
import { ThankYouStore } from '../../stores/thank-you-store';

import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  thankYouStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  suggestEditConfirmationStore?: SuggestEditConfirmationStore;
  suggestEditLodgeStore?: SuggestEditLodgeStore;
  suggestEditMeetingStore?: SuggestEditMeetingStore;
  suggestEditContactStore?: SuggestEditContactStore;
  configurationStore?: ConfigurationStore;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'suggestEditConfirmationStore',
  'suggestEditLodgeStore',
  'suggestEditMeetingStore',
  'suggestEditContactStore',
  'configurationStore',
  'loginStore',
  'thankYouStore'
)
@observer
export class SuggestEditConfirmation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.suggestEditConfirmationStore) {
      return;
    }

    const suggestEditLodgeStore = this.props.suggestEditLodgeStore;
    const suggestEditMeetingStore = this.props.suggestEditMeetingStore;
    const suggestEditContactStore = this.props.suggestEditContactStore;

    const suggestEditConfirmationStore = this.props.suggestEditConfirmationStore;

    const lodgeInput = suggestEditConfirmationStore.lodgeInput;
    const validationResult = suggestEditConfirmationStore.validationResult;

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    const loginStore = this.props.loginStore;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'contribution';

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Confirmation</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>

        <Row>
          <Col sm="12">
            <h3>{suggestEditLodgeStore.lodgeInput.name} No. {suggestEditLodgeStore.lodgeInput.number} {getShortConstitutionFromConfigsById(suggestEditLodgeStore.lodgeInput.constitution, configurationStore.constitutionResults)}</h3>
          </Col>
        </Row>
        <hr />
        <h5>Details:</h5>
        <div>
        <Row>
          <Col sm="6">
            <span className='lodge-details-heading'>Country:</span>
            <br/>
            {getCountryFromConfigsById(suggestEditLodgeStore.lodgeInput.country, configurationStore.countryResults)}
          </Col>
          <Col sm="6">
            <span className='lodge-details-heading'>District:</span>
            <br/>
            {getDistrictFromConfigsById(suggestEditLodgeStore.lodgeInput.district, configurationStore.districtResults)}
          </Col>
        </Row>
          <div>
          <span className='lodge-details-heading'>Order:</span>
          <br/>
          {degreeFromIdNoCollective(suggestEditLodgeStore.lodgeInput.degree)}
          <br/>
          <span className='lodge-details-heading'>Address:</span>
          <br/>
          {getAddressFromConfigsById(suggestEditLodgeStore.lodgeInput.address_id, configurationStore.addressResults)}
          <br/>
          <span className='lodge-details-heading'>Meetings:</span>
          <br/>
          {meetingString(suggestEditMeetingStore.lodgeInput.meetings)}
          <br/>
          <span className='lodge-details-heading'>Contact:</span>
          <br/>
          {(suggestEditContactStore.contactInput.title != '') ? suggestEditContactStore.contactInput.title + ' ' : ''}{suggestEditContactStore.contactInput.name} {suggestEditContactStore.contactInput.surname} {suggestEditContactStore.contactInput.contact_number}{(suggestEditContactStore.contactInput.contact_number_second != '') ? ' (' + suggestEditContactStore.contactInput.contact_number_second + ')' : ''}
          <br/>
          <span className='lodge-details-heading'>Website:</span>
          <br/>
          {suggestEditContactStore.contactInput.website}
          <br/>
          <span className='lodge-details-heading'>Email:</span>
          <br/>
          {suggestEditContactStore.contactInput.email}
          <br/>
          <span className='lodge-details-heading'>Language:</span>
          <br/>
          {getDistrictFromConfigsById(suggestEditLodgeStore.lodgeInput.language, configurationStore.languageResults)}
          </div>
        </div>
        <hr />
        <h5>Meetings:</h5>
        <div>
        {suggestEditMeetingStore.lodgeInput.meetings.length > 0 ? (suggestEditMeetingStore.lodgeInput.meetings.map((meeting, index) => (
          meeting.type != '3' ?
          <div>
            {meetingStringToDateWithDescription(meeting.position,meeting.day,meeting.month, meeting.type, meeting.description, meeting.description_extra, configurationStore.descriptionResults)}
          </div>
          : ''
        ))) : ''}
        </div>

        <hr />
        <Button
        color=""
        onClick={async () => {
          this.props.setLoadingTrue();

          const res = await suggestEditConfirmationStore.makeSuggestion(
            suggestEditLodgeStore,
            suggestEditMeetingStore,
            suggestEditContactStore,
            loginStore,
            meetingString(suggestEditMeetingStore.lodgeInput.meetings));
          /*const success = addConfirmationStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {*/
            //this.props.nextStep()
          //}
          console.log('---------');
          console.log(res);
          console.log('---------');
          if (res) {
            this.props.thankYouStep();
          }
          else {
            toast.warning("Could not make suggestion!");
          }

          this.props.setLoadingFalse();
        }}
        >
          <Icon id="send" text="Submit"></Icon>
        </Button>
        <Button color="" onClick={this.props.previousStep}>
          <Icon id="back" text="Back"></Icon>
        </Button>

            </CardBody>
          </Card>

      </div>
    );
  }
}

export default SuggestEditConfirmation;
