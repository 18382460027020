import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Input,
  FormFeedback,
  InputGroup,
  FormGroup,
  Label,
} from 'reactstrap';

interface Props {
  id: string;
  label: string;
  value: any;
  onChange: (e: any) => void;
  invalid: boolean;
  validationMessage: string;
}

export const DateInput: React.FC<Props> = observer((props: Props) => {
  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <InputGroup>
        <Input
          type='date'
          value={props.value || ''}
          onChange={props.onChange}
          invalid={props.invalid}
          id={props.id}
        />
      </InputGroup>
      <FormFeedback style={{ display: !!props.invalid && 'block' }} >
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  )
})

