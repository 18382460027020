// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { RegistrationStore } from '../../stores/registration-store';
import { RegistrationTwoStore } from '../../stores/registration-two-store';
import { RegistrationThreeStore } from '../../stores/registration-three-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, PasswordInput } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  addLodgeStep: () => void;
  getConfirmationCode: () => string;
  registrationStore?: RegistrationStore;
  registrationTwoStore?: RegistrationTwoStore;
  registrationThreeStore?: RegistrationThreeStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'registrationStore',
  'registrationTwoStore',
  'registrationThreeStore',
  'thankYouStore',
  'contributeStore'
)
@observer
export class RegistrationThree extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }


  handleAutoNextClick = async (confirmation_code, email, miniToken) => {
    const { registrationStore, registrationTwoStore, registrationThreeStore } = this.props;
    if (true) { //(success) {
      registrationThreeStore.setConfirmationData(confirmation_code, email, miniToken);
      if(await registrationThreeStore.confirm2FACode(registrationStore, registrationTwoStore)) {
        this.props.nextStep();
      }
      else {
        toast.error("Confirmation failed!");
      }
    }
  };


  render() {
    if (!this.props.registrationThreeStore) {
      return;
    }



    const registrationStore = this.props.registrationStore;
    const registrationTwoStore = this.props.registrationTwoStore;
    const registrationThreeStore = this.props.registrationThreeStore;
    //console.log("------- registrationStore.lodgeResults -------");
    //console.log(registrationStore.lodgeResults);
    //console.log("---------------------------------------");

    const registrationInput = registrationThreeStore.registrationInput;
    const validationResult = registrationThreeStore.validationResult;

    //console.log("------- this.props.getConfirmationCode -------");
    //console.log(this.props.getConfirmationCode());
    //console.log(atob(this.props.getConfirmationCode()));
    //console.log(atob(this.props.getConfirmationCode()).split("_!!!_")[1]);
    //console.log("---------------------------------------");

    let confirmation_data = '';//atob(this.props.getConfirmationCode());
    let confirmation_code = '';
    let email = '';
    let miniToken = '';
    try {
      confirmation_data = atob(this.props.getConfirmationCode());
      confirmation_code = confirmation_data.split("_!!!_")[1];
      email = confirmation_data.split("_!!!_")[0];
      miniToken = confirmation_data.split("_!!!_")[2];
    }
    catch (error) {
      console.log("ERROR!!!! reg three");
      console.error(error);
      //return false;
    } finally {
      //this.isLoading = false;
    }

    this.handleAutoNextClick(confirmation_code, email, miniToken);

    return (
      <div>
      <Row>
        <Col sm="12">
          <h1>Almost done...</h1>
        </Col>
      </Row>
      {/*
        <Row>
          <Col sm="12">
            <h1>Confirm Email</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <PasswordInput
              id="confirmation_code"
              label="Enter code sent to your email"
              value={(confirmation_code.toString() != '') ? confirmation_code : registrationInput.confirmation_code}
              onChange={e =>
                (registrationInput.confirmation_code = e.target.value)
              }
              invalid={!!validationResult.confirmation_code}
              validationMessage={validationResult.confirmation_code}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <span className="small-font-italic">
            <p>Almost there, click next to complete verification process.</p>

            <p>
              Upon clicking next you can expect:
              <ol>
                <li><b>Receive Your Password:</b> Shortly, you will receive a random password via email. This password will be used for your initial login.</li>
                <li><b>Log In:</b> Once you receive the random password, use it to log in to your account. Don't worry, you'll have the opportunity to change it to something more memorable.</li>
                <li><b>Change Your Password:</b> Upon logging in with the random password, you'll be prompted to change it to a password of your choosing. This will ensure the security of your account.</li>
                <li><b>Access Your Account:</b> After changing your password, you'll be redirected to log in again, this time using the password you've selected.</li>
              </ol>
            </p>

            </span>
          </Col>
        </Row>
        <br/>

        <div id='navigation_buttons'>

          <Button color="primary" onClick={async () => {
            //registrationThreeStore.validate();
            //const success = registrationThreeStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            if (true) { //(success) {
              registrationThreeStore.setConfirmationData(confirmation_code, email, miniToken);
              if(await registrationThreeStore.confirm2FACode(registrationStore, registrationTwoStore)) {
                this.props.nextStep();
              }
              else {
                toast.error("Confirmation failed!");
              }
            }
          }}>
            Next
          </Button>

          <br/>
          <br/>
          <Button color="primary" onClick={async () => {
            registrationThreeStore.resendCode();
            toast.success("Confirmation Code Sent");
          }}>
            Resend Code
          </Button>
        </div>
        */}
      </div>
    );
  }
}

export default RegistrationThree;
