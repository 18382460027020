import { observable, action } from 'mobx';
import { FeedWall } from '../domain/feed-wall';
import api from '../api';
import {loginStore} from "./login-store";

import {
  addOneYearAndSetToBeginningOfYear
} from '../helpers/utilities';

export class FeedWallStore {
  @observable isLoading = false;
  @observable userFeedPromotedNotices: any = [];
  @observable userFeedSponsoredNoticesRightPanel: any = [];
  @observable userFeedUpcomingMeetings: any = [];
  @observable userFeedTopThreeOnLeaderboard: any = [];

  @observable input = '';

  @observable scrollCount = 0;

  @observable theLastMeetingDate = '';

  constructor() {
  }

  getUserFeed = async (miniToken) => {
    let dat = {
      miniToken: miniToken
    };

    let result = [];
    try {
      result = await api.getUserFeed(dat, loginStore.authToken);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      console.log('======= getUserFeed =======');
      this.userFeedPromotedNotices = result['promotedNotices'];
      this.userFeedSponsoredNoticesRightPanel = result['sponsoredNoticesRightPanel'];
      this.userFeedUpcomingMeetings = result['upcomingMeetings'];
      this.userFeedTopThreeOnLeaderboard = result['topThreeOnLeaderboard'];
      console.log(result);
      console.log('============================');

      // attempting to save the last date
      //this.theLastMeetingDate = this.userFeedUpcomingMeetings[this.userFeedUpcomingMeetings.length - 1].date;
      // Filter the array based on the conditions
      const filteredMeetings = this.userFeedUpcomingMeetings.filter(meeting =>
          meeting.post_type === 1 &&
          meeting.promoted === false &&
          meeting.sponsored === false
      );

      // Check if there are any meetings that match the conditions
      if (filteredMeetings.length > 0) {
        // Set theLastMeetingDate to the date of the last meeting that matches the conditions
        let temp = filteredMeetings[filteredMeetings.length - 1].date;
        if (temp == this.theLastMeetingDate) {
          this.theLastMeetingDate = addOneYearAndSetToBeginningOfYear(this.theLastMeetingDate);
        }
        else {
          this.theLastMeetingDate = temp;
        }
        //this.theLastMeetingDate = filteredMeetings[filteredMeetings.length - 1].date;
      } else {
        // Handle the case where no meetings match the conditions (if necessary)
        console.log('No upcoming meetings match the conditions');
        this.theLastMeetingDate = null; // or some default value
      }
      console.log("the last meetings date in feed initial load!!!");
      console.log(this.theLastMeetingDate);
      //this.isLoading = false;
    }
  };

  getUserFeedWallOnly = async (miniToken) => {
    let result = [];
    try {
      this.scrollCount += 10;

      let dat = {
        miniToken: miniToken,
        scrollCount: this.scrollCount,
        theLastMeetingDate: this.theLastMeetingDate
      };

      result = await api.getUserFeedWallOnly(dat, loginStore.authToken);
      //return true;
    } catch (error) {
      console.error(error);
      //return false;
    } finally {
      console.log('======= getUserFeedWallOnly =======');
      //this.userFeedSponsoredNotices = result['sponsoredNotices'];
      //this.userFeedSponsoredNoticesRightPanel = result['sponsoredNoticesRightPanel'];
      //this.userFeedUpcomingMeetings.concat(result['upcomingMeetings']);
      this.userFeedUpcomingMeetings = [...this.userFeedUpcomingMeetings, ...result['upcomingMeetings']];
      //this.userFeedTopThreeOnLeaderboard = result['topThreeOnLeaderboard'];
      console.log(result);
      console.log('============================');

      // kind of works but doesn't really
      // the sponsored, promoted and user posts
      // cause an issue, because they can be last in the list
      //this.theLastMeetingDate = this.userFeedUpcomingMeetings[this.userFeedUpcomingMeetings.length - 1].date;
      // Filter the array based on the conditions
      const filteredMeetings = this.userFeedUpcomingMeetings.filter(meeting =>
          meeting.post_type === 1 &&
          meeting.promoted === false &&
          meeting.sponsored === false
      );

      // Check if there are any meetings that match the conditions
      if (filteredMeetings.length > 0) {
        // Set theLastMeetingDate to the date of the last meeting that matches the conditions
        let temp = filteredMeetings[filteredMeetings.length - 1].date;
        if (temp == this.theLastMeetingDate) {
          this.theLastMeetingDate = addOneYearAndSetToBeginningOfYear(this.theLastMeetingDate);
        }
        else {
          this.theLastMeetingDate = temp;
        }
        //this.theLastMeetingDate = filteredMeetings[filteredMeetings.length - 1].date;
      } else {
        // Handle the case where no meetings match the conditions (if necessary)
        console.log('No upcoming meetings match the conditions');
        this.theLastMeetingDate = null; // or some default value
      }
      console.log("the last meetings date in feed subsequent load!!!");
      console.log(this.theLastMeetingDate);

      //this.isLoading = false;
      return this.userFeedUpcomingMeetings;
    }
  };


  likePost = async (miniToken, post_id) => {
    let dat = {
      miniToken: miniToken,
      post_id: post_id
    };
    //console.log("DOUBLE CHECKING -> dat");
    //console.log(dat);

    let result = await api.likeUserPost(dat, loginStore.authToken);

    return result;
  };
}

export const feedWallStore = new FeedWallStore();
(window as any).feedWallStore = feedWallStore;
