// @ts-check
import React, {Component} from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import {
  getUniqueConstitutionsAndOrders,
  orderLogoFromConstitutionAndOrder
} from '../../helpers/utilities';

import { ViewProfileStore } from '../../stores/view-profile-store';
import { ContributeStore } from '../../stores/contribute-store';
import { CreditTransferInitiateStore } from "../../stores/credit-transfer-initiate-store";
import { UserAccountStore} from "../../stores/user-account-store";
import { LoginStore } from '../../stores/login-store';
import {ProfileStore} from "../../stores/profile-store";

import { Icon } from "../svg_icons/icon";
import { ViewProfilePost } from "../display-components/view-profile-post";
import { toast } from "react-toastify";




interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  feedWallStep: () => void;
  creditTransferInitiateStep: () => void;
  viewProfileStore?: ViewProfileStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  creditTransferInitiateStore?: CreditTransferInitiateStore;
  userAccountStore?: UserAccountStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  postsLiked: Array<Boolean>;
}



@inject('viewProfileStore',
    'contributeStore',
    'loginStore',
    'creditTransferInitiateStore',
    'userAccountStore')
@observer
export class ViewProfile extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      postsLiked: [],
    };

    this.scrollToTop();


  }

  componentDidMount() {
    const initializeArrayWithValues = (n, val = false) => Array(n).fill(val);
    this.setState({postsLiked: initializeArrayWithValues(this.props.viewProfileStore.profileResults.profile_feed.length, false)});

    this.props.viewProfileStore.profileResults.profile_feed.map((post, index) => {
      if (post.liked) {
        this.state.postsLiked[index] = true;
      }
    });
    this.setState({postsLiked: this.state.postsLiked});
  }

  scrollToTop = () => {
    console.log('scroll to top');
    window.scrollTo(0, 0);
  };

  getDescription = (id) => {
    document.getElementById('meeting_description_cut_'+id).style.display = 'none';
    document.getElementById('meeting_description_full_'+id).style.display = 'inherit';
    //document.getElementById('meeting_image_'+id).classList.remove("post-restricted-height");
  }


  render() {
    if (!this.props.viewProfileStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const viewProfileStore = this.props.viewProfileStore;

    return (
        <div>
          <Row>
            <Col sm="4"></Col>
            <Col sm="4" className="text-center">
              <span className="small-font-italic">Viewing Public Profile of </span>
              <br/>
              <div className="d-flex justify-content-center">
                <img className='profile-picture' src='logo.png' alt="logo" height="60px"/>
              </div>
              <h5>{viewProfileStore.profileResults.username}</h5>
              {viewProfileStore.profileResults.lodges.length < 1
                  ?
                  ''
                  : <div className="d-flex justify-content-center profile-text">
                    {getUniqueConstitutionsAndOrders(viewProfileStore.profileResults.lodges).map((lodge, index) => (
                        <img alt='logo' className="orderlogo-profile"
                             src={`${orderLogoFromConstitutionAndOrder(lodge.constitution, lodge.degree)}`}
                             height="40px"/>
                    ))}
                  </div>
              }
            </Col>
            <Col sm="4">
              <div className="x-small-font-italic">
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <Icon id="lodge"></Icon>
                        <span> Lodges: </span>
                        <span>{viewProfileStore.profileResults.number_of_lodges}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Icon id="edits"></Icon>
                        <span> Edits: </span>
                        <span>{viewProfileStore.profileResults.number_of_suggestions}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Icon id="votes"></Icon>
                        <span> Votes: </span>
                        <span>{viewProfileStore.profileResults.number_of_votes}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Icon id="referrals"></Icon>
                        <span> Referrals: </span>
                        <span>{viewProfileStore.profileResults.number_of_referrals}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Icon id="total"></Icon>
                        <span> Attendance: </span>
                        <span>{viewProfileStore.profileResults.number_of_total_visited}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>

            </Col>
          </Row>


          <Card className='post-option-active'>
            <CardBody className='top-buttons'>
              <Row className='row-justified-and-centered'>
                <Col>
                  <div id='navigation_buttons'>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          this.props.feedWallStep();
                        }}
                    >
                      <Icon id="back" text="Back"></Icon>
                    </Button>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          //this.props.feedWallStep();
                        }}
                    >
                      <Icon id="contact" text="Message"></Icon>
                    </Button>

                    {viewProfileStore.profileResults.receival_code != null
                    && viewProfileStore.profileResults.receival_code != '' ?
                    <Button className="" color="" onClick={async () => {

                      let res = await this.props.userAccountStore.validateReceivalCode(viewProfileStore.profileResults.receival_code, loginStore.miniToken);

                      if (res['status']) {
                        this.props.creditTransferInitiateStore.receival_code = viewProfileStore.profileResults.receival_code;
                        this.props.creditTransferInitiateStore.username = res['username'];
                        this.props.creditTransferInitiateStep();
                      } else {
                        toast.error("Unable to initiate sending of credits.");
                      }


                    }}>
                      <Icon id="receive" text="Send Credits"></Icon>
                    </Button>
                        : '' }

                    <Button className="" color="" onClick={async () => {
                      //this.setTourIsOpen();
                    }}>
                      <Icon id="help" text="Help"></Icon>
                    </Button>

                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>


          {viewProfileStore.profileResults.profile_feed.length > 0 ? (
                  <div>
                    <div>
                      {viewProfileStore.profileResults.profile_feed.map((post, index) => (
                          <Card className="post-option-active">
                            <CardBody className="card-padding-override">
                              <Row>
                                <Col className="col-sm-1 post_header_logo">
                                  <img
                                      src='logo.png'
                                      alt='logo'
                                      height='40px'
                                      onClick={() => {
                                        //this.props.profileStep();
                                      }}
                                  />
                                </Col>
                                <Col className="col-sm-11 post_header_title pointing-cursor">
                                <span
                                    onClick={() => {
                                      //this.props.profileStep();
                                    }}
                                >
                                  {/*loginStore.username*/}
                                  {post.title}
                                </span>
                                  <br/>
                                  <Icon id="clock"></Icon>
                                  <span className='x-small-font'> {post.date_time} </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  {
                                    post.description.length > 100
                                        ? <span id={`meeting_description_cut_${index}`}
                                                className='show_post_description pre pre-code'>{post.description.substring(0, 200) + "..."}
                                          <Button className="small-font" color="#007bff" onClick={() => {
                                            this.getDescription(index)
                                          }}>
                      see more
                    </Button>
                    </span>
                                        : ''
                                  }
                                  <span id={`meeting_description_full_${index}`} className={`${
                                      post.description.length < 100
                                          ? 'show_post_description pre pre-code'
                                          : 'hide_post_description pre pre-code'
                                  }`}>
                  {post.description}
                  </span>
                                </Col>
                              </Row>
                              {post.image !== '' ? (
                                  <Row>
                                    <Col sm="12" className="post-restricted-height">
                                      {post.image.includes('.pdf') ? (
                                              <embed src={process.env.REACT_APP_API_URL + post.image + "#toolbar=0"}
                                                     type="application/pdf" className="width-height-hundred"/>
                                          ) :
                                          post.image.includes('.mp3') ? (
                                                  <audio controls>
                                                    <source src={process.env.REACT_APP_API_URL + post.image}
                                                            type="audio/mp3"/>
                                                    Your browser does not support the audio tag.
                                                  </audio>
                                              ) :
                                              post.image.includes('.mp4') ? (
                                                      <video controls className="video-container">
                                                        <source src={process.env.REACT_APP_API_URL + post.image}
                                                                type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                      </video>
                                                  ) :
                                                  (
                                                      <img className="feed-wall-image"
                                                           src={process.env.REACT_APP_API_URL + post.image}/>
                                                  )}
                                    </Col>
                                  </Row>
                              ) : (
                                  ''
                              )}
                              <hr/>
                              <Row className="row-justified-and-centered small-font">
                                <Col>
                                  <Button className="small-font" color=""
                                          onClick={async () => {
                                            //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                            //this.props.contactStep();
                                            let res = await viewProfileStore.likePost(loginStore.miniToken, post.public_id);
                                            if (res) {
                                              toast.success("You have liked this post!");

                                              post.liked = !post.liked;

                                              // alternative to this.forceUpdate solution
                                              this.state.postsLiked[index] = !this.state.postsLiked[index];
                                              this.setState({postsLiked: this.state.postsLiked});

                                              // NB!!! not what I want to do here
                                              //this.forceUpdate();
                                              //viewProfileStore.setLiked(index);
                                            } else {
                                              toast.warning("You are unable to like this post!");
                                            }
                                          }}>

                                    {this.state.postsLiked[index] ?
                                        <Icon id="like" accent_color="#2859C5" base_color="#8FBFFA" text="Liked"></Icon>
                                        :
                                        <Icon id="like" text="Like"></Icon>
                                    }
                                    {post.likes_count > 0 ?
                                        <sup> ({post.likes_count})</sup>
                                        :
                                        ''}
                                  </Button>
                                </Col>
                                <Col>
                                  <Button className="small-font" color=""
                                          onClick={async () => {
                                            //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                            //this.props.contactStep();
                                            console.log("sending post data to comment modal");
                                            console.log(post);
                                            viewProfileStore.setPostData(post, index);
                                            viewProfileStore.toggleUserPostCommentModal();
                                          }}>
                                    <Icon id="comment" text="Comment"></Icon>
                                    {post.comments.length > 0 ?
                                        <sup> ({post.comments.length})</sup>
                                        :
                                        ''}
                                  </Button>
                                </Col>
                                <Col>
                                  <Button className="small-font" color=""
                                          onClick={async () => {
                                            //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                            //this.props.contactStep();
                                          }}>
                                    <Icon id="share" text="Share"></Icon>
                                  </Button>
                                </Col>
                              </Row>


                              {post.comments.length > 0 ?
                                  <div>
                                    <hr/>
                                    {/* comments sections - show the only one comment, the last/latest one */}

                                    {post.comments.slice(0, 2).map((comment, index) => (
                                            <div>
                                              <Row>
                                                <Col className="col-sm-1 post_header_logo">
                                                  <img
                                                      className="user-post-comment-image"
                                                      src='logo.png'
                                                      alt='logo'
                                                      height='20px'
                                                      onClick={() => {
                                                        //this.props.profileStep();
                                                      }}
                                                  />
                                                </Col>
                                                <Col>
                                                  <div className="user-post-comment">
                                                    <span className="x-small-font"><b>{comment.name}</b></span>
                                                    <br/>
                                                    <span className="small-font">{comment.comment}</span>
                                                  </div>
                                                  <br/>
                                                </Col>
                                              </Row>
                                            </div>
                                        )
                                    )}
                                  </div>
                                  : ''
                              }
                            </CardBody>
                          </Card>
                      ))}
                    </div>
                    <hr/>
                    <div>
                      {viewProfileStore.profileResults.profile_feed.map((post, index) => (
                          <ViewProfilePost
                              post={post}
                              index={index}
                              feedWallStep={this.props.feedWallStep}
                              viewProfileStore={this.props.viewProfileStore}
                              loginStore={this.props.loginStore}
                              /*profileStore={null}*/
                          ></ViewProfilePost>
                      ))}
                    </div>
                  </div>
              )
              : 'No posts yet'
          }

        </div>
    );
  }
}

export default ViewProfile;
