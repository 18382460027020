import { observable, action } from 'mobx';
import { Feedback } from '../domain/feedback';
import api from '../api';
import login from "../components/application/login";

export class FeedbackStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';// is this need? as the thankYouStore.typeOfThankYou is set on each page that uses it

  @observable feedbackInput: Partial<Feedback> = {
    message: ''
  };

  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  provideFeedback = async (feedback, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          feedback: feedback,
          miniToken: loginStore.miniToken
      };

      //const result = await api.getToken(null);
      const result2 = await api.provideFeedback(dat, loginStore.authToken);
      
      return true;
    } catch (error) {
      console.error(error);
      if (error.response) {
        // Check for a 302 error and redirect
        if (error.response.status === 302) {
          console.error("405 Error: Method Not Allowed. Redirecting...");
          //window.location.href = "/some-other-page"; // Replace with the desired URL
          return "goto_login";
        } else {
          console.error("API Error:", error.response);
          return false;
        }
      } else {
        console.error("General Error:", error.message);
        return false;
      }
      //return false;
    } finally {
      this.isLoading = false;
    }
  };

}

export const feedbackStore = new FeedbackStore();
(window as any).feedbackStore = feedbackStore;
