// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import {QRCodeCanvas} from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';

import {
  degreeFromId,
  degreeFromIdNoCollective,
  meetingStringToDate,
  getLanguageFromConfigsById,
  getConstitutionFromConfigsById,
  getConstitutionWebsiteFromConfigsById
} from '../../helpers/utilities';

import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { SuggestEditLodgeStore } from '../../stores/suggest-edit-lodge-store';
import { SuggestEditMeetingStore } from '../../stores/suggest-edit-meeting-store';
import { SuggestEditContactStore } from '../../stores/suggest-edit-contact-store';

import { ConfigurationStore } from '../../stores/configuration-store';

import { LoginStore } from '../../stores/login-store';

import { LogVisitMeetingStore } from '../../stores/log-visit-meeting-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";


interface Props {
  nextStep: () => void;
  previousStep: () => void;
  suggestEditLodgeStep: () => void;
  membershipClaimStep: () => void;
  emergencyMeetingStep: () => void;
  logVisitMeetingStep: () => void;
  schedulePlannerStep: () => void;
  feedWallStep: () => void;
  profileStep: () => void;
  lodgeDetailsStore?: LodgeDetailsStore;
  suggestEditLodgeStore?: SuggestEditLodgeStore;
  suggestEditMeetingStore?: SuggestEditMeetingStore;
  suggestEditContactStore?: SuggestEditContactStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
  logVisitMeetingStore?: LogVisitMeetingStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  isFavorite: boolean;
}

@inject(
    'lodgeDetailsStore',
    'suggestEditLodgeStore',
    'suggestEditMeetingStore',
    'suggestEditContactStore',
    'loginStore',
    'configurationStore',
    'logVisitMeetingStore',
)
@observer
export class LodgeDetails extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      isFavorite: false
    };

    this.scrollToTop();
  }

  /*componentDidMount() {
    this.scrollToTop();
  }*/

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClearingOfEdit = async () => {
    //this.props.suggestEditLodgeStore.clearLodgeInput();
    //this.props.suggestEditMeetingStore.clearMeetingInput();
    //this.props.suggestEditContactStore.clearContactInput();
    this.props.suggestEditLodgeStep();
    //await this.props.configurationStore.getAllAddresses(); // you shouldn't have to reload them
  };

  checkTimeWithinRangeEveryX = lodgeDetailsStore => {
    let result = false;
    try {
      const givenDateTime = new Date(lodgeDetailsStore.todaysMeetingTime);
      const currentDateTime = new Date();
      // Calculate the difference in milliseconds between the given date and time and the current date and time
      const timeDifference = Math.abs(givenDateTime.getTime() - currentDateTime.getTime());
      console.log(timeDifference);
      if (timeDifference <= 3600000) {
        // 3600000 milliseconds = 1 hour
        //console.log("The given date and time is within one hour of the current date and time.");
        result = true;
      } else {
        //console.log("The given date and time is NOT within one hour of the current date and time.");
        result = false;
      }
    } catch (error) {
      console.log('Invalid date and time: ' + error.message);
    }
    //return result;
    lodgeDetailsStore.canCheckIn = result;
  };

  checkIfToday = the_date => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday =
        givenDate.getFullYear() === currentDate.getFullYear() &&
        givenDate.getMonth() === currentDate.getMonth() &&
        givenDate.getDate() === currentDate.getDate();

    return isToday;
  };

  checkIfFuture = the_date => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date);

    // Get the current date
    const currentDate = new Date();

    // Compare givenDate and currentDate
    const isFuture = givenDate > currentDate;

    return isFuture ? 'future' : 'past';
  };

  checkTimeWithinRange = meetings => {
    let result = false;
    for (let i = 0; i < meetings.length; i++) {
      const meeting = meetings[i];
      try {
        let g =
            meetingStringToDate(meeting.position, meeting.day, meeting.month, meeting.type).split(' - ')[0] +
            'T' +
            meeting.time;
        console.log(g);

        const givenDateTime = new Date(g);

        const currentDateTime = new Date();

        // Calculate the difference in milliseconds between the given date and time and the current date and time
        const timeDifference = Math.abs(givenDateTime.getTime() - currentDateTime.getTime());
        console.log(timeDifference);
        //if (timeDifference <= 3600000) { // 3600000 milliseconds = 1 hour
        if (timeDifference <= 36000000) {
          // 36000000 milliseconds = 10 hours
          //console.log("The given date and time is within one hour of the current date and time.");
          result = true;
          break;
        } else {
          //console.log("The given date and time is NOT within one hour of the current date and time.");
          result = false;
        }
      } catch (error) {
        console.log('Invalid date and time: ' + error.message);
      }
    }
    return result;
  };

  testTime = () => {
    const timer = setInterval(() => {
      this.checkTimeWithinRangeEveryX(this.props.lodgeDetailsStore);
    }, 5000); // Adjust the interval as needed (in milliseconds)

    return timer;
  };

  checkIfMeetingNotLogged = (the_date, lodge_id, visits) => {
    return !visits.some(visit => {
      return visit.datetime.includes(the_date) && visit.lodge_id === lodge_id;
    });
  };

  getDescription = (type, id) => {
    document.getElementById(type + 'description_cut_' + id).style.display = 'none';
    document.getElementById(type + 'description_full_' + id).style.display = 'inherit';
    document.getElementById(type + 'image_' + id).classList.remove('post-restricted-height');
  };

  render() {
    if (!this.props.lodgeDetailsStore) {
      return;
    }

    const lodgeDetailsStore = this.props.lodgeDetailsStore;

    const suggestEditLodgeStore = this.props.suggestEditLodgeStore;
    suggestEditLodgeStore.setSuggestEditLodgeData(lodgeDetailsStore.lodgeInfo);

    const suggestEditMeetingStore = this.props.suggestEditMeetingStore;
    suggestEditMeetingStore.setSuggestEditMeetingData(lodgeDetailsStore.lodgeMeetingsTwo);

    const suggestEditContactStore = this.props.suggestEditContactStore;
    suggestEditContactStore.setSuggestEditContactData(lodgeDetailsStore.lodgeInfo);

    const loginStore = this.props.loginStore;

    const logVisitMeetingStore = this.props.logVisitMeetingStore;

    const configurationStore = this.props.configurationStore;

    // TODO: NB!! not sure what this is used for anymore
    let name_number = '';
    name_number =
        //'https://freemason-alpha.tempelhoffenterprises.com/freemason-travelers-guide/public/getCalendarForLodge/' +
        'https://api.freemason-alpha.com/ftg/public/getCalendarForLodge/' +
        lodgeDetailsStore.lodgeInput.name +
        '_' +
        lodgeDetailsStore.lodgeInput.number;
    //name_number = 'http://localhost:8000/getCalendarForLodge/'+lodgeDetailsStore.lodgeInput.name+'_'+lodgeDetailsStore.lodgeInput.number;

    if (this.props.lodgeDetailsStore.theTimer == null) {
      if (this.checkIfToday(lodgeDetailsStore.todaysMeetingTime.split('T')[0])) {
        this.props.lodgeDetailsStore.theTimer = this.testTime();
      }
    }

    return (
        <div>
          <Row>
            <Col sm='12'>
              {lodgeDetailsStore.lodgeInfo.map((lodge, index) => (
                  <div>
                    <h4>
                      <b>
                        <u>
                          {lodge.name} No. {lodge.number} {lodge.short_constitution}
                          <img
                              alt='logo'
                              className='orderlogo'
                              src={`star${lodge.isFavorite ? '_2.png' : '_1.png'}`}
                              height='40px'
                              onClick={async () => {
                                await this.props.lodgeDetailsStore.updateWatchList(lodge.id, !lodge.isFavorite);

                                lodge.isFavorite = !lodge.isFavorite;
                                this.setState({isFavorite: !this.state.isFavorite});
                              }}
                          />
                          <Button
                              className=''
                              color=''
                              onClick={async () => {
                                //this.props.nextStep();
                                //clearInterval(this.props.lodgeDetailsStore.theTimer);
                                //this.props.lodgeDetailsStore.theTimer = null;
                                //this.props.lodgeDetailsStore.todaysMeetingTime = '';
                                // "https://freemason-alpha.com/ftg?lodgeqrcode="lodge.qrCode"

                                // TODO: NB!!! update to new ftg.freemason-alpha.com/explore
                                // verify this is working as expected
                                navigator.clipboard.writeText(
                                    `https://ftg.freemason-alpha.com/${
                                        lodge.qrCode != '' ? 'explore?lodgeqrcode=' + lodge.qrCode : ''
                                    }`,
                                );
                                toast.info('Lodge share link has been copied. You can now paste link to share.');
                              }}
                          >
                            <Icon id="link"></Icon>
                          </Button>
                        </u>
                      </b>
                    </h4>
                  </div>
              ))}
            </Col>
          </Row>

          <Card className='post-option-active'>
            <CardBody className='top-buttons'>
              <Row className='row-justified-and-centered'>
                <Col>
                  <div id='navigation_buttons'>
                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          if (this.props.lodgeDetailsStore.previousPage == '') {
                            this.props.previousStep();
                          }
                          else if (this.props.lodgeDetailsStore.previousPage == 'schedule-planner') {
                            this.props.lodgeDetailsStore.previousPage = '';
                            this.props.schedulePlannerStep();
                          }
                          else if (this.props.lodgeDetailsStore.previousPage == 'feed-wall') {
                            this.props.lodgeDetailsStore.previousPage = '';
                            this.props.feedWallStep();
                          }
                          else if (this.props.lodgeDetailsStore.previousPage == 'profile') {
                            this.props.lodgeDetailsStore.previousPage = '';
                            this.props.profileStep();
                          }
                          clearInterval(this.props.lodgeDetailsStore.theTimer);
                          this.props.lodgeDetailsStore.theTimer = null;
                          this.props.lodgeDetailsStore.todaysMeetingTime = '';
                        }}
                    >
                      <Icon id="back" text="Back"></Icon>
                    </Button>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          this.props.nextStep();
                          clearInterval(this.props.lodgeDetailsStore.theTimer);
                          this.props.lodgeDetailsStore.theTimer = null;
                          this.props.lodgeDetailsStore.todaysMeetingTime = '';
                        }}
                    >
                      <Icon id="contact" text="Contact"></Icon>
                    </Button>

                    {!lodgeDetailsStore.isMember ? (
                        <Button
                            className=''
                            color=''
                            onClick={async () => {
                              if (window.confirm('Are you sure you wish to claim membership to this lodge?')) {
                                this.props.membershipClaimStep();
                                clearInterval(this.props.lodgeDetailsStore.theTimer);
                                this.props.lodgeDetailsStore.theTimer = null;
                                this.props.lodgeDetailsStore.todaysMeetingTime = '';
                              }
                            }}
                        >
                          <Icon id="claim-membership" text="Claim Membership"></Icon>
                        </Button>
                    ) : (
                        ''
                    )}

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          this.handleClearingOfEdit();
                          clearInterval(this.props.lodgeDetailsStore.theTimer);
                          this.props.lodgeDetailsStore.theTimer = null;
                          this.props.lodgeDetailsStore.todaysMeetingTime = '';
                          configurationStore.filterDescriptions(
                              configurationStore.descriptionResults,
                              lodgeDetailsStore.lodgeInfo[0].degree,
                          );
                          configurationStore.filterAddresses(
                              configurationStore.addressResults,
                              lodgeDetailsStore.lodgeInfo[0].country,
                          );
                          this.props.emergencyMeetingStep();
                        }}
                    >
                      <Icon id="emergency-meeting" text="Emergency Meeting"></Icon>
                    </Button>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          this.handleClearingOfEdit();
                          clearInterval(this.props.lodgeDetailsStore.theTimer);
                          this.props.lodgeDetailsStore.theTimer = null;
                          this.props.lodgeDetailsStore.todaysMeetingTime = '';
                        }}
                    >
                      <Icon id="suggest-edit" text="Suggest an Edit"></Icon>
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className='post-option-active'>
            <CardBody>
              <Row className='row-justified-and-centered'>
                <Col>
                  <img className='lodge-details-image' src={'lodges/' + lodgeDetailsStore.lodgeInfo[0].image} />
                </Col>
              </Row>
              <h5>{lodgeDetailsStore.isDetailsLoading ? 'Loading...' : 'Details:'}</h5>
              <div>
                {lodgeDetailsStore.lodgeInfo.map((lodge, index) => (
                    <div>
                      <span className='lodge-details-heading'>Address:</span>
                      <br />
                      <span>
                    <a href={`http://maps.google.com/maps?q=${lodge.gps !== '' ? lodge.gps : ''}`} target='_blank'>
                      {lodge.address}
                    </a>
                  </span>
                      <br />
                      <span className='lodge-details-heading'>Meetings:</span>
                      <br />
                      <span>
                    {lodge.meeting_time} {lodge.meeting_date}
                  </span>
                      <br />
                      <span className='lodge-details-heading'>Installation:</span>
                      <br />
                      {lodge.installation_time} {lodge.installation_date}
                      {/*<br/>
          Contact:
          <br/>
          {lodge.contact} <a href={`tel:${lodge.contact_number}`} target="_blank">{lodge.contact_number}</a>*/}
                      <br />
                      <span className='lodge-details-heading'>Website:</span>
                      <br />
                      <a href={lodge.website} target='_blank'>
                        {lodge.website}
                      </a>
                      {/*
          <br/>
          Email:
          <br/>
          {lodge.email}
          */}
                      <br />
                      <span className='lodge-details-heading'>Constitution:</span>
                      <br />
                      <span>
                    {getConstitutionWebsiteFromConfigsById(
                        lodge.constitution,
                        configurationStore.constitutionResults,
                    ) != '' ? (
                        <a
                            href={getConstitutionWebsiteFromConfigsById(
                                lodge.constitution,
                                configurationStore.constitutionResults,
                            )}
                            target='_blank'
                        >
                          {getConstitutionFromConfigsById(lodge.constitution, configurationStore.constitutionResults)}
                        </a>
                    ) : (
                        getConstitutionFromConfigsById(lodge.constitution, configurationStore.constitutionResults)
                    )}
                  </span>
                      <br />
                      <span className='lodge-details-heading'>Order:</span>
                      <br />
                      {degreeFromIdNoCollective(lodge.degree)}
                      <br />
                      <span className='lodge-details-heading'>Language:</span>
                      <br />
                      {getLanguageFromConfigsById(lodge.language, configurationStore.languageResults)}
                      <br />
                      <span className='lodge-details-heading'>State:</span>
                      <br />
                      {lodge.active}
                      <br />
                      {/*<Row>
            <Col>
              <div className="row-justified-and-centered-flex">
                <QRCodeCanvas
                value={'https://freemason-alpha.com/ftg?lodgeqrcode=' + encodeURI(btoa(lodge.name+"_"+lodge.number+"_"+lodge.degree))}
                />
              </div>
            </Col>
          </Row>
          <Row className='row-justified-and-centered'>
            <Col>
                <span>
                  {encodeURI(btoa(lodge.name+"_"+lodge.number+"_"+lodge.degree))}
                </span>
            </Col>
          </Row>*/}
                    </div>
                ))}
              </div>
              <hr />
              <h5>{lodgeDetailsStore.isMeetingsLoading ? '' : 'Meetings:'}</h5>
              <div>
                {lodgeDetailsStore.lodgeMeetings.map((meeting, index) => (
                    <div>
                  <span className={`date${this.checkIfToday(meeting.date) ? '-today' : ''}`}>
                    {/*lodgeDetailsStore.canCheckIn*/
                      /*loginStore.isLoggedIn*/
                      this.checkIfMeetingNotLogged(
                          meeting.date,
                          lodgeDetailsStore.lodgeInfo[0].id,
                          loginStore.visitationMeetings,
                      ) ? (
                          <Button
                              color=''
                              onClick={async () => {
                                if (window.confirm('Are you sure you want to log attendance for this meeting?')) {
                                  //this.props.logVisitMeetingStep();
                                  logVisitMeetingStore.logAttendance(
                                      meeting.date,
                                      meeting.time,
                                      meeting.meeting_type_id,
                                      lodgeDetailsStore.lodgeInfo[0].id,
                                      meeting.meeting_description_id,
                                      meeting.meeting_description_extra,
                                      lodgeDetailsStore.lodgeInfo[0].address_id,
                                      loginStore,
                                      this.checkIfFuture(meeting.date + ' ' + meeting.time),
                                      {
                                        name: lodgeDetailsStore.lodgeInfo[0].name,
                                        number: lodgeDetailsStore.lodgeInfo[0].number,
                                        address: lodgeDetailsStore.lodgeInfo[0].address,
                                        meeting_description: meeting.meeting_description,
                                      },
                                  );
                                  clearInterval(this.props.lodgeDetailsStore.theTimer);
                                  this.props.lodgeDetailsStore.theTimer = null;
                                  this.props.lodgeDetailsStore.todaysMeetingTime = '';
                                }
                              }}
                              style={{ background: 'none', border: 'none', padding: '0px', margin: '0px' }}
                          >
                            <Icon id="attend"></Icon>
                          </Button>
                      ) : (
                          ''
                      )}
                    {meeting.date}@{meeting.time} - {meeting.meeting_type}{' '}
                    {meeting.meeting_type == 'Meeting' || meeting.meeting_type == 'Emergency Meeting'
                        ? '(' + meeting.meeting_description + ')'
                        : ''}
                  </span>
                      {meeting.meeting_description_id == 9 ? ' - ' + meeting.meeting_description_extra : ''}
                      <br />
                      <span className='small-font-italic'>
                    {this.checkIfFuture(meeting.date + ' ' + meeting.time) == 'future'
                        ? meeting.attendance == 0
                            ? 'be the first to log attendance.'
                            : meeting.attendance == 1
                                ? meeting.attendance + ' person is interested in attending this meeting'
                                : meeting.attendance + ' people are interested in attending this meeting'
                        : meeting.attendance == 1
                            ? meeting.attendance + ' person attended this meeting'
                            : meeting.attendance == 0
                                ? ''
                                : meeting.attendance + ' people attended this meeting'}
                  </span>

                      {meeting.meeting_type == 'Emergency Meeting' ? (
                          <Button
                              color='primary'
                              onClick={async () => {
                                if (window.confirm('Are you sure you wish to vote for removal of this emergency meeting?')) {
                                  if (
                                      await lodgeDetailsStore.suggestEmergencyMeetingRemoval(
                                          lodgeDetailsStore.lodgeInfo[0].id, //lodge.id,
                                          meeting.date,
                                          meeting.time,
                                          loginStore,
                                      )
                                  ) {
                                    toast.success('Your suggestion has been logged!');
                                  } else {
                                    toast.warning('Your suggestion could not be logged!');
                                  }
                                }
                              }}
                              style={{ background: 'none', border: 'none', padding: '0px', margin: '0px' }}
                          >
                            <Icon id="trash"></Icon>
                          </Button>
                      ) : (
                          ''
                      )}
                    </div>
                ))}
                {lodgeDetailsStore.lodgeMeetings.length < 1 ? (
                    ''
                ) : (
                    <div className='small-font'>
                      <br />
                      <Icon id="calendar"></Icon>
                      <a href={name_number} target='_blank'>
                        Download Calendar
                      </a>
                    </div>
                )}
              </div>
            </CardBody>
          </Card>

          {/* insert user posts attributed to this lodge */}
          <div>
            {lodgeDetailsStore.feed.length > 0 ? (
                <div>
                  {lodgeDetailsStore.feed.map((post, index) => (
                      <Card className='post-option-active'>
                        <CardBody>
                          <Row>
                            <Col className='col-sm-1 post_header_logo'>
                              <img
                                  src='logo.png'
                                  alt='logo'
                                  height='40px'
                                  onClick={() => {
                                    //this.props.profileStep();
                                  }}
                              />
                            </Col>
                            <Col className='col-sm-11 post_header_title pointing-cursor'>
                        <span
                            onClick={() => {
                              //this.props.profileStep();
                            }}
                        >
                          {/*loginStore.username*/}
                          {post.title}
                        </span>
                            <br/>
                              <Icon id="clock"></Icon>
                              <span className='x-small-font'> {post.date_time} </span>
                              {post.post_type == 1 ? (
                                  <Icon id="globe"></Icon>
                              ) : (
                                  ''
                              )}
                              {post.post_type == 1 ? (
                                  <span className='x-small-font'>
                            <a
                                href={`http://maps.google.com/maps?q=${post.gps !== '' ? post.gps : ''}`}
                                target='_blank'
                            >
                              {post.address}
                            </a>
                          </span>
                              ) : (
                                  ''
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {post.description.length > 100 ? (
                                  <span id={`meeting_description_cut_${index}`} className='show_post_description'>
                            {post.description.substring(0, 200) + '...'}
                                    <Button
                                        className='small-font'
                                        color='#007bff'
                                        onClick={() => {
                                          this.getDescription('meeting_', index);
                                        }}
                                    >
                              see more
                            </Button>
                          </span>
                              ) : (
                                  ''
                              )}
                              <span
                                  id={`meeting_description_full_${index}`}
                                  className={`${
                                      post.description.length < 100
                                          ? 'show_post_description pre pre-code'
                                          : 'hide_post_description pre pre-code'
                                  }`}
                              >
                          {post.description}
                        </span>
                            </Col>
                          </Row>
                          {post.post_type == 1 ? (
                              <Row>
                                <Col sm='12' className='post-restricted-height'>
                                  <img className='feed-wall-image' src={post.image} />
                                </Col>
                              </Row>
                          ) : post.image !== '' ? (
                              <Row>
                                <Col sm='12' className='post-restricted-height'>
                                  {post.image.includes('.pdf') ? (
                                      <div>
                                        <embed
                                            src={process.env.REACT_APP_API_URL + post.image + '#toolbar=0'}
                                            type='application/pdf'
                                            className='width-height-hundred'
                                        />
                                        <br />
                                        <iframe
                                            src={process.env.REACT_APP_API_URL + post.image + '#toolbar=0'}
                                            className='width-height-hundred'
                                        />
                                      </div>
                                  ) : post.image.includes('.mp3') ? (
                                      <audio controls>
                                        <source src={process.env.REACT_APP_API_URL + post.image} type='audio/mp3' />
                                        Your browser does not support the audio tag.
                                      </audio>
                                  ) : post.image.includes('.mp4') ? (
                                      <video controls className='video-container'>
                                        <source src={process.env.REACT_APP_API_URL + post.image} type='video/mp4' />
                                        Your browser does not support the video tag.
                                      </video>
                                  ) : (
                                      <img className='feed-wall-image' src={process.env.REACT_APP_API_URL + post.image} />
                                  )}
                                </Col>
                              </Row>
                          ) : (
                              ''
                          )}
                        </CardBody>
                      </Card>
                  ))}
                </div>
            ) : (
                'Lodge has no posts yet'
            )}
          </div>
        </div>
    );
  }
}

export default LodgeDetails;
