// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  getShortConstitutionFromConfigsById,
  degreeFromIdNoCollective,
} from '../../helpers/utilities';

import { ContributeStore } from '../../stores/contribute-store';
import { ContributeDetailsStore } from '../../stores/contribute-details-store';
import { LoginStore } from '../../stores/login-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  addLodgeStep: () => void;
  thankYouStep: () => void;
  searchStep: () => void;
  feedWallStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  contributeStore?: ContributeStore;
  contributeDetailsStore?: ContributeDetailsStore;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  page: number;
}

@inject('contributeStore', 'contributeDetailsStore', 'loginStore', 'thankYouStore', 'configurationStore')
@observer
export class Contribute extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      page: 0,
    };

  }

  componentDidMount() {
    //this.updateCount();
  }

  //updateCount() {
  //  this.updateLodgesCount();
  //}

  //updateLodgesCount() {
  //  this.setState({lodge_edits_count: this.countSuggestionType(this.props.contributeStore.editResults, "3") + this.countSuggestionType(this.props.contributeStore.editResults, "4")});
  //}

  countSuggestionType = (edits: any[], type: string): number => {
    let res = 0;
    edits.forEach((edit) => {
      if (edit.suggested_edit.suggestion_type == type) {
        res += 1;
      }
    });
    return res;
  };

  render() {
    if (!this.props.contributeStore) {
      return;
    }

    if(!this.props.loginStore.isLoggedIn)
    {
      this.props.loginStep();
    }

    const contributeStore = this.props.contributeStore;
    const loginStore = this.props.loginStore;
    const thankYouStore = this.props.thankYouStore;
    const contributeDetailsStore = this.props.contributeDetailsStore;
    const configurationStore = this.props.configurationStore;



    try {
      thankYouStore.typeOfThankYou = 'vote';
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Contributions</u></h4>
          </Col>
        </Row>
        <Card className='post-option-active'>
          <CardBody className='top-buttons'>
            <Row className='row-justified-and-centered'>
              <Col>
                <div id='navigation_buttons'>
                  <Button
                      className=''
                      color=''
                      onClick={async () => {
                        this.props.feedWallStep();
                      }}
                  >
                    <Icon id="back" text="Back"></Icon>
                  </Button>

                  <Button
                      className=''
                      color=''
                      onClick={async () => {
                        this.props.searchStep();
                      }}
                  >
                    <Icon id="search" text="Search"></Icon>
                  </Button>

                  <Button
                      className=''
                      color=''
                      onClick={async () => {

                      }}
                  >
                    <Icon id="contribute" text="Contribute more"></Icon>
                  </Button>

                  <Button
                      className=''
                      color=''
                      onClick={async () => {

                      }}
                  >
                    <Icon id="help" text="Help"></Icon>
                  </Button>

                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <hr/>
        <Card className="post-option-active">
          <CardBody className="top-buttons">
            <Row className="row-justified-and-centered navigation-toolbar-step">
              <Col>
                <div id='navigation_buttons'>
                  <Button className="" color="" onClick={async () => {
                    contributeStore.page = 0;
                    this.setState({page: 0});
                  }}>
                    <Icon id="lodge" text="Lodges "></Icon>
                    <span className="small-font">({this.countSuggestionType(this.props.contributeStore.editResults, "3") + this.countSuggestionType(this.props.contributeStore.editResults, "4")})</span>
                  </Button>

                  <Button className="" color="" onClick={async () => {
                    contributeStore.page = 1;
                    this.setState({page: 1});
                  }}>
                    <Icon id="membership" text="Membership "></Icon>
                    <span className="small-font">({this.countSuggestionType(contributeStore.editResults, "5")})</span>
                  </Button>

                  <Button className="" color="" onClick={async () => {
                    contributeStore.page = 2;
                    this.setState({page: 2});
                  }}>
                    <Icon id="attendance" text="Emergency Meetings "></Icon>
                    <span className="small-font">({this.countSuggestionType(contributeStore.editResults, "6")})</span>
                  </Button>

                  <Button className="" color="" onClick={async () => {
                    contributeStore.page = 3;
                    this.setState({page: 3});
                  }}>
                    <Icon id="globe" text="Addresses "></Icon>
                    <span className="small-font">({this.countSuggestionType(contributeStore.editResults, "7")})</span>
                  </Button>

                  <Button className="" color="" onClick={async () => {
                    contributeStore.page = 4;
                    this.setState({page: 4});
                  }}>
                    <Icon id="events" text="Events "></Icon>
                    <span className="small-font">({this.countSuggestionType(contributeStore.editResults, "8")})</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
          <hr/>
          {this.state.page == 0 ? (
          <div>

          <h6>New lodges:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "3"
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status == 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  this.props.contributeDetailsStore.setLodgeInfo(edit.lodge.name, edit.lodge.number, edit.lodge.degree, 0, edit);
                  // this can not fetch an existing lodges info as it is a new lodge
                  this.props.nextStep();

                  this.props.setLoadingFalse();


                }}
                >
                <CardBody>
                  {edit.lodge.name + ' No. ' + edit.lodge.number + ' ' + getShortConstitutionFromConfigsById(edit.lodge.constitution, configurationStore.constitutionResults)}
                </CardBody>
              </Card>
              : ''
            ))}
          </div>
          <br/>
          <h6>Edits:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "4"
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults)}
                </CardBody>
              </Card>
              : ''
            ))}
          </div>

          </div>
          )
          :
          this.state.page == 1 ? (
          <div>

          <h6>Claims:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "5" && edit.user.action == 0
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMembershipInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  <b>{edit.user.name}</b> { ' claims membership to ' } <b>{edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>
          <br/>
          <h6>Removals:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "5" && edit.user.action == 1
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMembershipInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  Remove <b>{edit.user.name}</b> from <b>{edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>

          </div>
          )
          :
          this.state.page == 2 ? (
          <div>

          <h6>Proposals:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "6" && edit.emergency_meeting.action == 0
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMeetingInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)} on <b>{edit.emergency_meeting.date}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>
          <br/>
          <h6>Removals:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "6" && edit.emergency_meeting.action == 1
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMeetingInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)} on <b>{edit.emergency_meeting.date}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>

          </div>
          )
          :
          this.state.page == 3 ? (
          <div>
            <h6>New locations:</h6>
            <div>
              {contributeStore.editResults.map((edit, index) => (
                edit.suggested_edit.suggestion_type == "7"
                  ?
                <Card className={`edit-option${
                  edit.suggested_edit.user_vote_status == 'no_vote'
                    ? '-active'
                    : ''
                  }`} onClick={async () => {
                    this.props.setLoadingTrue();

                    this.props.contributeDetailsStore.clearLodgeInfo();
                    this.props.contributeDetailsStore.setLocationInfo(edit);
                    // this can not fetch an existing lodges info as it is a new lodge
                    this.props.nextStep();

                    this.props.setLoadingFalse();
                  }}
                  >
                  <CardBody>
                    {edit.location.name}
                  </CardBody>
                </Card>
                : ''
              ))}
            </div>
          </div>
          )
          :
          this.state.page == 4 ? (
          <div>
            <h6>Socials:</h6>
            <div>
              {contributeStore.editResults.map((edit, index) => (
                edit.suggested_edit.suggestion_type == "8"
                  ?
                <Card className={`edit-option${
                  edit.suggested_edit.user_vote_status == 'no_vote'
                    ? '-active'
                    : ''
                  }`} onClick={async () => {
                    this.props.setLoadingTrue();

                    this.props.contributeDetailsStore.clearLodgeInfo();
                    this.props.contributeDetailsStore.setLocationInfo(edit);
                    // this can not fetch an existing lodges info as it is a new lodge
                    this.props.nextStep();

                    this.props.setLoadingFalse();
                  }}
                  >
                  <CardBody>
                    {edit.social.title}
                  </CardBody>
                </Card>
                : ''
              ))}
            </div>
          </div>
          )
          :
          ''
          }

      </div>
    );
  }
}

export default Contribute;
