import { observable, action } from 'mobx';
import { RaffleDraw } from '../domain/raffle-draw';
import api from '../api';

export class RaffleDrawStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  @observable entrants: any = [];
  @observable colors: any = [];

  constructor() {}

  setEntrants = (data) => {
    this.entrants = data;
    this.setColors(data.length);
  }

  setColors = (length) => {
    for (let i = 0; i < length; i++) {
      //console.log(`Element at index ${i}: ${myArray[i]}`);
      this.colors.push('#0c07a8cf');
    }
  }

}

export const raffleDrawStore = new RaffleDrawStore();
(window as any).raffleDrawStore = raffleDrawStore;
