// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { ImpromptuMeetingStore } from '../../stores/impromptu-meeting-store';
import { ImpromptuMeetingSetupStore } from '../../stores/impromptu-meeting-setup-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  raffleNameStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  feedWallStep: () => void;
  impromptuMeetingSetupStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  impromptuMeetingStore?: ImpromptuMeetingStore;
  impromptuMeetingSetupStore?: ImpromptuMeetingSetupStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('impromptuMeetingStore', 'impromptuMeetingSetupStore', 'contributeStore', 'loginStore',)
@observer
export class ImpromptuMeeting extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };

    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  addDurationToStartDT = (date, time, duration) => {
    let new_date_time = '';

    const [year, month, day] = date.split('-').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    // Creating a Date object with the parsed components
    const specificDate = new Date(year, month - 1, day, hours, minutes);
    //console.log("Specific date/time:", specificDate);

    // Adding minutes to the specific date/time
    const minutesToAdd = duration;
    specificDate.setMinutes(specificDate.getMinutes() + minutesToAdd);
    //console.log("Specific date/time:", specificDate);

    new_date_time = /*specificDate.getFullYear() +
      '-' +
      String(specificDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(specificDate.getDate()).padStart(2, '0') +
      ' ' +*/
      String(specificDate.getHours()).padStart(2, '0') +
      ':' +
      String(specificDate.getMinutes()).padStart(2, '0');

    return new_date_time;
  };

  checkIfToday = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday = (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );

    return isToday;
  }

  render() {
    if (!this.props.impromptuMeetingStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const impromptuMeetingStore = this.props.impromptuMeetingStore;
    const impromptuMeetingSetupStore = this.props.impromptuMeetingSetupStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Masonic Events</u></h4>
          </Col>
        </Row>
        <br />

         <Card className="post-option-active">
         <CardBody className="top-buttons">
           <Row className="row-justified-and-centered navigation-toolbar-step">
             <Col>
               <div id='navigation_buttons'>
                 <Button
                     className=''
                     color=''
                     onClick={async () => {
                       this.props.feedWallStep();
                     }}
                 >
                   <Icon id="back" text="Back"></Icon>
                 </Button>

                 <Button className="create-social-step" color="" onClick={async () => {
                   this.props.impromptuMeetingSetupStep();
                   impromptuMeetingSetupStore.checkIfMobileDevice();
                   await impromptuMeetingSetupStore.setCoords();
                 }}>
                   <Icon id="create-event" text="Create Event"></Icon>
                 </Button>
               </div>
             </Col>
           </Row>
         </CardBody>
         </Card>


        <h5>{impromptuMeetingStore.isLoading ? 'Loading...' : 'Current listed socials: ('+impromptuMeetingStore.meetingResults.length+')'}</h5>
        <div>
        {impromptuMeetingStore.meetingResults.map((meeting, index) => (
          <Card className='post-option-active'>
            <CardBody>
              <b>{meeting.title}</b>
              <hr/>
              <span className="small-font">
              Date: {meeting.date}
              </span>
              <br/>
              <span className="small-font">
              Time: {meeting.time}{this.checkIfToday(meeting.date) ? (<span className="small-font"> till {this.addDurationToStartDT(meeting.date, meeting.time, meeting.duration)}</span>): ''}
              </span>
              <br/>
              <br/>
              <div className="pre pre-code">{meeting.description}</div>
              <span className="small-font">
              <i>Location: <a href={`http://maps.google.com/maps?q=${meeting.latitude},${meeting.longitude}`} target="_blank">{meeting.address}</a></i>
              </span>
              <Row>
                <Col sm="12" className="post-restricted-height">
                  <img className='feed-wall-image' src={process.env.REACT_APP_API_URL + meeting.image}/>
                </Col>
              </Row>
              <br/>
              <br/>
              <span className="small-font">
              <i>Contact: {meeting.user}</i>
              <br/>
              <i>Tel: <a href={`tel:${meeting.contact_number}`} target="_blank">{meeting.contact_number}</a></i>
              <br/>
              <i>Email: <a href={`mailto:${meeting.email}`} target="_blank">{meeting.email}</a></i>
              </span>
            </CardBody>
          </Card>
        ))}
        </div>

      </div>
    );
  }
}

export default ImpromptuMeeting;
