// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { RegistrationTwoStore } from '../../stores/registration-two-store';
import { RegistrationPreStore } from '../../stores/registration-pre-store';
import { RegistrationStore } from '../../stores/registration-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  addLodgeStep: () => void;
  registrationTwoStore?: RegistrationTwoStore;
  registrationPreStore?: RegistrationPreStore;
  registrationStore?: RegistrationStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'registrationTwoStore',
  'registrationPreStore',
  'registrationStore',
  'thankYouStore',
  'contributeStore'
)
@observer
export class RegistrationTwo extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.registrationTwoStore) {
      return;
    }

    const registrationTwoStore = this.props.registrationTwoStore;
    const registrationPreStore = this.props.registrationPreStore;
    const registrationStore = this.props.registrationStore;

    //console.log("------- registrationStore.lodgeResults -------");
    //console.log(registrationStore.lodgeResults);
    //console.log("---------------------------------------");

    const registrationInput = registrationTwoStore.registrationInput;
    const validationResult = registrationTwoStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Lodge Details</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextInput
              id="lodge_name"
              label="Lodge Name"
              value={registrationInput.lodge_name}
              onChange={e =>
                (registrationInput.lodge_name = e.target.value)
              }
              invalid={!!validationResult.lodge_name}
              validationMessage={validationResult.lodge_name}
            />
          </Col>
          <Col>
            <TextInput
              id="lodge_number"
              label="Lodge Number"
              value={registrationInput.lodge_number}
              onChange={e =>
                (registrationInput.lodge_number = e.target.value)
              }
              invalid={!!validationResult.lodge_number}
              validationMessage={validationResult.lodge_number}
            />
          </Col>
        </Row>

        <div id='navigation_buttons'>
          <Button color="primary" onClick={async () => {
            registrationTwoStore.validate();
            const success = registrationTwoStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            if (success) {
              if(await registrationTwoStore.registerUser(registrationPreStore, registrationStore)) {
                toast.success("Confirmation Code Sent");
                this.props.nextStep();
              }
              else {
                toast.error("Failed to register user!");
              }
            }
          }}>
            Next
          </Button>
          <Button color="" onClick={this.props.previousStep}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Back
          </Button>
        </div>
      </div>
    );
  }
}

export default RegistrationTwo;
