// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { observer, inject } from 'mobx-react';

import { AddContactStore } from '../../stores/add-contact-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";



interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  addContactStore?: AddContactStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('addContactStore')
@observer
export class AddContact extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.addContactStore) {
      return;
    }

    const addContactStore = this.props.addContactStore;
    // console.log("------- addContactStore.lodgeResults -------");
    // console.log(addContactStore.lodgeResults);
    // console.log("---------------------------------------");

    const contactInput = addContactStore.contactInput;
    const validationResult = addContactStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Add Contact</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <Row>
          <Col sm='2'>
            <TextInput
              id="title"
              label="Title"
              value={contactInput.title}
              onChange={e =>
                (contactInput.title = e.target.value)
              }
              invalid={!!validationResult.title}
              validationMessage={validationResult.title}
            />
          </Col>
          <Col>
            <TextInput
              id="name"
              label="Name"
              value={contactInput.name}
              onChange={e =>
                (contactInput.name = e.target.value)
              }
              invalid={!!validationResult.name}
              validationMessage={validationResult.name}
            />
          </Col>
          <Col>
            <TextInput
              id="surname"
              label="Surname"
              value={contactInput.surname}
              onChange={e =>
                (contactInput.surname = e.target.value)
              }
              invalid={!!validationResult.surname}
              validationMessage={validationResult.surname}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {/*<TextInput
              id="contact_number"
              label="Contact Number"
              value={contactInput.contact_number}
              onChange={e =>
                (contactInput.contact_number = e.target.value)
              }
              invalid={!!validationResult.contact_number}
              validationMessage={validationResult.contact_number}
            />*/}
            <span>Contact Number</span>
            <PhoneInput
              defaultCountry="za"
              value={contactInput.contact_number}
              onChange={(phone) => (contactInput.contact_number = phone)}
            />
          </Col>
          <Col>
            {/*<TextInput
              id="contact_number_second"
              label="Backup Contact Number"
              value={contactInput.contact_number_second}
              onChange={e =>
                (contactInput.contact_number_second = e.target.value)
              }
              invalid={!!validationResult.contact_number_second}
              validationMessage={validationResult.contact_number_second}
            />*/}
            <span>Backup Contact Number</span>
            <PhoneInput
              defaultCountry="za"
              value={contactInput.contact_number_second}
              onChange={(phone) => (contactInput.contact_number_second = phone)}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextInput
              id="website"
              label="Website"
              value={contactInput.website}
              onChange={e =>
                (contactInput.website = e.target.value)
              }
              invalid={!!validationResult.website}
              validationMessage={validationResult.website}
            />
          </Col>
          <Col>
            <TextInput
              id="email"
              label="Email"
              value={contactInput.email}
              onChange={e =>
                (contactInput.email = e.target.value)
              }
              invalid={!!validationResult.email}
              validationMessage={validationResult.email}
            />
          </Col>
        </Row>

        </CardBody>
        </Card>


        <Button
        color=""
        onClick={() => {
          addContactStore.validate();
          const success = addContactStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            this.props.nextStep();
          }
        }}
        >
          <Icon id="continue" text="Next"></Icon>
        </Button>
        <Button color="" onClick={this.props.previousStep}>
          <Icon id="back" text="Back"></Icon>
        </Button>
      </div>
    );
  }
}

export default AddContact;
