// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { LogVisitMeetingStore } from '../../stores/log-visit-meeting-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';


export const times = [
  {
    value: '08:00',
    label: '08:00'
  },
  {
    value: '08:30',
    label: '08:30'
  },
  {
    value: '09:00',
    label: '09:00'
  },
  {
    value: '09:30',
    label: '09:30'
  },
  {
    value: '10:00',
    label: '10:00'
  },
  {
    value: '10:30',
    label: '10:30'
  },
  {
    value: '11:00',
    label: '11:00'
  },
  {
    value: '11:30',
    label: '11:30'
  },
  {
    value: '12:00',
    label: '12:00'
  },
  {
    value: '12:30',
    label: '12:30'
  },
  {
    value: '13:00',
    label: '13:00'
  },
  {
    value: '13:30',
    label: '13:30'
  },
  {
    value: '14:00',
    label: '14:00'
  },
  {
    value: '14:30',
    label: '14:30'
  },
  {
    value: '15:00',
    label: '15:00'
  },
  {
    value: '15:30',
    label: '15:30'
  },
  {
    value: '16:00',
    label: '16:00'
  },
  {
    value: '16:30',
    label: '16:30'
  },
  {
    value: '17:00',
    label: '17:00'
  },
  {
    value: '17:30',
    label: '17:30'
  },
  {
    value: '18:00',
    label: '18:00'
  },
  {
    value: '18:30',
    label: '18:30'
  },
  {
    value: '19:00',
    label: '19:00'
  },
  {
    value: '19:30',
    label: '19:30'
  },
  {
    value: '20:00',
    label: '20:00'
  },
  {
    value: '20:30',
    label: '20:30'
  },
  {
    value: '21:00',
    label: '21:00'
  }
];
export const types = [
  {
    value: '1',
    label: 'Meeting'
  },
  {
    value: '2',
    label: 'Installation'
  }/*,
  {
    value: '3',
    label: 'Social'
  }*/
];

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  logVisitMeetingStore?: LogVisitMeetingStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('logVisitMeetingStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore', 'configurationStore')
@observer
export class LogVisitMeeting extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.logVisitMeetingStore) {
      return;
    }

    const logVisitMeetingStore = this.props.logVisitMeetingStore;

    const logVisitMeetingInput = logVisitMeetingStore.logVisitMeetingInput;
    const validationResult = logVisitMeetingStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;

    const configurationStore = this.props.configurationStore;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'log of attendance.';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Log Attendance</h1>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="3">
          <DateInput
              label="Date"
              invalid={!!validationResult.date}
              validationMessage={validationResult.date}
              value={logVisitMeetingInput.date}
              onChange={e => {
                logVisitMeetingInput.date = e.target.value;
              }}
              id="date"
            />
          </Col>
          <Col sm="3">
            <SelectInput
              id='time'
              label='Time'
              invalid={!!validationResult['time']}
              validationMessage={validationResult['time']}
              value={logVisitMeetingInput.time}
              onChange={e => {
                logVisitMeetingStore.logVisitMeetingInput.time = e.target.value;
              }}
              options={times}
            />
          </Col>
          <Col sm="3">
            <SelectInput
              id='type'
              label='Type'
              invalid={!!validationResult['type']}
              validationMessage={validationResult['type']}
              value={logVisitMeetingInput.type}
              onChange={e => {
                logVisitMeetingStore.logVisitMeetingInput.type = e.target.value;
              }}
              options={types}
            />
          </Col>
          <Col sm="6">
            <SelectInput
              id='description'
              label='Description'
              invalid={!!validationResult['description']}
              validationMessage={validationResult['description']}
              value={logVisitMeetingInput.description}
              onChange={e => {
                logVisitMeetingStore.logVisitMeetingInput.description = e.target.value;
              }}
              options={configurationStore.descriptionResults}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchableSelectInput
              id='address'
              label='Address'
              invalid={!!validationResult.address}
              validationMessage={validationResult.address}
              value={logVisitMeetingInput.address}
              onChange={(selectedOption) => {
                if (selectedOption != null) {
                  logVisitMeetingStore.logVisitMeetingInput.address = selectedOption.value;
                }
              }}
              options={configurationStore.addressResults}
            />
          </Col>
        </Row>


        <br/>
        <Button color="primary" onClick={async () => {
          //toast.info("TEST!");

          /*logVisitMeetingStore.validate();
          const success = logVisitMeetingStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');*/
          //if (success) {
            if (await logVisitMeetingStore.logAttendance(
                  logVisitMeetingInput.date,
                  logVisitMeetingInput.time,
                  logVisitMeetingInput.type,
                  lodgeDetailsStore.lodgeInfo[0].id,
                  logVisitMeetingInput.description,
                  logVisitMeetingInput.description_extra,
                  logVisitMeetingInput.address,
                  loginStore
                  ,''
                  ,{}
                )) {
                toast.success("Your attendance has been logged!");
                this.props.thankYouStep();
            }
            else {
              toast.warning("Could not log your attendance to the meeting!");
            }
          //}
        }}>
          Submit
        </Button>
        <Button color="" onClick={this.props.lodgeDetailsStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default LogVisitMeeting;
