import { observable, action } from 'mobx';
import { Configuration } from '../domain/configuration';
import api from '../api';
import {loginStore} from "./login-store";

export class ConfigurationStore {
  @observable isLoading = false;

  @observable addressResults: any = [];
  @observable constitutionResults: any = [];
  @observable countryResults: any = [];
  @observable degreeResults: any = [];
  @observable districtResults: any = [];
  @observable descriptionResults: any = [];
  @observable languageResults: any = [];

  @observable filteredDistricts: any = [];
  @observable filteredAddresses: any = [];
  @observable filteredDescriptions: any = [];
  @observable filteredConstitutions: any = [];


  constructor() {

  }

  getConfigurations = async () => {
    this.isLoading = true;
    let result = [];
    try {

        result = await api.getConfigurations([], loginStore.authToken);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getConfigurations =======');
      this.addressResults = result['addresses'];
      this.countryResults = result['countries'];
      this.districtResults = result['districts'];
      this.descriptionResults = result['descriptions'];
      this.degreeResults = result['degrees'];
      this.constitutionResults = result['constitutions'];
      this.languageResults = result['languages'];
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  getAllAddresses = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllAddresses([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllAddresses =======');
      this.addressResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setAddresses = () => {
    this.getAllAddresses();
    console.log('getting all addresses');
  };
  //========================

  getAllCountries = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllCountries([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllCountries =======');
      this.countryResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  getAllDistricts = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllDistricts([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllDistricts =======');
      this.districtResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  getAllConstitutions = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllConstitutions([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllConstitutions =======');
      this.constitutionResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  getAllDegrees = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllDegrees([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllDegrees =======');
      this.degreeResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  getAllDescriptions = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllDescriptions([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllDescriptions =======');
      this.descriptionResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };


  filterDistricts = (districts, country_id, constitution_id) => {
    this.filteredDistricts = [];
    console.log("districts");
    console.log(districts);
    districts.forEach(element => {
      if (element.country == country_id && element.constitution == constitution_id) {
        this.filteredDistricts.push(element);
      }
    });
    console.log(this.filteredDistricts);
  };

  filterAddresses = (addresses, country_id) => {
    this.filteredAddresses = [];
    addresses.forEach(element => {
      if (element.country == country_id) {
        this.filteredAddresses.push(element);
      }
    });
    console.log(this.filteredAddresses);
  };

  filterDescriptions = (descriptions, degree_id) => {
    this.filteredDescriptions = [];
    descriptions.forEach(element => {
      if (element.degree == degree_id) {
        this.filteredDescriptions.push(element);
      }
    });
    console.log(this.filteredDescriptions);
  };

  filterConstitutions = (constitutions, country_id) => {
    this.filteredConstitutions = [];
    constitutions.forEach(element => {
      if (element.country == country_id) {
        this.filteredConstitutions.push(element);
      }
    });
    console.log(this.filteredConstitutions);
  };

}

export const configurationStore = new ConfigurationStore();
(window as any).configurationStore = configurationStore;
