// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { ResetPasswordEmailStore } from '../../stores/reset-password-email-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput, PasswordInput } from '../inputs/text';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  loginStep: () => void;
  forgotPasswordStep: () => void;
  resetPasswordEmailStore?: ResetPasswordEmailStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  email: string;
}

@inject('loginStore', 'thankYouStore', 'contributeStore', 'resetPasswordEmailStore')
@observer
export class ResetPasswordEmail extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      email: ''
    };
  }

  render() {
    if (!this.props.resetPasswordEmailStore) {
      return;
    }

    const resetPasswordEmailStore = this.props.resetPasswordEmailStore;
    const resetPasswordEmailInput = resetPasswordEmailStore.resetPasswordEmailInput;
    const validationResult = resetPasswordEmailStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Reset Password</u></h4>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <TextInput
              id="username"
              label="Email"
              value={this.state.email}
              onChange={e => {
                  resetPasswordEmailInput.username = e.target.value;
                  this.setState({email: e.target.value});
                }
              }
              invalid={!!validationResult.username}
              validationMessage={validationResult.username}
            />
          </Col>
        </Row>

        <div id='navigation_buttons'>
          <Button color="" onClick={async () => {

            resetPasswordEmailStore.validate();
            const success = resetPasswordEmailStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            if (success) {
              toast.info("Confirming status");
              await resetPasswordEmailStore.confirmUser();
              if (resetPasswordEmailStore.isUser) {
                //toast.success("Welcome back!");
                //toast.success("Hi!!!");
                this.props.nextStep();
              } else {
                toast.error("An error has occured!");
              }
            }
          }}>
            <Icon id="continue" text="Next"></Icon>
          </Button>


          <Button color="" onClick={async () => {
            this.props.loginStep();
          }}>
            <Icon id="back" text="Back"></Icon>
          </Button>

        </div>
      </div>
    );
  }
}

export default ResetPasswordEmail;
