// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
//import WheelComponent from 'react-wheel-of-prizes'
import { observer, inject } from 'mobx-react';

import { RaffleDrawStore } from '../../stores/raffle-draw-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  raffleDrawStore?: RaffleDrawStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('raffleDrawStore', 'contributeStore', 'loginStore',)
@observer
export class RaffleDraw extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.raffleDrawStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const raffleDrawStore = this.props.raffleDrawStore;

    const segments = raffleDrawStore.entrants;/*[
      'better luck next time',
      'won 70',
      'won 10',
      'better luck next time',
      'won 2',
      'won uber pass',
      'better luck next time',
      'won a voucher'
    ];*/
    const segColors = raffleDrawStore.colors;/*[
      '#EE4040',
      '#F0CF50',
      '#815CD1',
      '#3DA5E0',
      '#34A24F',
      '#F9AA1F',
      '#EC3F3F',
      '#FF9000'
    ];*/

    const onFinished = (winner) => {
      console.log(winner);
      if (segments.length > 3 && winner != 'better luck next time') {
        let i = segments.indexOf(winner);
        console.log(i);
        segments.splice(i,1);
        segColors.splice(i,1);
      }
    }


    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Raffle Draw</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm="12">
            {/*<WheelComponent
              segments={segments}
              segColors={segColors}
              onFinished={(winner) => onFinished(winner)}
              primaryColor='black'
              contrastColor='white'
              buttonText='Spin'
              isOnlyOnce={false}
              size={290}
              upDuration={200}
              downDuration={800}
              fontFamily='Arial'
            />*/}
          </Col>
        </Row>


        <br/>

        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default RaffleDraw;
