import { observable, action } from 'mobx';
import { CreditTransferInitiate } from '../domain/credit-transfer-initiate';
import api from '../api';
import { loginStore } from './login-store';

export class CreditTransferInitiateStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];
  @observable page?: number;
  @observable receival_code?: string;
  @observable username?: string;

  @observable toggleOptionsDiv: { [k: number]: boolean } = {};

  @observable userTransactions: any = [];

  constructor() {}

  getUserTransactions = async (loginStore) => {
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getUserTransactions(dat, loginStore.authToken);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getUserTransactions =======');
      this.userTransactions = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setUserTransactions = (loginStore) => {
    this.getUserTransactions(loginStore);
    console.log('getting all users approved transactions');
  };

}

export const creditTransferInitiateStore = new CreditTransferInitiateStore();
(window as any).creditTransferInitiateStore = creditTransferInitiateStore;
