import { observable, action } from 'mobx';
import { ResetPasswordConfirm } from '../domain/reset-password-confirm';
import api from '../api';
import bcrypt from 'bcryptjs';
import {loginStore} from "./login-store";

export class ResetPasswordConfirmStore {
  @observable isLoading = false;

  @observable resetPasswordConfirmInput: Partial<ResetPasswordConfirm> = {
    code: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.resetPasswordConfirmInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.code = (request.code == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  confirmCode = async (user) => {

    this.isLoading = true;
    let result = null;
    try {

        //let resulta = await api.getToken(null);
        //console.log('======= getToken =======');
        //console.log(resulta.token);
        //console.log('============================');

        let dat = {
          user: user,
          code: this.resetPasswordConfirmInput.code
        };
        result = await api.confirmCode(dat, loginStore.authToken);

      //return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= confirmCode =======');
      console.log(result);
      if(result != null){
        if(result.status === true)
        {
          return true;
        }
        else {
          return false;
        }
        console.log('============================');
      }
      else {
        return false;
      }
      this.isLoading = false;
    }
  };

}

export const resetPasswordConfirmStore = new ResetPasswordConfirmStore();
(window as any).resetPasswordConfirmStore = resetPasswordConfirmStore;
