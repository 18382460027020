// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { degreeFromId, degreeLogoFromId, orderLogoFromConstitutionAndOrder } from '../../helpers/utilities';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  addLodgeStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  lodgeStore?: LodgeStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  addLodgeStore?: AddLodgeStore;
  addMeetingStore?: AddMeetingStore;
  addContactStore?: AddContactStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('lodgeStore',
'lodgeDetailsStore',
'addLodgeStore',
'addMeetingStore',
'addContactStore',
'loginStore',
'configurationStore')

@observer
export class Lodge extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClearingOfAddLodge = async () => {
    this.props.addLodgeStore.clearLodgeInput();
    this.props.addMeetingStore.clearMeetingInput();
    this.props.addContactStore.clearContactInput();
    this.props.addLodgeStep();
    //await this.props.configurationStore.getAllAddresses();
  };

  render() {
    if (!this.props.lodgeStore) {
      return;
    }

    const lodgeDetailsStore = this.props.lodgeDetailsStore;

    const lodgeStore = this.props.lodgeStore;
    console.log("------- lodgeStore.lodgeResults -------");
    console.log(lodgeStore.lodgeResults);
    console.log("---------------------------------------");

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Card className='post-option-active'>
          <CardBody className='top-buttons'>
            <Row className='row-justified-and-centered'>
              <Col>
                <div id='navigation_buttons'>
                  <Button
                      className=''
                      color=''
                      onClick={async () => {
                        this.props.previousStep();
                      }}
                  >
                    <Icon id="back" text="Back"></Icon>
                  </Button>

                  <Button
                      className=''
                      color=''
                      onClick={async () => {
                        this.handleClearingOfAddLodge();
                      }}
                  >
                    <Icon id="add-lodge" text="Add a lodge"></Icon>
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col sm="12">
            <h1>{degreeFromId(lodgeStore.degree)}</h1>
          </Col>
        </Row>
        <br />
        <h5>{lodgeStore.isLoading ? 'Loading...' : 'Select for more details: (found: '+lodgeStore.lodgeResults.length+')'}</h5>
        <div>
        {lodgeStore.lodgeResults.map((lodge, index) => (
          <Card className='post-option-active pointing-cursor' onClick={async () => {
            //const success = true;//await quoteStore.getQuote();
            //if (success) {
            //  this.props.nextStep()
            //}
            //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
            this.props.setLoadingTrue();

            this.props.lodgeDetailsStore.clearLodgeInfo();
            await this.props.lodgeDetailsStore.setLodgeInfo(lodge.name, lodge.number, lodge.degree, loginStore.miniToken, loginStore.lodges);
            this.props.nextStep();

            this.props.setLoadingFalse();
          }}>
          {/*<Card className={`quote-option${
            lodge.active === 'Active'
              ? '-active'
              : ''
            }`} onClick={async () => {
              //const success = true;//await quoteStore.getQuote();
              //if (success) {
              //  this.props.nextStep()
              //}
              //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
              this.props.setLoadingTrue();

              this.props.lodgeDetailsStore.clearLodgeInfo();
              await this.props.lodgeDetailsStore.setLodgeInfo(lodge.name, lodge.number, lodge.degree, loginStore.miniToken, loginStore.lodges);
              this.props.nextStep();

              this.props.setLoadingFalse();
            }}
            >*/}
          <CardBody>
            <img alt='logo' className="list-order-logo" src={`${orderLogoFromConstitutionAndOrder(lodge.constitution_id, lodge.degree)}`} height="40px" />
            <span>
              {lodge.name} No. {lodge.number} {lodge.short_constitution} {lodge.active === 'Active' ? '' : '(Inactive)'}
            </span>
          </CardBody>
          </Card>
        ))}
        </div>

      </div>
    );
  }
}

export default Lodge;
