// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { UserAccountStore } from '../../stores/user-account-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { QRCodeCanvas } from 'qrcode.react';
import { constructWAShareLink } from '../../helpers/utilities';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  userAccountStore?: UserAccountStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  page: number;
}

@inject('userAccountStore', 'contributeStore', 'loginStore')
@observer
export class UserAccount extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      page: 1,
    };
      //this.scrollToTop();
  }

  /*componentDidMount() {
    this.scrollToTop();
  }*/

  scrollToTop = () => {
      console.log('scroll to top');
      window.scrollTo(0, 0);
  };

  render() {
    if (!this.props.userAccountStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const userAccountStore = this.props.userAccountStore;//typeOfThankYou

    return (
        <div>
          <Row>
            <Col sm='12'>
              <h4>
                <u>Account</u>
              </h4>
            </Col>
          </Row>

          <Card className='post-option-active'>
            <CardBody>
              <Row className=''>
                <Col>
                <span className='small-font'>
                  Your current balance is: <b>{loginStore.user_credits}</b> Credits
                </span>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className='post-option-active'>
            <CardBody className='top-buttons'>
              <Row className='row-justified-and-centered'>
                <Col>
                  <div id='navigation_buttons'>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          this.props.profileStep();
                        }}
                    >
                      <Icon id="back" text="Back"></Icon>
                    </Button>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          userAccountStore.page = 1;
                          this.setState({page: 1});
                        }}
                    >
                      <Icon id="transactions" text="Transactions"></Icon>
                    </Button>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          userAccountStore.page = 2;
                          this.setState({page: 2});
                        }}
                    >
                      <Icon id="piggy-bank" text="Top Up Options"></Icon>
                    </Button>

                    <Button
                        className=''
                        color=''
                        onClick={async () => {
                          userAccountStore.page = 3;
                          this.setState({page: 3});
                        }}
                    >
                      <Icon id="receive" text="Receive Credits"></Icon>
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <hr />

          {this.state.page == 1 ? (
                  <div>
                    <Card className='post-option-active'>
                      <CardBody>
                        <Row>
                          <Col sm='12' className=''>
                <span>
                  <b>
                    <u>Transactions</u>
                  </b>
                </span>
                          </Col>
                        </Row>
                        <div>
                          {userAccountStore.userTransactions.map((transaction, index) => (
                              <div>
                                <span className='x-small-font-italic'>{transaction.created_at}</span>
                                <br />
                                <span className='small-font-italic'>
                    <b>{transaction.amount}</b> Credits
                  </span>
                                <br />
                                <span className='small-font-italic'>{transaction.description}</span>
                                <hr />
                              </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
              ) :
              this.state.page == 2 ? (
                  <div className='toggle-email-setting-div-open'>
                    <Row>
                      <Col sm='4'>

                        <Card className='post-option-active top-up-option'>
                          <CardBody>
                            <div className='pricing-top-label'>
                              <b>Starter Pack</b>
                            </div>
                            <Row>
                              <Col>
                                <b>10 Credits</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className='x-small-font'>FOR</span>
                                <br/>
                                <b>R33.00</b>
                                <br/>
                                <br/>
                              </Col>
                            </Row>

                            <Row>
                              <Col className='row-justified-and-centered-flex promote-button-col'>
                                <form
                                    name='PayFastPayNowForm'
                                    action='https://payment.payfast.io/eng/process'
                                    method='post'
                                    target='_blank'
                                >
                                  <input required type='hidden' name='cmd' value='_paynow'/>
                                  <input required type='hidden' name='receiver' pattern='[0-9]' value='24874815'/>
                                  <input type='hidden' name='return_url' value='https://freemason-alpha.com/ftg'/>
                                  <input type='hidden' name='cancel_url' value='https://freemason-alpha.com/ftg'/>
                                  <input type='hidden' name='notify_url' value='https://freemason-alpha.com/ftg'/>
                                  <input required type='hidden' name='amount' value='33'/>
                                  <input required type='hidden' name='item_name' value='10 Credits'/>
                                  <input
                                      type='hidden'
                                      name='item_description'
                                      value='10 credits within the Freemason Travellers Guide'
                                  />
                                  <table>
                                    <tr>
                                      <td>
                                        <input
                                            type='image'
                                            src='https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png'
                                            alt='Buy Now'
                                            title='Buy Now with Payfast'
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </form>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm='4'>
                        <Card className='post-option-active top-up-option'>
                          <CardBody>
                            <div className='pricing-top-label'>
                              <b>Most Loved</b>
                            </div>
                            <Row>
                              <Col>
                                <b>100 Credits</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className='x-small-font'>FOR</span>
                                <br/>
                                <b>R297.00</b> <s>R330.00</s>
                                <br/>
                                <span className='small-font-italic'>(10% off)</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col className='row-justified-and-centered-flex promote-button-col'>
                                <form
                                    name='PayFastPayNowForm'
                                    action='https://payment.payfast.io/eng/process'
                                    method='post'
                                    target='_blank'
                                >
                                  <input required type='hidden' name='cmd' value='_paynow'/>
                                  <input required type='hidden' name='receiver' pattern='[0-9]' value='24874815'/>
                                  <input type='hidden' name='return_url' value='https://freemason-alpha.com/ftg'/>
                                  <input type='hidden' name='cancel_url' value='https://freemason-alpha.com/ftg'/>
                                  <input type='hidden' name='notify_url' value='https://freemason-alpha.com/ftg'/>
                                  <input required type='hidden' name='amount' value='297'/>
                                  <input required type='hidden' name='item_name' value='100 Credits'/>
                                  <input
                                      type='hidden'
                                      name='item_description'
                                      value='100 credits within the Freemason Travellers Guide'
                                  />
                                  <table>
                                    <tr>
                                      <td>
                                        <input
                                            type='image'
                                            src='https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png'
                                            alt='Buy Now'
                                            title='Buy Now with Payfast'
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </form>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm='4'>
                        <Card className='post-option-active top-up-option'>
                          <CardBody>
                            <div className='pricing-top-label'>
                              <b>Best Value</b>
                            </div>
                            <Row>
                              <Col>
                                <b>1000 Credits</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className='x-small-font'>FOR</span>
                                <br/>
                                <b>R2640.00</b> <s>R3300.00</s>
                                <br/>
                                <span className='small-font-italic'>(20% off)</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col className='row-justified-and-centered-flex promote-button-col'>
                                <form
                                    name='PayFastPayNowForm'
                                    action='https://payment.payfast.io/eng/process'
                                    method='post'
                                    target='_blank'
                                >
                                  <input required type='hidden' name='cmd' value='_paynow'/>
                                  <input required type='hidden' name='receiver' pattern='[0-9]' value='24874815'/>
                                  <input type='hidden' name='return_url' value='https://freemason-alpha.com/ftg'/>
                                  <input type='hidden' name='cancel_url' value='https://freemason-alpha.com/ftg'/>
                                  <input type='hidden' name='notify_url' value='https://freemason-alpha.com/ftg'/>
                                  <input required type='hidden' name='amount' value='2640'/>
                                  <input required type='hidden' name='item_name' value='1000 Credits'/>
                                  <input
                                      type='hidden'
                                      name='item_description'
                                      value='1000 credits within the Freemason Travellers Guide'
                                  />
                                  <table>
                                    <tr>
                                      <td>
                                        <input
                                            type='image'
                                            src='https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png'
                                            alt='Buy Now'
                                            title='Buy Now with Payfast'
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </form>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
              ) : this.state.page == 3 ? (
                  <div>
                    <div>
                      <Row>
                        <Col sm='12'>
                          <h6>Your Receival Code:</h6>
                          <div>
                            <QRCodeCanvas value={'https://freemason-alpha.com/ftg?receivalcode=' + userAccountStore.receival_code} />
                            <Button
                                className='small-font'
                                color=''
                                title='Share your Receival QR code link'
                                onClick={async () => {
                                }}
                            >
                              <Icon id="whats-app"></Icon>
                            </Button>
                            <span className='small-font-italic'>
                      {'https://freemason-alpha.com/ftg?receivalcode=' + userAccountStore.receival_code}
                    </span>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <p>Share this QR code with fellow members, for them to be able to send you credits.</p>
                    </div>
                  </div>
              ) : ''
          }

          <hr />
        </div>
    );
  }
}

export default UserAccount;
