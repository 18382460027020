import { observable, action } from 'mobx';
import { RaffleName } from '../domain/raffle-name';
import api from '../api';

export class RaffleNameStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable raffle_name = '';
  @observable raffle_code = '';

  @observable editResults: any = [];

  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

}

export const raffleNameStore = new RaffleNameStore();
(window as any).raffleNameStore = raffleNameStore;
