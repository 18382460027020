import { observable, action } from 'mobx';
import { CreditTransferConfirmation } from '../domain/credit-transfer-confirmation';
import api from '../api';
import { loginStore } from './login-store';

export class CreditTransferConfirmationStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];
  @observable page?: number;
  @observable confirmation_code?: string;

  @observable toggleOptionsDiv: { [k: number]: boolean } = {};

  @observable userTransactions: any = [];

  @observable validationResult: { [k: string]: string } = {};
  fieldsToFill: string[] = [];

  constructor() {}

  validate = () => {
    //const request = this.lodgeInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.confirmation_code = (this.confirmation_code == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getUserTransactions = async (loginStore) => {
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getUserTransactions(dat, loginStore.authToken);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getUserTransactions =======');
      this.userTransactions = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setUserTransactions = (loginStore) => {
    this.getUserTransactions(loginStore);
    console.log('getting all users approved transactions');
  };

  validateConfirmationCode = async (miniToken, receivalCode, amount) => {
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: miniToken,
        confirmationCode: this.confirmation_code,
        receivalCode: receivalCode,
        amount: amount
      };
      result = await api.validateConfirmationCode(dat, loginStore.authToken);

      return result;
    }
    catch (error) {
      console.error(error);
      return false;
    }
    finally {
      console.log('======= validateConfirmationCode =======');
      //this.userTransactions = result;
      console.log(result);
      console.log('============================');
      this.isLoading = false;
    }
  };

}

export const creditTransferConfirmationStore = new CreditTransferConfirmationStore();
(window as any).creditTransferConfirmationStore = creditTransferConfirmationStore;
