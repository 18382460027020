import moment from 'moment'

export const getCurrentYear = () => {
  return "2024";
};

export const getCurrentDate = () => {
  //return "2024";
  const currentDate = new Date();

  const formattedDate = currentDate.getFullYear() + '-' +
    ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + currentDate.getDate()).slice(-2);

  return formattedDate;
};

export const convertDate = (d) => {
  //return "2024";
  const theDate = d; //new Date(d);

  const formattedDate = theDate.getFullYear() + '-' +
    ('0' + (theDate.getMonth() + 1)).slice(-2);/* + '-' +
    ('0' + theDate.getDate()).slice(-2);*/

  return formattedDate;
};

export const convertDateTwo = (d) => {
  //return "2024";
  const theDate = d; //new Date(d);

  const formattedDate = theDate.getFullYear() + '-' +
    ('0' + (theDate.getMonth() + 1)).slice(-2); + '-' +
    ('0' + theDate.getDate()).slice(-2);

  return formattedDate;
};

export const theDay = (D, d) => {
  //return "2024";
  const theDate = new Date(D);

  const formattedDate = theDate.getFullYear() + '-' +
    ('0' + (theDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + d).slice(-2);

  return formattedDate;
};

export const matchMeetingDates = (meetings, D) => {
  //console.log("meetings");
  //console.log(meetings);
  //console.log("DDDDDDDDDDDDDDDDDD");
  //console.log(D);

  var h = [];
  for (let i = 0; i < meetings.length; i++) {
    //console.log("meetings[i].datetime.toString()");
    //console.log(meetings[i].datetime.toString());
    if (meetings[i].datetime.toString().includes(D.toString())) {
      //console.log("meetings[i].datetime.toString()");
      //console.log(meetings[i].datetime.toString());
      h.push(meetings[i].datetime.toString());
    }
  }

  //console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
  //console.log(h);

  return h.length;
};

export const getNameOfDay = (d) => {
  var dt = moment(d, "YYYY-MM-DD HH:mm:ss")
  return dt.format('dddd').substring(0,3);
};

export const getNumberOfDaysInMonth = (d) => {
  //return d.addMonths(-1);
  return moment(d).daysInMonth();
};

export const getPreviousDate = (d) => {
  //console.log("getPreviousDate d");
  //console.log(d);
  let a = moment(d).subtract(1, 'months').calendar();
  if (a.includes("Today")) {
    return new Date();
  }
  //console.log("getPreviousDate a");
  //console.log(a);
  //let b = new Date(moment(d).subtract(1, 'months').calendar());
  //console.log("getPreviousDate b");
  //console.log(b);
  return new Date(moment(d).subtract(1, 'months').calendar());

};

export const getNextDate = (d) => {
  console.log("getNextDate d");
  console.log(d);
  let a = moment(d).add(1, 'months').calendar();
  if (a.includes("Today")) {
    return new Date();
  }
  return new Date(moment(d).add(1, 'months').calendar());

};
