import { observable, action } from 'mobx';
import { CreditTransferAmount } from '../domain/credit-transfer-amount';
import api from '../api';
import { loginStore } from './login-store';

export class CreditTransferAmountStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];
  @observable page?: number;
  @observable receival_code?: string;
  @observable amount?: number;

  @observable toggleOptionsDiv: { [k: number]: boolean } = {};
  @observable userTransactions: any = [];

  @observable validationResult: { [k: string]: string } = {};
  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    //const request = this.lodgeInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.amount = (this.amount == 0)
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getUserTransactions = async (loginStore) => {
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getUserTransactions(dat, loginStore.authToken);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getUserTransactions =======');
      this.userTransactions = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setUserTransactions = (loginStore) => {
    this.getUserTransactions(loginStore);
    console.log('getting all users approved transactions');
  };

  getTransferConfirmationCode = async (loginStore) => {
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getTransferConfirmationCode(dat, loginStore.authToken);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getTransferConfirmationCode =======');
      //this.userTransactions = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };
}

export const creditTransferAmountStore = new CreditTransferAmountStore();
(window as any).creditTransferAmountStore = creditTransferAmountStore;
