// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { AchievementsStore } from '../../stores/achievements-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LoginStore } from '../../stores/login-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  achievementsStore?: AchievementsStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}


@inject('achievementsStore', 'contributeStore', 'loginStore',)
@observer
export class Achievements extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            valid: {},
            clicked: false
        };
    }


    render() {
        if (!this.props.achievementsStore) {
            return;
        }

        const loginStore = this.props.loginStore;
        const achievementsStore = this.props.achievementsStore;

        return (
            <div>
                <Row>
                    <Col sm="12">
                        <h1>Achievements</h1>
                    </Col>
                </Row>
                <br/>
                <Card>
                    <CardBody>
                        <div className='leaderboard'>
                            <table>
                                <tr>
                                    <th>Contribution</th>
                                    <th>Points earned</th>
                                </tr>
                                <tbody>
                                {achievementsStore.achievementResults.achievement_contributions.map((contribution_data, index) => (
                                    <tr>
                                        <td className='username'>{contribution_data.contribution}</td>
                                        <td className='username'>{contribution_data.points_earned} points
                                            per {contribution_data.contribution}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>

                <hr/>
                <Card>
                    <CardBody>
                        <div className='leaderboard'>
                            <table>
                                <tr>
                                    <th>Level</th>
                                    <th>Points</th>
                                    <th>Badge</th>
                                </tr>
                                <tbody>
                                {achievementsStore.achievementResults.achievement_levels.map((level_data, index) => (
                                    <tr>
                                        <td className='rank'>{level_data.level}</td>
                                        <td className='username'>{level_data.points}</td>
                                        <td className='username'>{level_data.badge}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>

            </div>
        );
    }
}


export default Achievements;
