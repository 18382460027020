import { observable, action } from 'mobx';
import { ResetPasswordNewPassword } from '../domain/reset-password-new-password';
import api from '../api';
import bcrypt from 'bcryptjs';
import {loginStore} from "./login-store";

export class ResetPasswordNewPasswordStore {
  @observable isLoading = false;

  @observable resetPasswordNewPasswordInput: Partial<ResetPasswordNewPassword> = {
    password1: '',
    password2: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.resetPasswordNewPasswordInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.password1 = (request.password1 == '')
      ? '* Required'
      : '';

    this.validationResult.password2 = (request.password2 == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  changePassword = async (user, code) => {

    this.isLoading = true;
    let result = null;
    try {

        //let resulta = await api.getToken(null);
        //console.log('======= getToken =======');
        //console.log(resulta.token);
        //console.log('============================');

        let dat = {
          user: user,
          code: code,
          password: this.resetPasswordNewPasswordInput.password1
        };
        result = await api.changePassword(dat, loginStore.authToken);

      //return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= changePassword =======');
      console.log(result);
      if(result != null){
        if(result.status === true)
        {
          return true;
        }
        else {
          return false;
        }
        console.log('============================');
      }
      else {
        return false;
      }
      this.isLoading = false;
    }
  };

}

export const resetPasswordNewPasswordStore = new ResetPasswordNewPasswordStore();
(window as any).resetPasswordNewPasswordStore = resetPasswordNewPasswordStore;
