// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { RaffleNameStore } from '../../stores/raffle-name-store';
import { RaffleEntrantsStore } from '../../stores/raffle-entrants-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import { TextArea } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  //raffleCodeStep: () => void;
  raffleEntrantsStep: () => void;
  raffleNameStore?: RaffleNameStore;
  raffleEntrantsStore?: RaffleEntrantsStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('raffleNameStore', 'raffleEntrantsStore', 'contributeStore', 'loginStore',)
@observer
export class RaffleName extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.raffleNameStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const raffleNameStore = this.props.raffleNameStore;
    const raffleEntrantsStore = this.props.raffleEntrantsStore;
    const validationResult = raffleNameStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Raffle Create</h1>
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
            <TextInput
              id="raffle_name"
              label="Raffle Name"
              value={raffleNameStore.raffle_name}
              onChange={e =>
                (raffleNameStore.raffle_name = e.target.value)
              }
              invalid={!!validationResult.raffle_name}
              validationMessage={validationResult.raffle_name}
            />
          </Col>
          <Col>
            <TextInput
              id="raffle_code"
              label="Raffle Code"
              value={raffleNameStore.raffle_code}
              onChange={e =>
                (raffleNameStore.raffle_code = e.target.value)
              }
              invalid={!!validationResult.raffle_code}
              validationMessage={validationResult.raffle_code}
            />
          </Col>
        </Row>

        <br/>
        <Button color="primary" onClick={async () => {
          raffleEntrantsStore.entrants = [];
          await raffleEntrantsStore.getAllUsers();
          this.props.raffleEntrantsStep();
        }}>
          Start
        </Button>
        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default RaffleName;
