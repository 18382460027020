// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {districtTitleSwitch} from '../../helpers/utilities';

import { SearchStore } from '../../stores/search-store';
import { LodgeStore } from '../../stores/lodge-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select'
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";

interface Props {
  nextStep: () => void;
  contributeStep: () => void;
  newLocationStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  searchStore?: SearchStore;
  lodgeStore?: LodgeStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  advancedSearchToggle: boolean;
  searchText: string;
  searchTodayOnly: boolean;
  searchCountry: string;
  searchDistrict: string;
  searchConstitution: string;
  searchDegree: string;
  searchAddress: string;
}

@inject('searchStore', 'lodgeStore', 'contributeStore', 'loginStore', 'configurationStore')
@observer
export class Search extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      advancedSearchToggle: false,
      searchText: '',
      searchTodayOnly: false,
      searchCountry: '',
      searchDistrict: '',
      searchConstitution: '',
      searchDegree: '',
      searchAddress: '',
    };
  }



  render() {
    if (!this.props.searchStore) {
      return;
    }

    const searchStore = this.props.searchStore;

    const searchInput = searchStore.searchInput;
    const validationResult = searchStore.validationResult;

    const loginStore = this.props.loginStore;

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    return (
      <div>
      <Row>
        <Col sm="12">
          <h4><u>Search</u></h4>
        </Col>
      </Row>

      <Card className='post-option-active'>
        <CardBody>
          <h5>Criteria:</h5>
          <div>
            <Row>
              <Col>
                <TextInput
                    id="name_number"
                    label="Lodge Name/Number*"
                    value={this.state.searchText}
                    onChange={e => {
                      searchInput.search = e.target.value;
                      this.setState({searchText: e.target.value});
                    }
                    }
                    invalid={!!validationResult.search}
                    validationMessage={validationResult.search}
                />
              </Col>
            </Row>
            <a href='#' onClick={() => {
              //searchStore.toggleExpand();
              this.setState({advancedSearchToggle: !this.state.advancedSearchToggle});
//          this.forceUpdate();
            }}>
              {this.state.advancedSearchToggle ? 'Advanced Search Hide' : 'Advanced Search Show'}
            </a>
            {this.state.advancedSearchToggle && (
                <div>
                  <br/>
                  <Row>
                    <Col>

                      <FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                                type='checkbox'
                                name='radioToday'
                                checked={this.state.searchTodayOnly}
                                onClick={() => {
                                  searchInput.today_only = !searchInput.today_only;
                                  //this.props.applicationStore.validationResult = {};
                                  this.setState({searchTodayOnly: !searchInput.today_only});
                                }}
                            />{' '}
                            Meeting Today
                          </Label>
                        </FormGroup>
                      </FormGroup>

                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SelectInput
                          id='country'
                          label='Country'
                          invalid={!!validationResult.country}
                          validationMessage={validationResult.country}
                          value={this.state.searchCountry}
                          onChange={e => {
                            searchStore.searchInput.country = e.target.value;
                            this.setState({searchCountry: e.target.value});
                            configurationStore.filterAddresses(configurationStore.addressResults, searchStore.searchInput.country);
                            configurationStore.filterConstitutions(configurationStore.constitutionResults, searchStore.searchInput.country);
                            searchInput.constitution = '';
                            searchInput.district = '';
                            configurationStore.filteredDistricts = [];
                            searchInput.address = '';
                          }}
                          options={configurationStore.countryResults}
                      />
                    </Col>
                    <Col>
                      <SelectInput
                          id='constitution'
                          label='Constitution'
                          invalid={!!validationResult.constitution}
                          validationMessage={validationResult.constitution}
                          value={this.state.searchConstitution}
                          onChange={e => {
                            //console.log(e.target.value.split('_', 1));
                            //console.log(e.target.value.split('_', 1)[0]);
                            searchInput.constitution = e.target.value;
                            this.setState({searchConstitution: e.target.value});
                            configurationStore.filterDistricts(configurationStore.districtResults, searchStore.searchInput.country, searchInput.constitution.split('_', 1)[0]);
                            searchStore.searchInput.district = '';
                            searchStore.searchInput.address = '';
                          }}
                          options={configurationStore.filteredConstitutions}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SelectInput
                          id='district'
                          label={districtTitleSwitch(searchInput.country, searchInput.constitution.split('_', 1)[0], configurationStore.filteredConstitutions)}
                          invalid={!!validationResult.district}
                          validationMessage={validationResult.district}
                          value={this.state.searchDistrict}
                          onChange={e => {
                            searchInput.district = e.target.value;
                            this.setState({searchDistrict: e.target.value});
                          }}
                          options={configurationStore.filteredDistricts}
                      />
                    </Col>
                    <Col>
                      <SelectInput
                          id='degree'
                          label='Order'
                          invalid={!!validationResult.degree}
                          validationMessage={validationResult.degree}
                          value={this.state.searchDegree}
                          onChange={e => {
                            searchStore.searchInput.degree = e.target.value;
                            this.setState({searchDegree: e.target.value});
                          }}
                          options={configurationStore.degreeResults}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SearchableSelectInput
                          id='address'
                          label='Address'
                          invalid={!!validationResult.address}
                          validationMessage={validationResult.address}
                          value={this.state.searchAddress}
                          onChange={(selectedOption) => {
                            if (selectedOption != null) {
                              searchStore.searchInput.address = selectedOption.value; // Set the address_id using the selected option's value property
                              this.setState({searchAddress: selectedOption.value});
                            }
                          }}
                          options={configurationStore.filteredAddresses}
                      />

                    </Col>
                  </Row>
                </div>
            )}

          </div>

          <div id='navigation_buttons'>
            <Button color="" onClick={async () => {
              //const success = true;//await quoteStore.getQuote();
              //if (success) {
              //  this.props.nextStep()
              //}
              this.props.setLoadingTrue();

              this.props.lodgeStore.clearLodges();
              this.props.lodgeStore.searchString = this.state.searchText;
              this.props.lodgeStore.country = this.state.searchCountry;
              this.props.lodgeStore.district = this.state.searchDistrict.split('_', 1)[0];
              this.props.lodgeStore.constitution = this.state.searchConstitution.split('_', 1)[0];
              this.props.lodgeStore.degree = this.state.searchDegree;
              this.props.lodgeStore.today_only = this.state.searchTodayOnly;
              this.props.lodgeStore.address = this.state.searchAddress;
              //this.props.lodgeStore.setSearchString(searchInput.search);
              await this.props.lodgeStore.setLodges(loginStore);
              this.props.nextStep();

              this.props.setLoadingFalse();
            }}>
              <Icon id="magnifying-glass" text="Search"></Icon>
            </Button>
            <Button color="" onClick={async () => {
              this.props.newLocationStep();
            }}>
              <Icon id="location" text="Missing address?"></Icon>
            </Button>
          </div>
        </CardBody>
      </Card>
      </div>
  );
  }
}

export default Search;
