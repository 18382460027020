import { observable, action, makeAutoObservable } from 'mobx';
import { ViewProfile } from '../domain/view-profile';
import api from '../api';
import {loginStore} from "./login-store";

export class ViewProfileStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable profileResults: any = [];
  @observable page?: number;
  //@observable feed_input = '';

  @observable postData?: any = {};
  @observable postDataIndex?: number;

  // for the comment modal
  @observable userPostCommentModalInput: { [k: string]: any } = {
    id_type: 'id'
    // TODO remove init
  };
  @observable modalValidationResult: { [k: string]: string } = {};
  @observable userPostCommentModalOpen: boolean = false;
  @observable userPostCommentModalSuccess: boolean = false;
  @observable userPostCommentModalError: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setProfile = async (miniToken, user_id) => {
    //this.getAllSuggestedEdits(loginStore);
    console.log('!@#!@#!@#!@');
    console.log('setProfile');
    console.log(user_id);

    await this.getProfile(miniToken, user_id);
  };

  getProfile = async (miniToken, user_id) => {
    this.isLoading = true;
    try {
      let dat = {
        miniToken: miniToken, // who is looking at the profile
        public_id: user_id // whos profile is being looked at
      };

      const result = await api.getUserPublicProfile(dat, loginStore.authToken);
      console.log('======= getProfile =======');
      this.profileResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  likePost = async (miniToken, post_id) => {
    let dat = {
      miniToken: miniToken,
      post_id: post_id
    };
    //console.log("DOUBLE CHECKING -> dat");
    //console.log(dat);

    let result = await api.likeUserPost(dat, loginStore.authToken);

    return result;
  };

  setLiked = (index) => {
    this.profileResults.profile_feed[index].liked = !this.profileResults.profile_feed[index].liked;
  }

  commentOnUserPost = async (miniToken, post_id, comment) => {
    let dat = {
      miniToken: miniToken,
      post_id: post_id,
      comment: comment
    };
    //console.log("DOUBLE CHECKING -> dat");
    //console.log(dat);

    let result = await api.commentOnUserPost(dat, loginStore.authToken);

    return result;
  };

  setPostData = (post_data, index) => {
    this.postData = post_data;
    this.postDataIndex = index;
  }

  toggleUserPostCommentModal = () => {
    this.userPostCommentModalOpen = !this.userPostCommentModalOpen;
    this.userPostCommentModalError = false;
    this.userPostCommentModalSuccess = false;
  };
}

export const viewProfileStore = new ViewProfileStore();
(window as any).viewProfileStore = viewProfileStore;
