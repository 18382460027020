import { observable, action } from 'mobx';
import { ThankYou } from '../domain/thank-you';
import api from '../api';

export class ThankYouStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {

  }

  


}

export const thankYouStore = new ThankYouStore();
(window as any).thankYouStore = thankYouStore;
