import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Input,
  FormFeedback,
  InputGroup,
  FormGroup,
  Label,
} from 'reactstrap';

interface Props {
  id: string;
  label: string;
  value: any;
  onChange: (e: any) => void;
  invalid: boolean;
  validationMessage: string;
  options: { value: string; label: string }[]
}

export const SelectInput: React.FC<Props> = observer((props: Props) => {
  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <InputGroup>
        <Input
          type="select"
          name="select"
          value={props.value || ''}
          onChange={props.onChange}
          invalid={props.invalid}
          id={props.id}
        >
          <option value="">Please select</option>
          {props.options.map((r, index) => (
            <option key={r.value + index} value={r.value}>
              {r.label}
            </option>
          ))}
        </Input>
      </InputGroup>
      <FormFeedback style={{ display: !!props.invalid && 'block' }} >
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  )
})

