import { observable, action } from 'mobx';
import { ImpromptuMeetingSetupImage } from '../domain/impromptu-meeting-setup-image';
import api from '../api';

export class ImpromptuMeetingSetupImageStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable meetingResults: any = [];

  @observable latitude = 0;
  @observable longitude = 0;
  @observable link = '';
  @observable address = '';
  @observable title = '';
  @observable description = '';
  @observable closed = false;
  @observable lodge = null;
  @observable isMobile = false;
  @observable isScheduled = false;
  @observable image_file_name = '';
  @observable image_file_data = '';
  @observable image_file_binary = '';

  @observable validationResult: { [k: string]: string } = {};

  constructor() {

  }

  // TODO: needs refinement, not just basing it on window size
  checkIfMobileDevice = () => {
    let tt = window.matchMedia('(max-width: 767px)').matches;
    //console.log("tt: " + tt);
    this.isMobile = tt;
  };

  setIsScheduledTrue = () => {
    this.isScheduled = true;
  };

  setIsScheduledFalse = () => {
    this.isScheduled = false;
  };

  // define the function that finds the users geolocation
  setCoords = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          let coords = position.coords;
          // update the value of userlocation variable
          //setUserLocation({ latitude, longitude });
          console.log(position.coords);
          //console.log(coords);
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          //http://maps.google.com/maps?q=S26.184019549056156,E28.046418206048134
          if (this.latitude > 0)
            this.link += 'N' + this.latitude;
          else
            this.link += 'S' + this.latitude * -1;

          this.link += ','
          if (this.longitude > 0)
            this.link += 'E' + this.longitude;
          else
            this.link += 'W' + this.longitude * -1;

        },
        // if there was an error getting the users location
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error('Geolocation is not supported by this browser.');
      this.isMobile = false;
    }
  };



}

export const impromptuMeetingSetupImageStore = new ImpromptuMeetingSetupImageStore();
(window as any).impromptuMeetingSetupImageStore = impromptuMeetingSetupImageStore;
