// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from "react-infinite-scroll-component";
import Tour from 'reactour';
// import { Document, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();

import {
  orderLogoFromConstitutionAndOrder,
  checkIfFuture,
  checkIfMeetingNotLogged,
  constructWAShareLink
} from '../../helpers/utilities';

import { FeedWallStore } from '../../stores/feed-wall-store';
import { FeedWallRightPanelStore } from '../../stores/feed-wall-right-panel-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ImpromptuMeetingStore } from '../../stores/impromptu-meeting-store';
import { LoginStore } from '../../stores/login-store';
import { LogVisitMeetingStore } from '../../stores/log-visit-meeting-store';
import { ContactStore } from '../../stores/contact-store';
import { ProfileStore } from '../../stores/profile-store';
import { PromotePostStore } from '../../stores/promote-post-store';
import { ViewProfileStore } from '../../stores/view-profile-store';

import { Icon } from "../svg_icons/icon";
import { Post } from "../display-components/post";
import { toast } from "react-toastify";

export const PromotedPost: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Promoted Posts</h2>
        <p className="small-font">
          Enhance Your Content's Visibility with Promoted Posts! Looking to ensure
          that your message stands out in the crowd? Look no further. Promoted Posts
          offer a powerful tool to boost the visibility of your content. Simply flag a
          meeting, social or one of your own posts, and watch it take center stage,
          captivating users as they engage with the platform. With Promoted Posts,
          you wield the ability to elevate your content's impact effortlessly.
          Join us in unlocking the full potential of your posts today!
        </p>

        <p className="small-font">
        All you have to do is click the <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Announcement-Megaphone--Streamline-Core" height="14" width="14"><desc>Announcement Megaphone Streamline Icon: https://streamlinehq.com</desc><g id="annoncement-megaphone"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M7.615 3.497a0.5 0.5 0 1 0 -0.866 0.5l0.018 0.032 -6.413 6.86a0.5 0.5 0 0 0 -0.068 0.592l0.609 1.055a0.5 0.5 0 0 0 0.546 0.237l1.708 -0.397 0.338 0.586 0.001 0.002A1.921 1.921 0 0 0 7 11.482l3.59 -0.834 0.017 0.03a0.5 0.5 0 1 0 0.866 -0.5l-0.188 -0.326a0.497 0.497 0 0 0 -0.028 -0.049l-3.424 -5.93a0.497 0.497 0 0 0 -0.028 -0.047l-0.19 -0.33Zm-1.592 8.212 -1.856 0.43 0.185 0.322a0.921 0.921 0 0 0 1.671 -0.752Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M7.181 0a0.75 0.75 0 0 1 0.75 0.75v1.253a0.75 0.75 0 0 1 -1.5 0V0.75a0.75 0.75 0 0 1 0.75 -0.75Zm6.806 6.806a0.75 0.75 0 0 1 -0.75 0.75h-1.253a0.75 0.75 0 0 1 0 -1.5h1.253a0.75 0.75 0 0 1 0.75 0.75Zm-11.608 0.75a0.75 0.75 0 1 0 0 -1.5H1.126a0.75 0.75 0 1 0 0 1.5h1.253ZM4.315 3.94a0.75 0.75 0 0 1 -1.06 0l-0.887 -0.886a0.75 0.75 0 1 1 1.06 -1.06l0.887 0.886a0.75 0.75 0 0 1 0 1.06Zm7.679 -0.886a0.75 0.75 0 0 0 -1.06 -1.06l-0.887 0.886a0.75 0.75 0 0 0 1.06 1.06l0.887 -0.886Z" clip-rule="evenodd" stroke-width="1"></path></g></svg> icon
        on the post you would like to promote and follow the instructions presented. <b>It's as easy as that!</b>
        </p>
      </p>
    </div>
  );
};

export const SponsoredPost: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Sponsored Posts</h2>
        <p className="small-font">
          Elevate your brand's presence with Sponsored Posts! Craft compelling content
          that captivates and resonates with your audience, then let us do the rest.
          Sponsored Posts seamlessly blend into users' feeds, delivering your
          message in an authentic and engaging way. Collaborate with us to create
          content that speaks directly to your target demographic, ensuring maximum
          impact and relevance. Whether it's driving brand awareness, generating
          leads, or sparking conversation, Sponsored Posts are your secret weapon
          for standing out in the digital crowd. Get ready to make waves and
          unlock new possibilities for your brand with Sponsored Posts. Let's
          elevate your brand together!
        </p>
      </p>
    </div>
  );
};

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  lodgeDetailsStep: () => void;
  impromptuMeetingStep: () => void;
  contactStep: () => void;
  promotePostStep: () => void;
  leaderboardStep: () => void;
  viewProfileStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  feedWallStore?: FeedWallStore;
  feedWallRightPanelStore?: FeedWallRightPanelStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  impromptuMeetingStore?: ImpromptuMeetingStore;
  logVisitMeetingStore?: LogVisitMeetingStore;
  contactStore?: ContactStore;
  profileStore?: ProfileStore;
  promotePostStore?: PromotePostStore;
  viewProfileStore?: ViewProfileStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  promotedPostOpen: boolean;
  sponsoredPostOpen: boolean;
  isTourOpen: boolean;
  userFeedUpcomingMeetings: any; // change this
  postsLiked: Array<Boolean>;
}

@inject(
  'feedWallStore',
  'contributeStore',
  'loginStore',
  'lodgeDetailsStore',
  'impromptuMeetingStore',
  'logVisitMeetingStore',
  'contactStore',
  'feedWallRightPanelStore',
  'profileStore',
  'promotePostStore',
  'viewProfileStore'
)
@observer
export class FeedWall extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      promotedPostOpen: false,
      sponsoredPostOpen: false,
      isTourOpen: false,
      userFeedUpcomingMeetings: [], //this.props.feedWallStore.userFeedUpcomingMeetings
      postsLiked: []
    };
    this.setTourIsClosed = this.setTourIsClosed.bind(this);

    //pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  componentDidMount() {
    this.setState({userFeedUpcomingMeetings: this.props.feedWallStore.userFeedUpcomingMeetings});

    const initializeArrayWithValues = (n, val = false) => Array(n).fill(val);
    this.setState({postsLiked: initializeArrayWithValues(this.props.feedWallStore.userFeedUpcomingMeetings.length, false)});

    this.props.feedWallStore.userFeedUpcomingMeetings.map((post, index) => {
      if (post.liked) {
        this.state.postsLiked[index] = true;
      }
    });
    this.setState({postsLiked: this.state.postsLiked});
  }

  getDescription = (id) => {
    document.getElementById('meeting_description_cut_'+id).style.display = 'none';
    document.getElementById('meeting_description_full_'+id).style.display = 'inherit';
    document.getElementById('meeting_image_'+id).classList.remove("post-restricted-height");
  }

  // as you scroll down more data is fetched for the Infinite Scroll
  fetchMoreData = async () => {
    let t = await this.props.feedWallStore.getUserFeedWallOnly(this.props.loginStore.miniToken);
    this.setState({userFeedUpcomingMeetings: t});

    const initializeArrayWithValues = (n, val = false) => Array(n).fill(val);
    this.setState({postsLiked: initializeArrayWithValues(this.props.feedWallStore.userFeedUpcomingMeetings.length, false)});

    this.props.feedWallStore.userFeedUpcomingMeetings.map((post, index) => {
      if (post.liked) {
        this.state.postsLiked[index] = true;
      }
    });
    this.setState({postsLiked: this.state.postsLiked});
  };

  reDir = (website) => {
    window.open(website, '_blank');
  }

  setTourIsOpen() {
    this.setState({ isTourOpen: true });
  }
  setTourIsClosed() {
    this.setState({ isTourOpen: false });
  }


  checkMobile(left_steps, general_steps, right_steps) {
    // Get the element
    var searchNavButton = document.getElementById("search_nav_button_text");

    // Check if the element exists and is visible
    if (searchNavButton && window.getComputedStyle(searchNavButton).display !== 'none') {
      //alert("shown");
      return left_steps.concat(general_steps).concat(right_steps);
    } else {
      //alert("hidden");
      return general_steps;
    }
  }

  onDocumentLoadSuccess() {
    console.log("onDocumentLoadSuccess");
  }


  render() {
    if (!this.props.feedWallStore) {
      return;
    }
    const loginStore = this.props.loginStore;
    const feedWallStore = this.props.feedWallStore;
    const feedWallRightPanelStore = this.props.feedWallRightPanelStore;

    const logVisitMeetingStore = this.props.logVisitMeetingStore;
    const contactStore = this.props.contactStore;
    const impromptuMeetingStore = this.props.impromptuMeetingStore;

    const promotePostStore = this.props.promotePostStore;

    const profileStore = this.props.profileStore;
    const viewProfileStore = this.props.viewProfileStore;

    

    const left_steps = [
      {
        selector: '.profile-navigate-step',
        content: 'Click to navigate to your profile section.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.your-contributions-step',
        content: 'Quick view of your contributions.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.top-three-leaderboard-step',
        content: 'Top 3 in the leaderboard. Click to navigate to the Leaderboard.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    const right_steps = [
      {
        selector: '.sponsored-post-step',
        content: 'This is a Sponsored Post, if you to would to advertise within the platform please contact advertise@freemason-alpha.com and we will send you all the details.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    const general_steps = [
      {
        selector: '.navigation-toolbar-step',
        content: 'Navigation toolbar.',
        style: {
          borderRadius: '5px',
        },


      },
      {
        selector: '.search-step',
        content: 'Find a lodge.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.contributions-step',
        content: 'Help vet and confirm suggestions made in the system.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.socials-step',
        content: 'Find list of all the social meetings.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.your-post-step',
        content: 'Create a post to let everyone knows what\'s happening.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.your-feed-step',
        content: 'Latest posts, meetings, socials and content.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-log-attendance-step',
        content: 'Log you visit or your intended attendance for this meeting.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-contact-step',
        content: 'Send a message to the lodge contact.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-share-step',
        content: 'Share this event or meeting via Whats App.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-promote-step',
        content: 'You can promote a post by clicking this icon and following the steps.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    const steps = this.checkMobile(left_steps, general_steps, right_steps);
    //const full_steps = left_steps.concat(general_steps).concat(right_steps);
    //const mobile_steps = general_steps;

    return (
      <div className="page-container-feed-modification">

      <Card className="post-option-active">
      <CardBody className="top-buttons">
        <Row className="row-justified-and-centered navigation-toolbar-step">
          <Col>
            <div id='navigation_buttons'>
              <Button className="search-step" color="" onClick={this.props.searchStep}>
                <Icon id="search" text="Search"></Icon>
              </Button>
              {loginStore.isLoggedIn ? (
              <Button className="contributions-step" color="" onClick={async () => {
                await this.props.contributeStore.setEdits(loginStore);
                this.props.contributeStep();
              }}>
                <Icon id="contribute" text="Contribute"></Icon>
              </Button>
              ): ''}
              <Button className="socials-step" color="" onClick={async () => {
                await impromptuMeetingStore.setImpromptuMeetings(loginStore);
                this.props.impromptuMeetingStep();
              }}>
                <Icon id="events" text="Events"></Icon>
              </Button>
              <Button color="" onClick={async () => {
                this.setTourIsOpen();
              }}>
                <Icon id="help" text="Help"></Icon>
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
      </Card>

      <div className="your-post-step">
        <Card className="post-option-active">
        <CardBody>
          <Row>
            <Col className="user-post-box">
            <img className="user-post-box-image" src="logo.png" alt="logo" height="30px" onClick={() => {
               //this.props.profileStep();
             }}/>
            <div className="user-post-box-text-field" onClick={ () => {
              profileStore.toggleUserPostModal();
              //this.setState({userPostOpen: true});
            }}>
              <span>What's on your mind, {loginStore.username}?</span>
            </div>
            </Col>
          </Row>
        </CardBody>
        </Card>
      </div>

        <div>
        {this.state.userFeedUpcomingMeetings.length > 0 ? (
          <div>
          {this.state.userFeedUpcomingMeetings.map((lodge_meeting, index) => (
            //<Card className="post-option-active">
            <Card className={`post-option-active ${
                lodge_meeting.sponsored && (index == 0 || index == 1)
                ? 'sponsor-ad'
                : ''
              }`}>
            <CardBody className={`card-padding-override ${
              lodge_meeting.post_type == 1
                ? 'your-feed-step'
                : ''
              }`}>
              {
                lodge_meeting.sponsored || lodge_meeting.promoted ?
                  <div className="pointing-cursor promoted-sponsored-info-button" onClick={() =>
                    lodge_meeting.sponsored ?
                      this.setState({ sponsoredPostOpen: true })
                    :
                    lodge_meeting.promoted ?
                      this.setState({ promotedPostOpen: true })
                    :
                    ''
                  }>
                    <Icon id="info"></Icon>
                  </div>
                :
                (lodge_meeting.post_type == 1 || lodge_meeting.post_type == 2) && lodge_meeting.promoted == false
                  ?
                  <div className="pointing-cursor promoted-sponsored-info-button feed-post-promote-step" onClick={async () => {
                      if (window.confirm('Are you sure you want to promote this meeting?')) {

                        promotePostStore.setPostData(lodge_meeting);
                        this.props.promotePostStep();
                      }
                    }}>
                    <Icon id="promote"></Icon>
                  </div>
                : ''
              }
            <Row>
              <Col className="col-sm-1 post_header_logo">
                <img alt='logo' src={`${lodge_meeting.lodge_constitution != '' ? orderLogoFromConstitutionAndOrder(lodge_meeting.lodge_constitution, lodge_meeting.lodge_degree) : "logo.png"}`} height="40px"
                onClick={async () => {
                  this.props.setLoadingTrue();

                  if (lodge_meeting.post_type == 1) {
                    this.props.lodgeDetailsStore.previousPage = 'feed-wall';
                    this.props.lodgeDetailsStore.clearLodgeInfo();
                    await this.props.lodgeDetailsStore.setLodgeInfo(lodge_meeting.lodge_name, lodge_meeting.lodge_number, lodge_meeting.lodge_degree, loginStore.miniToken, loginStore.lodges);
                    this.props.lodgeDetailsStep();
                  }
                  else if (lodge_meeting.post_type == 2) {
                    this.props.lodgeDetailsStore.previousPage = 'feed-wall';
                    await this.props.impromptuMeetingStore.setImpromptuMeetings(loginStore);
                    this.props.impromptuMeetingStep();
                  }
                  else if (lodge_meeting.post_type == 3) {
                    await this.props.viewProfileStore.setProfile(loginStore.miniToken, lodge_meeting.user_public_id);
                    this.props.viewProfileStep();
                  }
                  else {}

                  this.props.setLoadingFalse();
                }}/>
              </Col>
              <Col className="col-sm-11 post_header_title pointing-cursor">
              <span className="" onClick={async () => {
                if(lodge_meeting.sponsored) {
                  let res = await feedWallRightPanelStore.sponsoredPostImpression(loginStore.miniToken, lodge_meeting.id);
                  if(res) {
                    this.reDir(lodge_meeting.website);
                  }
                }
                else {
                  //const success = true;//await quoteStore.getQuote();
                  //if (success) {
                  //  this.props.nextStep()
                  //}
                  //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
                  this.props.setLoadingTrue();

                  if (lodge_meeting.post_type == 1) {
                    this.props.lodgeDetailsStore.previousPage = 'feed-wall';
                    this.props.lodgeDetailsStore.clearLodgeInfo();
                    await this.props.lodgeDetailsStore.setLodgeInfo(lodge_meeting.lodge_name, lodge_meeting.lodge_number, lodge_meeting.lodge_degree, loginStore.miniToken, loginStore.lodges);
                    this.props.lodgeDetailsStep();
                  }
                  else if (lodge_meeting.post_type == 2) {
                    this.props.lodgeDetailsStore.previousPage = 'feed-wall';
                    await this.props.impromptuMeetingStore.setImpromptuMeetings(loginStore);
                    this.props.impromptuMeetingStep();
                  }
                  else if (lodge_meeting.post_type == 3) {
                    await this.props.viewProfileStore.setProfile(loginStore.miniToken, lodge_meeting.user_public_id);
                    this.props.viewProfileStep();
                  }
                  else {}

                  this.props.setLoadingFalse();
                }
              }}>
                {lodge_meeting.title}
              </span>
              <br/>
                <Icon id="clock"></Icon>
              <span className='x-small-font'> {lodge_meeting.date_time} </span>
                <Icon id="globe"></Icon>
              <span className='x-small-font'>
              <a href={`http://maps.google.com/maps?q=${
                lodge_meeting.gps !== ''
                  ? lodge_meeting.gps
                  : ''
                }`} target="_blank">
                {lodge_meeting.address}
              </a>
              </span>
              <div>
                <span className="small-font-italic">
                  {lodge_meeting.sponsored ? 'Sponsored' : lodge_meeting.promoted ? 'Promoted' : ''}
                </span>
              </div>
              </Col>              
            </Row>

            <Row className="small-font">
              <Col>
              {/*lodge_meeting.description*/}
              {
                lodge_meeting.description.length > 100
                ? <span id={`meeting_description_cut_${index}`} className='show_post_description pre pre-code'>{lodge_meeting.description.substring(0, 200) + "..."}
                <Button className="small-font" color="#007bff" onClick={() => {this.getDescription(index)}}>
                  see more
                </Button>
                </span>
                : ''
              }
              <span id={`meeting_description_full_${index}`} className={`${
                lodge_meeting.description.length < 100
                  ? 'show_post_description pre pre-code'
                  : 'hide_post_description pre pre-code'
                }`}>
              {lodge_meeting.description}
              {lodge_meeting.post_type == 2
                ?
                <div>
                  <br/>
                  Contact: {lodge_meeting.contact_name}
                  <br/>
                  Tel: {lodge_meeting.contact_number}
                  <br/>
                  Email: {lodge_meeting.contact_email}
                </div>
                 : ''
               }
              </span>
              </Col>
            </Row>
            {/*}<Row className="x-small-font">
              <Col>
              <span>
              <a href={`http://maps.google.com/maps?q=${
                lodge_meeting.gps !== ''
                  ? lodge_meeting.gps
                  : ''
                }`} target="_blank">{lodge_meeting.address}</a>
              </span>
              </Col>
            </Row>*/}
            <Row>
              <Col id={`meeting_image_${index}`} sm="12" className="post-restricted-height">
              {
                lodge_meeting.post_type == 1
                ?
                <img alt='' className='feed-wall-image' src={lodge_meeting.image}/>
                :
                lodge_meeting.image != ''
                ?
                  lodge_meeting.image.includes('.pdf') ? (
                          <div>
                            <iframe src={process.env.REACT_APP_API_URL + lodge_meeting.image + "#toolbar=0"}
                                className="width-height-hundred"/>
                            {/*
                            <embed src={process.env.REACT_APP_API_URL + lodge_meeting.image+"#toolbar=0"} type="application/pdf" className="width-height-hundred"/>
                            <br/>
                            <iframe src={process.env.REACT_APP_API_URL + lodge_meeting.image+"#toolbar=0"} className="width-height-hundred"/>

                            <Document file="VdgHp8jloB.pdf" className="width-height-hundred" />
                            <Document file={process.env.REACT_APP_API_URL + lodge_meeting.image} className="width-height-hundred" />
                            */}
                          </div>
                      )
                      :
                      lodge_meeting.image.includes('.mp3') ? (
                              <audio controls>
                       <source src={process.env.REACT_APP_API_URL + lodge_meeting.image} type="audio/mp3" />
                       Your browser does not support the audio tag.
                      </audio>
                  ) :
                  lodge_meeting.image.includes('.mp4') ? (
                    <video controls className="video-container" preload="metadata">
                      <source src={process.env.REACT_APP_API_URL + lodge_meeting.image + "#t=1.75"} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) :
                  (
                    <img className="feed-wall-image" src={process.env.REACT_APP_API_URL + lodge_meeting.image} />
                  )
                : ''
              }
              </Col>
            </Row>
            <hr/>
            <Row className="row-justified-and-centered small-font">
              <Col>
              {
                lodge_meeting.post_type == 1 // meetings
                ?
                  checkIfMeetingNotLogged(lodge_meeting.date, lodge_meeting.lodge_id, loginStore.visitationMeetings)
                  ? <Button className="feed-post-log-attendance-step small-font" color=""
                    onClick={async () => {
                      if (window.confirm('Are you sure you want to log attendance for this meeting?')) {
                        //this.props.logVisitMeetingStep();
                        logVisitMeetingStore.logAttendance(
                          lodge_meeting.date,
                          lodge_meeting.time,
                          lodge_meeting.meeting_type,
                          lodge_meeting.lodge_id,
                          lodge_meeting.description_id,
                          lodge_meeting.meeting_description_extra,
                          lodge_meeting.address_id,
                          loginStore,
                          checkIfFuture(lodge_meeting.date +' '+ lodge_meeting.time),
                          {
                            'name': lodge_meeting.lodge_name,
                            'number': lodge_meeting.lodge_number,
                            'address': lodge_meeting.address,
                            'meeting_description': lodge_meeting.description
                          });
                      }
                  }}>
                        <Icon id="attend" text="Attend"></Icon>
                      {lodge_meeting.attendance > 0 ?
                      <sup className=""> ({lodge_meeting.attendance})</sup>
                      : ''}
                    </Button>
                  :
                  <Button className="feed-post-log-attendance-step small-font" color=""
                    onClick={async () => {
                      this.props.profileStep()
                  }}>
                    <Icon id="attending" accent_color="#2859C5" base_color="#8FBFFA" text="Attending"></Icon>
                      {lodge_meeting.attendance > 0 ?
                      <sup className=""> ({lodge_meeting.attendance})</sup>
                      : ''}
                    </Button>
                : lodge_meeting.post_type == 3 // user posts
                  ? (
                            <Button className="small-font" color=""
                                    onClick={async () => {
                                      //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                      //this.props.contactStep();
                                      let res = await feedWallStore.likePost(loginStore.miniToken, lodge_meeting.public_id);
                                      if (res) {
                                        toast.success("You have liked this post!");

                                        lodge_meeting.liked = !lodge_meeting.liked;

                                        // alternative to this.forceUpdate solution
                                        this.state.postsLiked[index] = !this.state.postsLiked[index];
                                        this.setState({postsLiked: this.state.postsLiked});

                                        // NB!!! not what I want to do here
                                        //this.forceUpdate();
                                        //viewProfileStore.setLiked(index);
                                      }
                                      else {
                                        toast.warning("You are unable to like this post!");
                                      }
                                    }}>

                              {this.state.postsLiked[index] ?
                                  <Icon id="like" accent_color="#2859C5" base_color="#8FBFFA" text="Liked"></Icon>
                                  :
                                  <Icon id="like" text="Like"></Icon>
                              }
                              {lodge_meeting.likes_count > 0 ?
                                  <sup> ({lodge_meeting.likes_count})</sup>
                                  :
                                  '' }
                            </Button>
                        )
                  : ''
              }
              </Col>
              <Col>
                {
                  lodge_meeting.post_type == 1
                  ? <Button className="feed-post-contact-step small-font" color=""
                    onClick={async () => {
                      contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                      this.props.contactStep();
                    }}>
                        <Icon id="contact" text="Contact"></Icon>
                    </Button>
                  : lodge_meeting.post_type == 3 ? (
                          <Button className="small-font" color=""
                                  onClick={async () => {
                                    //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                                    //this.props.contactStep();
                                    console.log("sending post data to comment modal");
                                    console.log(lodge_meeting);
                                    viewProfileStore.setPostData(lodge_meeting, index);
                                    viewProfileStore.toggleUserPostCommentModal();
                                  }}>
                            <Icon id="comment" text="Comment"></Icon>
                            {lodge_meeting.comments.length > 0 ?
                                <sup> ({lodge_meeting.comments.length})</sup>
                                :
                                ''}
                          </Button>
                  ) : ''
                }
              </Col>
              <Col>
                <Button className={`small-font ${
                  lodge_meeting.post_type == 1
                    ? 'feed-post-share-step'
                    : ''
                  }`} color=""
                  onClick={async () => {
                    if (window.confirm('Are you sure you want to share this meeting?')) {
                      let lnk = "";
                      if (lodge_meeting.post_type == 1) {
                        lnk = constructWAShareLink(lodge_meeting.date_time + "\n" + lodge_meeting.lodge_name + ' No. ' + lodge_meeting.lodge_number + "\n" + lodge_meeting.address + "\n\n" + lodge_meeting.description);
                      }
                      else if (lodge_meeting.post_type == 2) {
                        lnk = constructWAShareLink(lodge_meeting.date_time + "\n" + lodge_meeting.title + "\n" + lodge_meeting.address + "\n\n" + lodge_meeting.description + "\n\n" + "Contact: " + lodge_meeting.contact_name + "\n" + "Tel: " + lodge_meeting.contact_number + "\n" + "Email: " + lodge_meeting.contact_email);
                      }
                      else {
                        lnk = "";
                      }
                      //e.preventDefault();
                      //window.location.href=lnk;
                      window.open(lnk, "_blank");
                    }
                  }}>
                  <Icon id="share" text="Share"></Icon>
                </Button>
              </Col>

            </Row>

              { lodge_meeting.post_type == 3 && lodge_meeting.comments && lodge_meeting.comments.length > 0 ?
                  <div>
                    <hr/>
                    {/* comments sections - show the only one comment, the last/latest one */}

                    {lodge_meeting.comments.slice(0, 2).map((comment, index) => (
                            <div>
                              <Row>
                                <Col className="col-sm-1 post_header_logo">
                                  <img
                                      className="user-post-comment-image"
                                      src='logo.png'
                                      alt='logo'
                                      height='20px'
                                      onClick={() => {
                                        //this.props.profileStep();
                                      }}
                                  />
                                </Col>
                                <Col>
                                  <div className="user-post-comment">
                                    <span className="x-small-font"><b>{comment.name}</b></span>
                                    <br/>
                                    <span className="small-font">{comment.comment}</span>
                                  </div>
                                  <br/>
                                </Col>
                              </Row>
                            </div>
                        )
                    )}
                  </div>
                  : ''
              }
            </CardBody>
            </Card>
          ))}
          </div>


          )
          : 'No news at the moment'}
        </div>

        <InfiniteScroll
          dataLength={this.state.userFeedUpcomingMeetings.length}
          next={this.fetchMoreData}
          hasMore={true}
          loader={
            <Card className="post-option-active">
            <CardBody className="top-buttons">
              <Row className="row-justified-and-centered">
                <Col>
                  <div id='navigation_buttons'>
                    <Button className="small-font" color=""
                            onClick={async () => {
                              //contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                              //this.props.contactStep();
                              feedWallStore.scrollCount = 0;
                              //await feedWallStore.getUserFeedWallOnly(loginStore.miniToken);
                              await this.fetchMoreData();
                            }}>
                      <Icon id="reload" text="Refresh"></Icon>
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
            </Card>
          }
        >
        </InfiniteScroll>


        <Modal isOpen={this.state.promotedPostOpen}>
          <ModalHeader
            toggle={() => this.setState({ promotedPostOpen: false })}
          ></ModalHeader>
          <ModalBody>
            <PromotedPost />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ promotedPostOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.sponsoredPostOpen}>
          <ModalHeader
            toggle={() => this.setState({ sponsoredPostOpen: false })}
          ></ModalHeader>
          <ModalBody>
            <SponsoredPost />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ sponsoredPostOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.setTourIsClosed}
        onAfterOpen={this.props.setLoadingTrue}
        onBeforeClose={this.props.setLoadingFalse}
        showNavigation={false}
        />
      </div>
    );
  }
}

export default FeedWall;
