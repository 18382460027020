import { observable, action } from 'mobx';
import { SuggestEditContact } from '../domain/suggest-edit-contact';
import api from '../api';
/*import {
  validateName,
  validateEmail,
  validateCellphone
} from '../helpers/validation';*/

export class SuggestEditContactStore {
  @observable isLoading = false;

  @observable contactInput: Partial<SuggestEditContact> = {
    title: '',
    name: '',
    surname: '',
    contact_number: '',
    contact_number_second: '',
    website: '',
    email: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  setSuggestEditContactData = (lodgeData: any) => {
    if (lodgeData.length > 0) {
      const lodge = lodgeData[0];
      const two_parts = lodge.contact.split(' ');
      this.contactInput.title = '';//lodge.title;
      this.contactInput.name = two_parts[0];
      this.contactInput.surname = two_parts[1];//lodge.surname;
      this.contactInput.contact_number = lodge.contact_number;
      this.contactInput.contact_number_second = '';//lodge.contact_number_second;
      this.contactInput.website = lodge.website;
      this.contactInput.email = lodge.email;
    }
  };

  clearContactInput = () => {
    //contactInput
    console.log('clearing edit contact input');
  };

}

export const suggestEditContactStore = new SuggestEditContactStore();
(window as any).suggestEditContactStore = suggestEditContactStore;
