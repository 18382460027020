import { observable, action } from 'mobx';
import { FAQs } from '../domain/faqs';
import api from '../api';

export class FAQsStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];

  @observable toggleQADivs: { [k: number]: boolean } = {};

  constructor() {

  }




}

export const fAQsStore = new FAQsStore();
(window as any).fAQsStore = fAQsStore;
