import { observable, action } from 'mobx';
import { RaffleCode } from '../domain/raffle-code';
import api from '../api';

export class RaffleCodeStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {}

}

export const raffleCodeStore = new RaffleCodeStore();
(window as any).raffleCodeStore = raffleCodeStore;
