import Cookies from 'js-cookie';

/* global fetch:false Request:false */
//const key = process.env.REACT_APP_API_KEY;
//const auth = 'Bearer ' + key;
const sandbox = process.env.REACT_APP_SANDBOX;
const basePath = process.env.REACT_APP_API_URL;
let xxsrftoken = 'eyJpdiI6IjNNaXZ4OTZSeld6MU5XcUZoTHNGbnc9PSIsInZhbHVlIjoiU0kwTnZic3o2dGJWd3ZBaVZWcThsbW1kZkFaNW1uS1RRR2pJTWh0TzB6V05wNElyK29wQWdjbElmMHcvVmN4UnBHblRwakZnRnpmT0Y4RFJXdE5DTS9kZWUwUW9tM015N04xWmlXMDlObkoyd2ZQT2xyNzA5dXhFL3pPMEU4Um0iLCJtYWMiOiI2NDU2NGI4MmEwZTRjZTdkMjBjYWNhNjZmNjE3ZGE5ZDFlZTE4YTkxNTUxOTg1YzNjODNmNGYxNDBjN2VlNTdmIiwidGFnIjoiIn0=';


const makeRequestX = (method, data, auth, path) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + auth,
      'Accept': '*/*',
      'Access-Control-Allow-Origin': 'http://127.0.0.1:3000',
      'Access-Control-Allow-Credentials' : true,
      'X-XSRF-TOKEN': xxsrftoken // Uncomment if you need to send an XSRF token
    },
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include', // Include credentials (cookies) in cross-origin requests
  };

  const request = new Request(basePath + path);

  return fetch(request, options)
      .then((response) => {
        return response.json().then((r) => {
          if (response.ok) { // Use response.ok for better readability
            console.log('Response:', r);
            return r;
          } else {
            return Promise.reject(r);
          }
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        throw error; // Re-throw the error for further handling if necessary
      });
};


const makeRequest = (method, data, auth, path) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + auth,
      //'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
      //'X-XSRF-TOKEN': xxsrftoken
    },
    //body: data && JSON.stringify(data),
    body: data ? JSON.stringify(data) : undefined,
  };
  const request = new Request(basePath + path);
  /*return fetch(request, options).then((response) => {
    return response.json().then((r) => {
      //if (response.status === 200) {
      if (response.ok) {
        console.log('response.ok');
        console.log(r);
        return r;
      }
      else {
        console.log('reject');
        return Promise.reject(r);
      }
    });
  });*/
  return fetch(request, options)
      .then((response) => {
        return response.text().then((text) => {
          if (response.ok) {
            //console.log("response");
            //console.log(response);
            console.log("xsrfToken");
            const xsrfToken = Cookies.get('XSRF-TOKEN');
            console.log(xsrfToken);
            const r = text ? JSON.parse(text) : {};
            console.log('response.ok');
            console.log(r);
            return r;
          } else {
            console.log('reject');
            return Promise.reject(text);
          }
        });
      })
      .catch((error) => {
        console.error('Error occurred:', error);
        throw error; // Re-throw if you need to handle it further up
      });
};

const makeRequestWithToken = (method, token, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth,
      // 'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
      /*'CSRF-TOKEN': token*/
    },
    body: data && JSON.stringify(data),
  };
  console.log('************');
  console.log(JSON.stringify(data));
  console.log('************');
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    // console.log(request);
    // console.log(options);
    // console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

const makeRequestWithFileData = (method, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': auth,
      //'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
    },
    body: data && JSON.stringify(data),
  };
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    console.log(request);
    console.log(options);
    console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

export default {
  // getToken: (data) => makeRequest('GET', data, '/api/token'),
  // Lodge endpoints
  getAllLodges: (data, auth) => makeRequest('GET', null, auth, '/api/lodges'),
  getAllLodgesSearch: (search, country, district, constitution, degree, address, today, data, auth) => makeRequest('POST', data, auth, `/api/lodges-search/${search}/${country}/${district}/${constitution}/${degree}/${address}/${today}`),
  getAllTheLodges: (constitution, data, auth) => makeRequest('GET', null, auth, `/api/thelodges/${constitution}`),
  getLodgeInfo: (lodge_name, lodge_number, degree, data, auth) => makeRequest('GET', null, auth, `/api/thelodgeinfo/${lodge_name}/${lodge_number}/${degree}`),
  getLodgeInfoTwo: (data, auth) => makeRequest('POST', data, auth, `/api/thelodgeinfotwo`),
  getLodgeMeetings: (lodge_name, lodge_number, degree, data, auth) => makeRequest('GET', null, auth, `/api/getlodgemeetings/${lodge_name}_${lodge_number}_${degree}`),
  getLodgeMeetingsTwo: (lodge_name, lodge_number, degree, data, auth) => makeRequest('GET', null, auth, `/api/getlodgemeetingstwo/${lodge_name}_${lodge_number}_${degree}`),
  getLodgeInfoById: (lodge_id, data, auth) => makeRequest('GET', null, auth, `/api/thelodgeinfobyid/${lodge_id}`),
  getLodgeMeetingsById: (lodge_id, data, auth) => makeRequest('GET', null, auth, `/api/getlodgemeetingsbyid/${lodge_id}`),
  getLodgeMeetingsTwoById: (lodge_id, data, auth) => makeRequest('GET', null, auth, `/api/getlodgemeetingstwobyid/${lodge_id}`),
  getLodgeFeed: (data, auth) => makeRequest('POST', data, auth, `/api/getLodgeFeed`),

  // Suggestion endpoints
  makeSuggestion: (data, auth) => makeRequest('POST', data, auth, '/api/MakeSuggestion'),
  // Contribute endpoints
  //getAllSuggestedEdits: (data) => makeRequest('GET', null, '/api/AllSuggestedEdits'),
  getAllSuggestedEdits: (data, auth) => makeRequest('POST', data, auth, '/api/AllSuggestedEdits'),
  // Cast Vote endpoint
  castVote: (data, auth) => makeRequest('POST', data, auth, '/api/CastVote'),

  loginUser: (data, auth) => makeRequest('POST', data, auth, '/api/login'),

  confirmUser: (data, auth) => makeRequest('POST', data, auth, '/api/confirmuser'),
  confirmCode: (data, auth) => makeRequest('POST', data, auth, '/api/confirmcode'),
  changePassword: (data, auth) => makeRequest('POST', data, auth, '/api/changenreset'),

  getMyCookie: (data, auth) => makeRequest('GET', null, auth, `/sanctum/csrf-cookie`),
  // Configuration
  //getAllAddresses: (data) => makeRequest('GET', null, '/api/getAddresses'),
  //getAllConstitutions: (data) => makeRequest('GET', null, '/api/getConstitutions'),
  //getAllCountries: (data) => makeRequest('GET', null, '/api/getCountries'),
  //getAllDistricts: (data) => makeRequest('GET', null, '/api/getDistricts'),
  //getAllDegrees: (data) => makeRequest('GET', null, '/api/getDegrees'),
  //getAllDescriptions: (data) => makeRequest('GET', null, '/api/getDescriptions'),
  // to replace the above 6 api calls with 1
  getConfigurations: (data, auth) => makeRequest('GET', null, auth, '/api/getConfigurations'),


  // Diagram
  getDiagramData: (data, auth) => makeRequest('GET', null, auth, '/api/getDiagramData'),

  // Raffle Users
  getAllUsers: (data, auth) => makeRequest('GET', null, auth, '/api/getAllUsers'),

  // 2FA Code
  // generateTwoFA: data => makeRequest('GET', data, '/api/gen2FA'),

  // START ----- these wont have auth so cant send auth
  // Confirm Referral Code
  confirmReferralCode: (data, auth) => makeRequest('POST', data, auth, '/api/register-pre'),
  // Register User
  registerUser: (data, auth) => makeRequest('POST', data, auth, '/api/register'),
  // 2FA Code Confirmation
  confirm2FACode: (data, auth) => makeRequest('POST', data, auth, '/api/register-confirmation'),
  // END ----- these wont have auth so cant send auth

  // contact lodge
  contactLodge: (data, auth) => makeRequest('POST', data, auth, '/api/make-contact'),

  // contact lodge
  provideFeedback: (data, auth) => makeRequest('POST', data, auth, '/api/user-feedback'),

  // ???? what is this ????
  // contact lodge
  //claimMembership: (data) => makeRequest('POST', data, '/api/membership-claim'),

  //logVisit: (data, auth) => makeRequest('POST', data, auth, '/api/logVisit'),

  updateNotifications: (data, auth) => makeRequest('POST', data, auth, '/api/updateNotifications'),

  updateWatchList: (data, auth) => makeRequest('POST', data, auth, '/api/updateWatchList'),

  getUserWatchList: (data, auth) => makeRequest('POST', data, auth, '/api/getUserWatchList'),

  logAttendance: (data, auth) => makeRequest('POST', data, auth, '/api/logAttendance'),
  removeLogAttendance: (data, auth) => makeRequest('POST', data, auth, '/api/removeLogAttendance'),

  getAllImpromptuMeetings: (data, auth) => makeRequest('POST', data, auth, '/api/getAllImpromptuMeetings'),

  getAllLeaderboards: (data, auth) => makeRequest('POST', data, auth, '/api/getAllLeaderboards'),

  getAchievementsStructureData: (data, auth) => makeRequest('POST', data, auth, '/api/getAchievementsStructureData'),

  getUserFeed: (data, auth) => makeRequest('POST', data, auth, '/api/getUserFeed'),
  getUserFeedWallOnly: (data, auth) => makeRequest('POST', data, auth, '/api/getUserFeedWallOnly'),

  getUserPublicProfile: (data, auth) => makeRequest('POST', data, auth, '/api/getUserPublicProfile'),

  getUserProfileFeed: (data, auth) => makeRequest('POST', data, auth, '/api/getUserProfileFeed'),

  postToUserProfileFeed: (data, auth) => makeRequest('POST', data, auth, '/api/postToUserProfileFeed'),

  sponsoredPostImpression: (data, auth) => makeRequest('POST', data, auth, '/api/sponsoredPostImpression'),

  cancelSponsoredPost: (data, auth) => makeRequest('POST', data, auth, '/api/cancelSponsoredPost'),

  promotePost: (data, auth) => makeRequest('POST', data, auth, '/api/promotePost'),

  likeUserPost: (data, auth) => makeRequest('POST', data, auth, '/api/likeUserPost'),

  commentOnUserPost: (data, auth) => makeRequest('POST', data, auth, '/api/commentOnUserPost'),

  getUserTransactions: (data, auth) => makeRequest('POST', data, auth, '/api/getUserTransactions'),

  getSpecifiedMonthsCalendar: (data, auth) => makeRequest('POST', data, auth, '/api/getSpecifiedMonthsCalendar'),

  validateReceivalCode: (data, auth) => makeRequest('POST', data, auth, '/api/validateReceivalCode'),
  getTransferConfirmationCode: (data, auth) => makeRequest('POST', data, auth, '/api/getTransferConfirmationCode'),
  validateConfirmationCode: (data, auth) => makeRequest('POST', data, auth, '/api/validateConfirmationCode'),

  editUserPost: (data, auth) => makeRequest('POST', data, auth, '/api/editUserPost'),

  /*getQuote: (type, data) => makeRequest('POST', { type, ...data }, '/v1/insurance/quotes'),
  createPolicyholder: data => makeRequest('POST', data, '/v1/insurance/policyholders'),
  updatePolicyholder: data => makeRequest('PUT', data, `/v1/insurance/policyholders`),
  createApplication: data => makeRequest('POST', data, '/v1/insurance/applications'),
  createPolicy: data => makeRequest('POST', data, '/v1/insurance/policies'),
  addPaymentMethod: (policyholderId, data) =>
    makeRequest('POST', data, `/v1/insurance/policyholders/${policyholderId}/payment-methods`),
  createAttachment: (policyId, data) => makeRequest('POST', data, `/v1/insurance/policies/${policyId}/attachments`),
  createBeneficiaries: (policyId, data) => makeRequest('PUT', data, `/v1/insurance/policies/${policyId}/beneficiaries`),
  callRequest: data => makeRequest('POST', data, '/call-request'),*/
};
