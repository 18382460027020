// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { MembershipClaimStore } from '../../stores/membership-claim-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  membershipClaimStore?: MembershipClaimStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  proposer: string;
  seconder: string;
  joined: string;
}

@inject('membershipClaimStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore')
@observer
export class MembershipClaim extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      proposer: '',
      seconder: '',
      joined: ''
    };
  }

  render() {
    if (!this.props.membershipClaimStore) {
      return;
    }

    const membershipClaimStore = this.props.membershipClaimStore;
    console.log("------- membershipClaimStore.lodgeResults -------");
    console.log(membershipClaimStore.lodgeResults);
    console.log("---------------------------------------");

    const membershipClaimInput = membershipClaimStore.membershipClaimInput;
    const validationResult = membershipClaimStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'message';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Membership Claim</u></h4>
          </Col>
        </Row>

          <Card className='post-option-active'>
              <CardBody>
                <Row>
                  <Col>
                    <TextInput
                      id="proposer"
                      label="Proposer"
                      value={this.state.proposer}
                      onChange={e => {
                          membershipClaimInput.proposer = e.target.value;
                          this.setState({proposer: e.target.value});
                        }
                      }
                      invalid={!!validationResult.proposer}
                      validationMessage={validationResult.proposer}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      id="seconder"
                      label="Seconder"
                      value={this.state.seconder}
                      onChange={e => {
                          membershipClaimInput.seconder = e.target.value;
                          this.setState({seconder: e.target.value});
                        }
                      }
                      invalid={!!validationResult.seconder}
                      validationMessage={validationResult.seconder}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <DateInput
                        label="Date Joined"
                        invalid={!!validationResult.joined}
                        validationMessage={validationResult.joined}
                        value={this.state.joined}
                        onChange={e => {
                          membershipClaimInput.joined = e.target.value;
                          this.setState({joined: e.target.value});
                        }}
                        id="joined"
                      />
                  </Col>
                </Row>

                <br/>
                <Button color="" onClick={async () => {
                  //toast.info("TEST!");

                  membershipClaimStore.validate();
                  const success = membershipClaimStore.checkValidation();
                  console.log('----');
                  console.log(success);
                  console.log('----');
                  if (success) {
                    if (await membershipClaimStore.claimMembership(
                          lodgeDetailsStore.lodgeInfo[0].id,
                          membershipClaimInput.proposer,
                          membershipClaimInput.seconder,
                          membershipClaimInput.joined,
                          loginStore
                        )) {
                        toast.success("Your claim has been logged!");
                        this.props.thankYouStep();
                    }
                    else {
                      toast.warning("Could not log your claim!");
                    }
                  }
                }}>
                  <Icon id="send" text="Submit"></Icon>
                </Button>
                <Button color="" onClick={this.props.lodgeDetailsStep}>
                  <Icon id="back" text="Back"></Icon>
                </Button>
            </CardBody>
        </Card>

      </div>
    );
  }
}

export default MembershipClaim;
