// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { NewLocationStore } from '../../stores/new-location-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';
import {Icon} from "../svg_icons/icon";

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  feedWallStep: () => void;
  searchStep: () => void;
  newLocationStore?: NewLocationStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
    clicked: boolean;
    valid: any; // change this
    name: string;
    address: string;
    city: string;
    region_name: string;
    country: string;
    latitude: string;
    longitude: string;
}

@inject('newLocationStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore', 'configurationStore')
@observer
export class NewLocation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
        valid: {},
        clicked: false,
        name: '',
        address: '',
        city: '',
        region_name: '',
        country: '',
        latitude: '',
        longitude: ''
    };
  }

  render() {
    if (!this.props.newLocationStore) {
      return;
    }

    const newLocationStore = this.props.newLocationStore;
    console.log("------- newLocationStore.lodgeResults -------");
    console.log(newLocationStore.lodgeResults);
    console.log("---------------------------------------");

    const newLocationInput = newLocationStore.newLocationInput;
    const validationResult = newLocationStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const configurationStore = this.props.configurationStore;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'suggestion';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
            <Col sm="12">
                <h4><u>New Location</u></h4>
            </Col>
        </Row>

          <Card className='post-option-active'>
              <CardBody>
                  <Row>
                      <Col>
                          <TextInput
                              id="name"
                              label="Name"
                              value={this.state.name}
                              onChange={e => {
                                  newLocationInput.name = e.target.value;
                                  this.setState({name: e.target.value});
                                }
                              }
                              invalid={!!validationResult.name}
                              validationMessage={validationResult.name}
                          />
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <TextInput
                              id="address"
                              label="Address"
                              value={this.state.address}
                              onChange={e => {
                                  newLocationInput.address = e.target.value;
                                  this.setState({address: e.target.value});
                                }
                              }
                              invalid={!!validationResult.address}
                              validationMessage={validationResult.address}
                          />
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <TextInput
                              id="city"
                              label="City"
                              value={this.state.city}
                              onChange={e => {
                                  newLocationInput.city = e.target.value;
                                  this.setState({city: e.target.value});
                                }
                              }
                              invalid={!!validationResult.city}
                              validationMessage={validationResult.city}
                          />
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <TextInput
                              id="region_name"
                              label="Region"
                              value={this.state.region_name}
                              onChange={e => {
                                  newLocationInput.region_name = e.target.value;
                                  this.setState({region_name: e.target.value});
                                }
                              }
                              invalid={!!validationResult.region_name}
                              validationMessage={validationResult.region_name}
                          />
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <SelectInput
                              id='country'
                              label='Country'
                              invalid={!!validationResult.country}
                              validationMessage={validationResult.country}
                              value={this.state.country}
                              onChange={e => {
                                  newLocationInput.country = e.target.value;
                                  this.setState({country: e.target.value});
                              }}
                              options={configurationStore.countryResults}
                          />
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <TextInput
                              id="latitude"
                              label="Latitude"
                              value={this.state.latitude}
                              onChange={e => {
                                  newLocationInput.latitude = e.target.value;
                                  this.setState({latitude: e.target.value});
                                }
                              }
                              invalid={!!validationResult.latitude}
                              validationMessage={validationResult.latitude}
                          />
                      </Col>
                      <Col>
                          <TextInput
                              id="longitude"
                              label="Longitude"
                              value={this.state.longitude}
                              onChange={e => {
                                  newLocationInput.longitude = e.target.value;
                                  this.setState({longitude: e.target.value});
                                }
                              }
                              invalid={!!validationResult.longitude}
                              validationMessage={validationResult.longitude}
                          />
                      </Col>
                  </Row>

                  <div id='navigation_buttons'>
                      <Button color="" onClick={async () => {
                          //toast.info("TEST!");

                          newLocationStore.validate();
                          const success = newLocationStore.checkValidation();
                          console.log('----');
                          console.log(success);
                          console.log('----');
                          if (success) {
                              if (await newLocationStore.addLocation(
                                  newLocationInput.name,
                                  newLocationInput.address,
                                  newLocationInput.city,
                                  newLocationInput.region_name,
                                  newLocationInput.country,
                                  newLocationInput.latitude,
                                  newLocationInput.longitude,
                                  loginStore
                              )) {
                                  toast.success("Your suggested location has been logged!");
                                  this.props.thankYouStep();
                              } else {
                                  toast.warning("Could not log your suggested location!");
                              }
                          }
                      }}>
                          <Icon id="send" text="Submit"></Icon>
                      </Button>
                      <Button color="" onClick={() => {
                          this.props.searchStep();
                      }}>
                          <Icon id="back" text="Back"></Icon>
                      </Button>
                  </div>
              </CardBody>
          </Card>

      </div>
    );
  }
}

export default NewLocation;
