// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody, ModalHeader, ModalBody, ModalFooter, Modal,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  getCurrentYear,
  getPreviousDate,
  getNextDate,
  convertDate,
  getNumberOfDaysInMonth,
  theDay,
  matchMeetingDates,
  convertDateTwo,
  getNameOfDay,
} from '../../helpers/calendar';

import { schedulePlannerStore, SchedulePlannerStore } from '../../stores/schedule-planner-store';
import { ContributeStore } from '../../stores/contribute-store';
import { loginStore, LoginStore } from '../../stores/login-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import {Icon} from "../svg_icons/icon";

export const MeetingPlanner: React.FC<Props> = (props) => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Meeting Planner : <span className="small-font">{schedulePlannerStore.theViewedDay}</span></h2>
        <div>
          {
            schedulePlannerStore.dayLodgesMeet && schedulePlannerStore.dayLodgesMeet.length > 0 ? (
                schedulePlannerStore.dayLodgesMeet.map((lodge, index) => (
                    <div className={`small-font ${
                        lodge.matched
                            ? 'matched'
                            : ''
                    }`}>
                      <span>{lodge.time}: </span>
                      <a href="#"
                         onClick={async () => {
                           props.setLoadingTrue();
                           props.lodgeDetailsStore.clearLodgeInfo();
                           await props.lodgeDetailsStore.setLodgeInfo(
                               lodge.name,
                               lodge.number,
                               lodge.degree_id,
                               loginStore.miniToken,
                               loginStore.lodges,
                           );
                           props.lodgeDetailsStore.previousPage = 'schedule-planner';
                           props.lodgeDetailsStep();
                           props.setLoadingFalse();
                         }}>{lodge.name} No. {lodge.number} {lodge.short_constitution} ({lodge.degree})</a>
                    </div>
                ))
            ) : 'No meetings...'
          }
        </div>
      </p>
    </div>
  );
};


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  profileStep: () => void;
  lodgeDetailsStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  schedulePlannerStore?: SchedulePlannerStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  lodgeDetailsStore?: LodgeDetailsStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  meetingPlannerOpen: boolean;
  page: number;
}

@inject('schedulePlannerStore', 'contributeStore', 'loginStore', 'lodgeDetailsStore')
@observer
export class SchedulePlanner extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      meetingPlannerOpen: false,
      page: 2
    };
  }

  //testing padding ======
  // Function to get the first day of the month (0 = Sunday, 1 = Monday, etc.)
  getFirstDayOfMonth = (date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay.getDay(); // Returns the weekday (0 = Sunday, 6 = Saturday)
  };

  generateCalendarDays = () => {
    const numberOfDaysInMonth = getNumberOfDaysInMonth(schedulePlannerStore.selectedDate);
    const firstDayOfMonth = this.getFirstDayOfMonth(schedulePlannerStore.selectedDate);

    // Create the days array with padding at the start
    const paddedDays = [
      ...Array(firstDayOfMonth).fill(null), // Add padding (null or any placeholder)
      ...Array.from({ length: numberOfDaysInMonth }, (_, i) => i + 1), // Actual days of the month
    ];

    console.log("paddedDays");
    console.log(paddedDays);
    return paddedDays;
  };
  //====== testing padding

  render() {
    if (!this.props.schedulePlannerStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const schedulePlannerStore = this.props.schedulePlannerStore;

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Schedule Planner</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody className='top-buttons'>
            <Row className='row-justified-and-centered'>
              <Col>
                <div id='navigation_buttons'>
                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      //this.props.previousStep();
                      //clearInterval(this.props.lodgeDetailsStore.theTimer);
                      //this.props.lodgeDetailsStore.theTimer = null;
                      //this.props.lodgeDetailsStore.todaysMeetingTime = '';

                      this.props.profileStep();
                    }}
                  >
                    <Icon id="back" text="Back"></Icon>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      //this.props.previousStep();
                      //clearInterval(this.props.lodgeDetailsStore.theTimer);
                      //this.props.lodgeDetailsStore.theTimer = null;
                      //this.props.lodgeDetailsStore.todaysMeetingTime = '';

                      schedulePlannerStore.page = 2;
                      this.setState({page: 2});
                    }}
                  >
                    <Icon id="calendar" text="Calendar View"></Icon>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      //this.props.nextStep();
                      //clearInterval(this.props.lodgeDetailsStore.theTimer);
                      //this.props.lodgeDetailsStore.theTimer = null;
                      //this.props.lodgeDetailsStore.todaysMeetingTime = '';

                      schedulePlannerStore.page = 1;
                      this.setState({page: 1});
                    }}
                  >
                    <Icon id="list" text="List View"></Icon>
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className='post-option-active'>
          <CardBody>
            <span className='small-font-italic'>
              {this.state.page == 1 ? (
                  loginStore.visitationMeetingsFuture.length > 0 ? (
                    <div>
                              {loginStore.visitationMeetingsFuture.map((visit, index) => (
                                  <div>
                        <span className='small-font'>
                          <b>
                            {visit.datetime} - {visit.lodge_name} No. {visit.lodge_number} -{' '}
                            {parseInt(visit.meeting_type_id) === 1
                                ? `Meeting -  ${visit.meeting_description}`
                                : parseInt(visit.meeting_type_id) === 3
                                    ? `Emergency Meeting -  ${visit.meeting_description}`
                                    : 'Installation'}
                          </b>
                          <b>
                            {parseInt(visit.meeting_description_id) === 9
                                ? ' - ' + visit.meeting_description_extra
                                : ''}
                          </b>
                          <br/>
                          {visit.location_name}, {visit.location_address}, {visit.location_city}
                        </span>
                                  </div>
                              ))}
                            </div>
                  )
                  :
                  'Your schedule is empty'
              ) : this.state.page == 2 ? (
                  <div className='calendar'>
                    <div className='row-justified-and-centered-flex'>
                      <h4>{schedulePlannerStore.selectedDateString}</h4>
                    </div>
                    <div className='row-justified-and-centered-flex'>
                      <Button
                          className=''
                          color=''
                          onClick={async () => {
                            schedulePlannerStore.selectedDate = getPreviousDate(schedulePlannerStore.selectedDate);
                            schedulePlannerStore.selectedDateString = convertDate(schedulePlannerStore.selectedDate);
                            schedulePlannerStore.getMonthsMeetings(loginStore);
                            this.forceUpdate();
                          }}
                      >
                        <span className='feed-button-text'>Prev </span>
                        <Icon id="prev"></Icon>
                      </Button>

                      <Button
                        className=''
                        color=''
                        onClick={async () => {
                          schedulePlannerStore.selectedDate = getNextDate(schedulePlannerStore.selectedDate);
                          schedulePlannerStore.selectedDateString = convertDate(schedulePlannerStore.selectedDate);
                          schedulePlannerStore.getMonthsMeetings(loginStore);
                          this.forceUpdate();
                        }}
                      >
                        <Icon id="next"></Icon>
                        <span className='feed-button-text'> Next</span>
                      </Button>
                    </div>
                    <p>...</p>
                    <div className='ul'>
                      {this.generateCalendarDays().map((day, index) => (
                          day != null ?
                              <div
                                  className={`li ${
                                      matchMeetingDates(
                                          loginStore.visitationOtherUsersMeetingsFuture,
                                          theDay(schedulePlannerStore.selectedDate, day),
                                      ) > 0
                                          ? 'blue'
                                          : ''
                                  }`}
                                  onClick={async () => {
                                    await schedulePlannerStore.getDaysMeetings(
                                        theDay(schedulePlannerStore.selectedDate, day),
                                        loginStore.visitationOtherUsersMeetingsFuture,
                                    );
                                    this.setState({meetingPlannerOpen: true});
                                    //schedulePlannerStore.theViewedDay = (schedulePlannerStore.selectedDateString) + ', ' + (day + 1) + ' ' + getNameOfDay(theDay(schedulePlannerStore.selectedDate, day + 1));
                                    schedulePlannerStore.theViewedDay = (schedulePlannerStore.selectedDateString) + '-' + (day).toString().padStart(2, '0');
                                  }}
                              >
                                <time>{day}</time>
                                <span>{getNameOfDay(theDay(schedulePlannerStore.selectedDate, day))}</span>
                                {/*theDay(schedulePlannerStore.selectedDate, day+1)*/}
                                {matchMeetingDates(
                                    loginStore.visitationOtherUsersMeetingsFuture,
                                    theDay(schedulePlannerStore.selectedDate, day),
                                ) > 0
                                    ? matchMeetingDates(
                                        loginStore.visitationOtherUsersMeetingsFuture,
                                        theDay(schedulePlannerStore.selectedDate, day),
                                    )
                                    : ''}
                              </div>
                              :
                              <div className="li blank">
                                <time></time>
                                <span></span>
                              </div>
                      ))}
                    </div>
                  </div>
                  )
                  : (
                      'unknown view'
                  )
              }
            </span>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.meetingPlannerOpen} props={this.props}>
          <ModalHeader toggle={() => this.setState({meetingPlannerOpen: false})}></ModalHeader>
          <ModalBody>
            <MeetingPlanner
                contributeStep={this.props.contributeStep}
                lodgeDetailsStep={this.props.lodgeDetailsStep}
                lodgeDetailsStore={this.props.lodgeDetailsStore}
                previousStep={this.props.previousStep}
                nextStep={this.props.nextStep}
                searchStep={this.props.searchStep}
                profileStep={this.props.profileStep}
                setLoadingTrue={this.props.setLoadingTrue}
                setLoadingFalse={this.props.setLoadingFalse}
            />
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => this.setState({meetingPlannerOpen: false})}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SchedulePlanner;
