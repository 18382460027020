import { observable, action } from 'mobx';
import { AddContact } from '../domain/add-contact';
import api from '../api';
import {
  validateName,
  validateEmail,
  validateCellphone
} from '../helpers/validation';

export class AddContactStore {
  @observable isLoading = false;

  @observable contactInput: Partial<AddContact> = {
    title: '',
    name: '',
    surname: '',
    contact_number: '',
    contact_number_second: '',
    website: '',
    email: '',

    country: '',
    district: '',
    number: '',
    constitution: '',
    degree: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.contactInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.name = !validateName(
      request.name
    )
      ? '* Required'
      : '';

    this.validationResult.surname = !validateName(
      request.surname
    )
      ? '* Required'
      : '';

    //this.validationResult.contact_number = !validateCellphone(
    //  request.contact_number
    //)
    //  ? '* Required'
    //  : '';

    this.validationResult.email = !validateEmail(
      request.email
    )
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  clearContactInput = () => {
    //contactInput
    console.log('clearing contact input');
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

}

export const addContactStore = new AddContactStore();
(window as any).addContactStore = addContactStore;
