import { observable, action } from 'mobx';
import { Contact } from '../domain/contact';
import api from '../api';

export class ContactStore {
  @observable isLoading = false;

  @observable contactInput: Partial<Contact> = {
    message: '',
    type: '',
    dining: false,
    lodge_id: 0
  };
  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  setContactLodgeId = (lodge_id) => {
    this.contactInput.lodge_id = lodge_id;
  }

  makeContact = async (lodge_id, message, type, dining, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          lodge_id: (lodge_id != undefined) ? lodge_id : this.contactInput.lodge_id,
          message: message,
          type: type,
          dining: dining,
          miniToken: loginStore.miniToken
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      const result2 = await api.contactLodge(dat, loginStore.authToken);
      //console.log('======= contactLodge =======');
      //console.log(result2);
      //console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };
}

export const contactStore = new ContactStore();
(window as any).contactStore = contactStore;
