import { observable, action } from 'mobx';
import { MembershipClaim } from '../domain/membership-claim';
import api from '../api';

export class MembershipClaimStore {
  @observable isLoading = false;

  @observable membershipClaimInput: Partial<MembershipClaim> = {
    message: '',
    type: '',
    dining: false,
    lodge_id: 0,
    proposer: '',
    seconder: '',
    joined: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = { };
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.membershipClaimInput;
    this.validationResult = { };
    this.fieldsToFill = [];

    this.validationResult.proposer = (request.proposer == '')
      ? '* Required'
      : '';

    this.validationResult.seconder = (request.seconder == '')
      ? '* Required'
      : '';

    this.validationResult.joined = (request.joined == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getSelectedLodge = () => {
    return this.lodgeResults[this.selectedLodgeIndex];
  };

  getAllLodges = async () => {
    /*
    this.validate();
    if (this.hasValidationError()) {
      return;
    }

    const quoteInCents = this.getApiQuoteInput();
    */
    this.isLoading = true;
    try {
      const result = await api.getAllLodges([]);
      console.log('======= getAllLodges =======');
      this.lodgeResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setSelectedLodgeIndex = (index: number) => {
    this.selectedLodgeIndex = index;
    console.log('setting lodge index');
  };

  setLodges = () => {
    this.getAllLodges();
    console.log('getting all lodges');
  };

  claimMembership = async (lodge_id, proposer, seconder, joined, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggestion_type : '5',
          action : '0',
          lodge_id: lodge_id,
          user_id: 0,// 0 for an action of 0 which is add, it the user who create it is claiming membership
          proposer: proposer,
          seconder: seconder,
          joined: joined
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      //const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.claimMembership(result.token, dat);
      const result2 = await api.makeSuggestion(dat, loginStore.authToken);
      console.log(result2);
      console.log('======= makeSuggestion =======');
      console.log(result2.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  get selectedLodge () {
    return this.lodgeResults[this.selectedLodgeIndex || 0];
  }
}

export const membershipClaimStore = new MembershipClaimStore();
(window as any).membershipClaimStore = membershipClaimStore;
