import moment from 'moment';

export const ageFromDateOfBirth = (dateOfBirth) => {
  return moment().diff(moment(dateOfBirth), 'years');
};

export const dateOfBirthFromID = (id) => {
  let birthDate = moment(id.slice(0, 6), 'YYMMDD');
  if (birthDate.isSameOrAfter(moment())) {
    birthDate = moment('19' + id.slice(0, 6), 'YYYYMMDD');
  }
  return birthDate;
};

export const ageFromID = (idNumber) => {
  const birthDate = dateOfBirthFromID(idNumber);
  const age = moment().diff(birthDate, 'years');
  return age;
};
