import { observable, action } from 'mobx';
import { About } from '../domain/about';
import api from '../api';

export class AboutStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {

  }




}

export const aboutStore = new AboutStore();
(window as any).aboutStore = aboutStore;
