import { observable, action } from 'mobx';
import { Quiz } from '../domain/quiz';
import api from '../api';

export class QuizStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {}




}

export const quizStore = new QuizStore();
(window as any).quizStore = quizStore;
