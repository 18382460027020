// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { Line, Circle } from 'rc-progress';

import { FeedWallLeftPanelStore } from '../../stores/feed-wall-left-panel-store';
import { FeedWallStore } from '../../stores/feed-wall-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';
import { AchievementsStore } from '../../stores/achievements-store';
import { LeaderboardStore } from '../../stores/leaderboard-store';
import { ViewProfileStore } from '../../stores/view-profile-store';

import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import {Icon} from "../svg_icons/icon";

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  leaderboardStep: () => void;
  viewProfileStep: () => void;
  feedWallLeftPanelStore?: FeedWallLeftPanelStore;
  feedWallStore?: FeedWallStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  achievementsStore?: AchievementsStore;
  leaderboardStore?: LeaderboardStore;
  viewProfileStore?: ViewProfileStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'feedWallLeftPanelStore',
  'contributeStore',
  'loginStore',
  'feedWallStore',
  'achievementsStore',
  'leaderboardStore',
  'viewProfileStore'
)
@observer
export class FeedWallLeftPanel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.feedWallLeftPanelStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const feedWallLeftPanelStore = this.props.feedWallLeftPanelStore;
    const feedWallStore = this.props.feedWallStore;
    const achievementsStore = this.props.achievementsStore;
    const leaderboardStore = this.props.leaderboardStore;
    const viewProfileStore = this.props.viewProfileStore;

    return (
      <div>
        <div id="stuck-element" className='feed-wall-right-panel-standard profile-navigate-step'>
        <Row>
          <Col className="leader-board-lp-card-body-profile">
            <div>
              <img className='profile-picture' src='logo.png' alt="logo" height="60px" />
            </div>
            <h5 onClick={() => {
               this.props.profileStep();
             }}>{loginStore.username}
             </h5>
          </Col>
        </Row>
        {/*<Row>*/}
        {/*<Col>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Chat-Bubble-Text-Square--Streamline-Core"><desc>Chat Bubble Text Square Streamline Icon: https://streamlinehq.com</desc><g id="chat-bubble-text-square--messages-message-bubble-text-square-chat"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M2.5 0A1.5 1.5 0 0 0 1 1.5v8.919l-0.974 2.923a0.5 0.5 0 0 0 0.595 0.643L4.561 13H12.5a1.5 1.5 0 0 0 1.5 -1.5v-10A1.5 1.5 0 0 0 12.5 0h-10Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M4.5 4.22a0.625 0.625 0 1 0 0 1.25h6a0.625 0.625 0 1 0 0 -1.25h-6Zm0 3.31a0.625 0.625 0 1 0 0 1.25h4a0.625 0.625 0 1 0 0 -1.25h-4Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
        </Col>*/}
          {/*<Col className='level-point-progress-level'>
            Level {loginStore.level}
          </Col>*/}
          {/*<Col>
            <div title={parseFloat(loginStore.percentage.toString()).toFixed(2).toString() + '%'} >
              <Line percent={loginStore.percentage} strokeWidth={4} trailWidth={3} strokeColor="green" />
            </div>
          </Col>*/}
          {/*<Col className='level-point-progress-points'>
            <span title={parseInt(loginStore.points.toString()) + ' / ' + parseInt((loginStore.points_high).toString())}>
            Points {parseInt(loginStore.points.toString())}
            </span>
          </Col>*/}
        {/*</Row>*/}
        </div>
        <hr/>
        <div className="your-contributions-step">
        <b>Your contributions</b>
        <br />
        <Card className="leader-board-lp x-small-font">
        <CardBody className="card-padding-override">
        <Row className="leader-board-lp-row">
          <Col>
              <Icon id="lodge" text="Lodges added: "></Icon>
            <span>{loginStore.number_of_lodges}</span>
          </Col>
        </Row>
        <Row className="leader-board-lp-row">
          <Col>
              <Icon id="edits" text="Edits: "></Icon>
            <span>{loginStore.number_of_suggestions}</span>
          </Col>
        </Row>
        <Row className="leader-board-lp-row">
          <Col>
              <Icon id="votes" text="Votes: "></Icon>
            <span>{loginStore.number_of_votes}</span>
          </Col>
        </Row>
        <Row className="leader-board-lp-row">
          <Col>
              <Icon id="referrals" text="Referrals: "></Icon>
            <span>{loginStore.number_of_referrals}</span>
          </Col>
        </Row>
        <Row>
          <Col>
              <Icon id="attendance" text="Attendance: "></Icon>
            <span>{loginStore.number_of_total_visited}</span>
          </Col>
        </Row>
        </CardBody>
        </Card>
        </div>
        <hr/>
        <div className="top-three-leaderboard-step">
          {feedWallStore.userFeedTopThreeOnLeaderboard.length > 0 ? (
            <div>
            <span className="leader-board-lp-heading" onClick={async () => {
              await leaderboardStore.setAllLeaderboards(loginStore);
              this.props.leaderboardStep();
            }}>
            Leaderboard Top 3
            </span>
            {feedWallStore.userFeedTopThreeOnLeaderboard.map((user, index) => (
                 <Card className="leader-board-lp x-small-font">
                 <CardBody className="leader-board-lp-card-body pointing-cursor">
                 <Row onClick={async () => {
                    if(user.public_id != null) {
                      await this.props.viewProfileStore.setProfile(loginStore.miniToken, user.public_id);
                      this.props.viewProfileStep();
                    }
                 }}>
                   <Col>
                   <span>{user.rank}.</span>
                   <span>{user.name}</span>
                   </Col>
                 </Row>
                 </CardBody>
                 </Card>
             ))}
            </div>
            )
            :
            'default something'
          }
        </div>
      </div>
    );
  }
}

export default FeedWallLeftPanel;
