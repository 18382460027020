import * as React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Alert
} from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { ContributeStore } from '../../stores/contribute-store';

import { TextInput } from '../inputs/text';


interface Props {
  contributeStore?: ContributeStore;
}

export const ContributeModal: React.FC<Props> = inject('contributeStore')(
  observer((props: Props) => {
    if (!props.contributeStore) {
      return null;
    }

    const contributeModalInput = props.contributeStore.contributeModalInput;
    const modalValidationResult = props.contributeStore.modalValidationResult;

    return (
      <Modal
        className="shrink"
        isOpen={props.contributeStore.callMeModalOpen}
      >
        <ModalHeader
          toggle={() => props.contributeStore.toggleCallMeModal()}
        >
        Header
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Col sm="12">
              <TextInput
                label="First name*"
                invalid={!!modalValidationResult.first_name}
                validationMessage={modalValidationResult.first_name}
                value={contributeModalInput.first_name}
                onChange={e => {
                  contributeModalInput.first_name = e.target.value;
                }}
                id="first_name"
              />
            </Col>
            <Col sm="12">
              <TextInput
                label="Cellphone*"
                invalid={!!modalValidationResult.cellphone}
                validationMessage={modalValidationResult.cellphone}
                value={contributeModalInput.cellphone}
                onChange={e => {
                  contributeModalInput.cellphone = e.target.value;
                }}
                id="cellphone"
              />
            </Col>
            <Col sm="12">
              <TextInput
                label="Email*"
                invalid={!!modalValidationResult.email}
                validationMessage={modalValidationResult.email}
                value={contributeModalInput.email}
                onChange={e => {
                  contributeModalInput.email = e.target.value;
                }}
                id="email"
              />
            </Col>
            <Col sm="12">
              {props.contributeStore.callMeModalSuccess && (
                <Alert color="success">
                  Thank you for your request. A consultant will contact you
                  shortly.
                </Alert>
              )}
              {props.contributeStore.callMeModalError && (
                <Alert color="warning">
                  Something went wrong, please try again.
                </Alert>
              )}
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => props.contributeStore.toggleCallMeModal()}
          >
            Close
          </Button>
          <Button
            color="primary"
            onClick={() => {
              /*props.contributeStore.requestCall();*/
            }}
          >
            Call me
          </Button>
        </ModalFooter>
      </Modal>
    );
  })
);
