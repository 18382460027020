import { observable, action } from 'mobx';
import { Login } from '../domain/login';
import api from '../api';
//import bcrypt from 'bcryptjs';

import Cookies from 'js-cookie';

import { isLocalhost } from '../helpers/utilities';

export class LoginStore {
  @observable isLoading = false;
  @observable isLoggedIn = false;
  @observable authToken = '';
  @observable miniToken = '';
  @observable referral_code = '';
  @observable receival_code = '';
  @observable username = '';
  @observable email = '';
  @observable contact_number = '';
  @observable level = 0;
  @observable points = 0;
  @observable percentage = 0;
  @observable points_high = 0;
  @observable user_credits = 0.00;
  @observable sponsored_posts = [];
  // GLOBAL
  @observable enable_notifications = false;
  // DAILY
  @observable email_notification_daily_meetings = false;
  @observable all_lodge_notifications = false;
  @observable watch_list_notifications = false;
  @observable custom_notifications = false;
  @observable filter_constitutions = [];
  @observable all_socials_notifications = false;
  @observable day_socials_notifications = false;
  // WEEKLY
  @observable email_notification_weekly_meetings = false;
  @observable all_lodge_weekly_notifications = false;
  @observable watch_list_weekly_notifications = false;
  @observable custom_weekly_notifications = false;
  @observable filter_weekly_constitutions = [];
  @observable all_socials_weekly_notifications = false;
  @observable week_socials_weekly_notifications = false;
  // MONTHLY
  @observable email_notification_monthly_meetings = false;
  @observable all_lodge_monthly_notifications = false;
  @observable watch_list_monthly_notifications = false;
  @observable custom_monthly_notifications = false;
  @observable filter_monthly_constitutions = [];
  @observable all_socials_monthly_notifications = false;
  @observable month_socials_monthly_notifications = false;
  // REPORTS
  @observable email_notification_lodge_attendance_report = false;
  // SYSTEM
  @observable email_notification_for_suggestions = false;
  @observable email_notification_for_new_socials = false;
  //
  @observable display_on_leaderboard = false;
  @observable display_public_profile = false;
  @observable customize_feed = false;

  @observable last_login = '';
  @observable number_of_lodges = 0;
  @observable number_of_suggestions = 0;
  @observable number_of_votes = 0;
  @observable number_of_referrals = 0;
  @observable lodges = [];
  @observable lodge_data = [];
  @observable change_password = true;
  @observable two_fa = '';
  @observable number_of_locations_visited = 0;
  @observable number_of_lodges_visited = 0;
  @observable number_of_total_visited = 0;
  @observable visitationMeetings = [];
  @observable visitationMeetingsPast = [];
  @observable visitationMeetingsFuture = [];
  @observable visitationOtherUsersMeetingsFuture = [];

  @observable loginInput: Partial<Login> = {
    username: '',
    password: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];

  }

  validate = () => {
    const request = this.loginInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.username = (request.username == '')
        ? '* Required'
        : '';

    this.validationResult.password = (request.password == '')
        ? '* Required'
        : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
        ([_, value]) => !!value
    );
  };

  loginUser = async () => {
    console.log("isLocalhost()");
    console.log(isLocalhost());
    console.log("navigator.onLine");
    console.log(navigator.onLine);

    if (navigator.onLine || isLocalhost()) {
      console.log("!!!!!!!! we are here !!!");
    } else {
      return false;
    }
    //if (isLocalhost()) {
    this.isLoading = true;
    let result = null;
    try {

      let dat = {
        email: this.loginInput.username,
        password: this.loginInput.password
      };
      result = await api.loginUser(dat, null);
      //return true;
    }
    catch (error) {
      console.error(error);
      return {"status": false, "message": "incorrect_details"};
    }
    finally {

      console.log('======= loginUser =======');
      //this.editResults = result;
      console.log(result);
      if (result != null) {
        if (result.status === true) {
          this.setLoggedInStatus();
          this.authToken = result.authToken;
          this.miniToken = result.miniToken;
          this.referral_code = result.referral_code;
          this.receival_code = result.receival_code;
          this.username = result.username;
          this.email = result.email;
          this.contact_number = result.contact_number;
          this.level = result.level;
          this.points = result.points;
          this.percentage = result.percentage;
          this.points_high = result.points_high;
          this.user_credits = result.user_credits;
          this.sponsored_posts = result.sponsored_posts;
          // GLOBAL
          this.enable_notifications = result.enable_notifications;
          // DAILY
          this.email_notification_daily_meetings = result.email_notification_daily_meetings;
          this.all_lodge_notifications = result.all_lodge_notifications;
          this.watch_list_notifications = result.watch_list_notifications;
          this.custom_notifications = result.custom_notifications;
          this.filter_constitutions = result.filter_constitutions;
          this.all_socials_notifications = result.all_socials_notifications;
          this.day_socials_notifications = result.day_socials_notifications;
          // WEEKLY
          this.email_notification_weekly_meetings = result.email_notification_weekly_meetings;
          this.all_lodge_weekly_notifications = result.all_lodge_weekly_notifications;
          this.watch_list_weekly_notifications = result.watch_list_weekly_notifications;
          this.custom_weekly_notifications = result.custom_weekly_notifications;
          this.filter_weekly_constitutions = result.filter_weekly_constitutions;
          this.all_socials_weekly_notifications = result.all_socials_weekly_notifications;
          this.week_socials_weekly_notifications = result.week_socials_weekly_notifications;
          // MONTHLY
          this.email_notification_monthly_meetings = result.email_notification_monthly_meetings;
          this.all_lodge_monthly_notifications = result.all_lodge_monthly_notifications;
          this.watch_list_monthly_notifications = result.watch_list_monthly_notifications;
          this.custom_monthly_notifications = result.custom_monthly_notifications;
          this.filter_monthly_constitutions = result.filter_monthly_constitutions;
          this.all_socials_monthly_notifications = result.all_socials_monthly_notifications;
          this.month_socials_monthly_notifications = result.month_socials_monthly_notifications;
          // REPORTS
          this.email_notification_lodge_attendance_report = result.email_notification_lodge_attendance_report;
          // SYSTEM
          this.email_notification_for_suggestions = result.email_notification_for_suggestions;
          this.email_notification_for_new_socials = result.email_notification_for_new_socials;
          //
          this.display_on_leaderboard = result.display_on_leaderboard;
          this.display_public_profile = result.display_public_profile;
          this.customize_feed = result.customize_feed;

          this.last_login = result.last_login;
          this.number_of_lodges = result.number_of_lodges;
          this.number_of_suggestions = result.number_of_suggestions;
          this.number_of_votes = result.number_of_votes;
          this.number_of_referrals = result.number_of_referrals;
          this.lodges = result.lodges;
          this.lodge_data = result.lodge_data;
          //console.log('090909090900090909');
          //console.log(result.lodge_data);
          this.change_password = result.change_password;
          this.two_fa = result["2fa"];

          this.number_of_locations_visited = result.number_of_locations_visited;
          this.number_of_lodges_visited = result.number_of_lodges_visited;
          this.number_of_total_visited = result.number_of_total_visited;
          this.visitationMeetings = [...result.futureVisits, ...result.pastVisits];
          this.visitationMeetingsPast = result.pastVisits;
          this.visitationMeetingsFuture = result.futureVisits;
          this.visitationOtherUsersMeetingsFuture = result.otherUsersVisits;

          //Cookies.set('test_logged_in', 'true');

          return true;
        } else {
          //Cookies.remove('test_logged_in');
          this.setLoggedOutStatus();
        }
        console.log('============================');
      } else {
        this.setLoggedOutStatus();
      }

      this.isLoading = false;
      this.loginInput.password = "";
    }
  };

  setLoggedInStatus = () => {
    this.isLoggedIn = true;
    console.log('changed logged in status to true');
  };

  setLoggedOutStatus = () => {
    this.isLoggedIn = false;
    console.log('changed logged in status to false');
  };


}

export const loginStore = new LoginStore();
(window as any).loginStore = loginStore;
