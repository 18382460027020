import * as React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Alert
} from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { ProfileStore } from '../../stores/profile-store';
import { LoginStore } from '../../stores/login-store';

import { TextInput, TextArea } from '../inputs/text';

interface Props {
  profileStore?: ProfileStore;
  loginStore?: LoginStore;
}

export const UserPostModal: React.FC<Props> = inject('profileStore', 'loginStore')(
  observer((props: Props) => {
    if (!props.profileStore) {
      return null;
    }

    const userPostModalInput = props.profileStore.userPostModalInput;
    const modalValidationResult = props.profileStore.modalValidationResult;

    const profileStore = props.profileStore;
    const loginStore = props.loginStore;

    const validateFileType = (event) => {
       var selectedFile = event.target.files[0]; //document.getElementById('fileInput').files[0];
       var allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'audio/mpeg', 'video/mp4'];

       if (!allowedTypes.includes(selectedFile.type)) {
          alert('Invalid file type. Please upload a JPEG, PNG, MP3, MP4, or PDF file.');
          //document.getElementById('fileInput').value = '';
          return false;
       } else {
         return true;
       }
    }

    const handleFileChange = (event) => {
        if (validateFileType(event))
        {
          let base64 = null;
          //console.log('! handleFileChange');
          const file = event.target.files[0];
          // Process the file
          console.log(file);

          // Create FormData object
          const formData = new FormData();
          formData.append('file', file);

          console.log(formData);
          console.log(formData);
          props.profileStore.userPostModalInput.image_file_name = file.name;
          console.log("userPostModalInput.image_file_name:", props.profileStore.userPostModalInput.image_file_name);

          // Read the file data
          const reader = new FileReader();
          reader.onload = function(event) {
            const fileData = event.target.result as string;
            //console.log("File data:", fileData);
            // Encode file data as base64
            base64 = btoa(fileData);
            //console.log("Base64 encoded:", base64);
            props.profileStore.userPostModalInput.image_file_data = base64;
            //console.log("userPostModalInput.image_data:", props.profileStore.userPostModalInput.image_data);
          };

          // Assuming 'file' is a reference to the File object
          //reader.readAsText(file);
          //reader.readAsArrayBuffer(file);
          reader.readAsBinaryString(file);
        }
        else {
          props.profileStore.userPostModalInput.image_file_name = "";
          profileStore.toggleUserPostModal();
        }
    };



    return (
      <Modal
        className="shrink"
        isOpen={props.profileStore.userPostModalOpen}
      >
        <ModalHeader
          toggle={() => props.profileStore.toggleUserPostModal()}
        >
        Create post
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Col>
              <img src="logo.png" alt="logo" height="30px" onClick={() => {
               }}/> {loginStore.username}
            </Col>
            <Col sm="12">
              <div>
               <TextArea
                 id="input"
                 label=""
                 placeholder={"What's on your mind, " + loginStore.username.substring(0, loginStore.username.indexOf(' ')) + "\?"}
                 value={userPostModalInput.feed_input}
                 onChange={e =>
                   (userPostModalInput.feed_input = e.target.value)
                 }
                 invalid={!!modalValidationResult.feed_input}
                 validationMessage={modalValidationResult.feed_input}
               />
              </div>
            </Col>
            <Col>
              <input type="file" onChange={handleFileChange} />
            </Col>
            <Col sm="12">
              {props.profileStore.userPostModalSuccess && (
                <Alert color="success">
                  Thank you for posting. Your post might take up to an hour to verify.
                </Alert>
              )}
              {props.profileStore.userPostModalError && (
                <Alert color="warning">
                  Something went wrong, please try again. If this persists please contact <a href="mailto:info@freemason-alpha.com">info@freemason-alpha.com</a>
                </Alert>
              )}
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            style={{
              width: '100%'
            }}
            onClick={async () => {
              let res = await profileStore.postToUserProfileFeed(loginStore.miniToken, userPostModalInput.feed_input);
              console.log("res");
              console.log(res);
              console.log("resser");
              if (res) {
                //profileStore.toggleUserPostModal();
                userPostModalInput.feed_input = '';
                userPostModalInput.image_file_name = '';
                userPostModalInput.image_file_data = '';
              }
            }}
          >
            Post
          </Button>
        </ModalFooter>
      </Modal>
    );
  })
);
