// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { SuggestEditLodgeStore } from '../../stores/suggest-edit-lodge-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  suggestEditLodgeStore?: SuggestEditLodgeStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('suggestEditLodgeStore', 'configurationStore')
@observer
export class SuggestEditLodge extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.suggestEditLodgeStore) {
      return;
    }

    const suggestEditLodgeStore = this.props.suggestEditLodgeStore;

    const lodgeInput = suggestEditLodgeStore.lodgeInput;
    const validationResult = suggestEditLodgeStore.validationResult;

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Lodge Details Edit</u></h4>
          </Col>
        </Row>

          <Card className='post-option-active'>
              <CardBody>

                <Row>
                  <Col>
                    <TextInput
                      id="name"
                      label="Lodge Name"
                      value={lodgeInput.name}
                      onChange={e =>
                        (lodgeInput.name = e.target.value)
                      }
                      invalid={!!validationResult.name}
                      validationMessage={validationResult.name}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      id="number"
                      label="Lodge Number"
                      value={lodgeInput.number}
                      onChange={e =>
                        (lodgeInput.number = e.target.value)
                      }
                      invalid={!!validationResult.number}
                      validationMessage={validationResult.number}
                    />
                  </Col>
                </Row>

                <Row>
                    <Col>
                    <SelectInput
                      id='country'
                      label='Country'
                      invalid={!!validationResult.country}
                      validationMessage={validationResult.country}
                      value={lodgeInput.country}
                      onChange={e => {
                        suggestEditLodgeStore.lodgeInput.country = e.target.value;
                        configurationStore.filterAddresses(configurationStore.addressResults, suggestEditLodgeStore.lodgeInput.country);
                        configurationStore.filterConstitutions(configurationStore.constitutionResults, suggestEditLodgeStore.lodgeInput.country);
                        suggestEditLodgeStore.lodgeInput.address_id = '';
                      }}
                      options={configurationStore.countryResults}
                    />
                    </Col>
                    <Col>
                    <SelectInput
                      id='constitution'
                      label='Constitution'
                      invalid={!!validationResult.constitution}
                      validationMessage={validationResult.constitution}
                      value={lodgeInput.constitution}
                      onChange={e => {
                        suggestEditLodgeStore.lodgeInput.constitution = e.target.value;
                        configurationStore.filterDistricts(configurationStore.districtResults, suggestEditLodgeStore.lodgeInput.country, suggestEditLodgeStore.lodgeInput.constitution);
                      }}
                      options={configurationStore.filteredConstitutions}
                    />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <SelectInput
                      id='district'
                      label='Jurisdiction'
                      invalid={!!validationResult.district}
                      validationMessage={validationResult.district}
                      value={lodgeInput.district}
                      onChange={e => {
                        suggestEditLodgeStore.lodgeInput.district = e.target.value;
                      }}
                      options={configurationStore.filteredDistricts}
                    />
                    </Col>
                    <Col>
                    <SelectInput
                      id='degree'
                      label='Order'
                      invalid={!!validationResult.degree}
                      validationMessage={validationResult.degree}
                      value={lodgeInput.degree}
                      onChange={e => {
                        suggestEditLodgeStore.lodgeInput.degree = e.target.value;
                      }}
                      options={configurationStore.degreeResults}
                    />
                    </Col>
                  </Row>

                <Row>
                  <Col>
                  <SelectInput
                    id='language'
                    label='Language'
                    invalid={!!validationResult.language}
                    validationMessage={validationResult.language}
                    value={lodgeInput.language}
                    onChange={e => {
                      suggestEditLodgeStore.lodgeInput.language = e.target.value;
                    }}
                    options={configurationStore.languageResults}
                  />
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <SearchableSelectInput
                    id='address'
                    label='Address'
                    invalid={!!validationResult.address}
                    validationMessage={validationResult.address}
                    value={lodgeInput.address_id}
                    onChange={(selectedOption) => {
                      if (selectedOption != null) {
                        suggestEditLodgeStore.lodgeInput.address_id = selectedOption.value; // Set the address_id using the selected option's value property
                      }
                    }}
                    options={configurationStore.filteredAddresses}
                  />
                </Col>
              </Row>

                <br/>
                <Button color="" onClick={() => {
                  configurationStore.filterDescriptions(configurationStore.descriptionResults, lodgeInput.degree);
                  this.props.nextStep();
                }}
                >
                    <Icon id="continue" text="Next"></Icon>
                </Button>
                <Button color="" onClick={this.props.lodgesStep}>
                    <Icon id="back" text="Back"></Icon>
                </Button>
              </CardBody>
          </Card>

      </div>
    );
  }
}

export default SuggestEditLodge;
