// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { SuggestEditContactStore } from '../../stores/suggest-edit-contact-store';

import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  suggestEditContactStore?: SuggestEditContactStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('suggestEditContactStore')
@observer
export class SuggestEditContact extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.suggestEditContactStore) {
      return;
    }

    const suggestEditContactStore = this.props.suggestEditContactStore;

    const contactInput = suggestEditContactStore.contactInput;
    const validationResult = suggestEditContactStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Contact Details Edit</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>

        <Row>
          {/*<Col sm='2'>
            <TextInput
              id="title"
              label="Title"
              value={contactInput.title}
              onChange={e =>
                (contactInput.title = e.target.value)
              }
              invalid={!!validationResult.title}
              validationMessage={validationResult.title}
            />
          </Col>*/}
          <Col>
            <TextInput
              id="name"
              label="Name"
              value={contactInput.name}
              onChange={e =>
                (contactInput.name = e.target.value)
              }
              invalid={!!validationResult.name}
              validationMessage={validationResult.name}
            />
          </Col>
          <Col>
            <TextInput
              id="surname"
              label="Surname"
              value={contactInput.surname}
              onChange={e =>
                (contactInput.surname = e.target.value)
              }
              invalid={!!validationResult.surname}
              validationMessage={validationResult.surname}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextInput
              id="contact_number"
              label="Contact Number"
              value={contactInput.contact_number}
              onChange={e =>
                (contactInput.contact_number = e.target.value)
              }
              invalid={!!validationResult.contact_number}
              validationMessage={validationResult.contact_number}
            />
          </Col>
          <Col>
            <TextInput
              id="contact_number_second"
              label="Backup Contact Number"
              value={contactInput.contact_number_second}
              onChange={e =>
                (contactInput.contact_number_second = e.target.value)
              }
              invalid={!!validationResult.contact_number_second}
              validationMessage={validationResult.contact_number_second}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextInput
              id="website"
              label="Website"
              value={contactInput.website}
              onChange={e =>
                (contactInput.website = e.target.value)
              }
              invalid={!!validationResult.website}
              validationMessage={validationResult.website}
            />
          </Col>
          <Col>
            <TextInput
              id="email"
              label="Email"
              value={contactInput.email}
              onChange={e =>
                (contactInput.email = e.target.value)
              }
              invalid={!!validationResult.email}
              validationMessage={validationResult.email}
            />
          </Col>
        </Row>

        <br/>
        <Button color="" onClick={this.props.nextStep}>
          <Icon id="continue" text="Next"></Icon>
        </Button>
        <Button color="" onClick={this.props.previousStep}>
          <Icon id="back" text="Back"></Icon>
        </Button>

            </CardBody>
          </Card>

      </div>
    );
  }
}

export default SuggestEditContact;
