// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {districtTitleSwitch} from '../../helpers/utilities';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import {Icon} from "../svg_icons/icon";


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  addLodgeStore?: AddLodgeStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('addLodgeStore', 'configurationStore')
@observer
export class AddLodge extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.addLodgeStore) {
      return;
    }

    const addLodgeStore = this.props.addLodgeStore;
    //console.log("------- addLodgeStore.lodgeResults -------");
    //console.log(addLodgeStore.lodgeResults);
    //console.log("---------------------------------------");

    const lodgeInput = addLodgeStore.lodgeInput;
    const validationResult = addLodgeStore.validationResult;

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Add Lodge</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <Row>
          <Col>
            <TextInput
              id="name"
              label="Lodge Name"
              value={lodgeInput.name}
              onChange={e =>
                (lodgeInput.name = e.target.value)
              }
              invalid={!!validationResult.name}
              validationMessage={validationResult.name}
            />
          </Col>
          <Col>
            <TextInput
              id="number"
              label="Lodge Number"
              value={lodgeInput.number}
              onChange={e =>
                (lodgeInput.number = e.target.value)
              }
              invalid={!!validationResult.number}
              validationMessage={validationResult.number}
            />
          </Col>
        </Row>

        <Row>
            <Col>
            <SelectInput
              id='country'
              label='Country'
              invalid={!!validationResult.country}
              validationMessage={validationResult.country}
              value={lodgeInput.country}
              onChange={e => {
                addLodgeStore.lodgeInput.country = e.target.value;
                configurationStore.filterAddresses(configurationStore.addressResults, addLodgeStore.lodgeInput.country);
                configurationStore.filterConstitutions(configurationStore.constitutionResults, addLodgeStore.lodgeInput.country);
                addLodgeStore.lodgeInput.constitution = '';
                addLodgeStore.lodgeInput.district = '';
                configurationStore.filteredDistricts = [];
                addLodgeStore.lodgeInput.address = '';
              }}
              options={configurationStore.countryResults}
            />
            </Col>
            <Col>
            <SelectInput
              id='constitution'
              label='Constitution'
              invalid={!!validationResult.constitution}
              validationMessage={validationResult.constitution}
              value={lodgeInput.constitution}
              onChange={e => {
                addLodgeStore.lodgeInput.constitution = e.target.value;//.split("_", 1);
                configurationStore.filterDistricts(configurationStore.districtResults, addLodgeStore.lodgeInput.country, addLodgeStore.lodgeInput.constitution.split('_', 1)[0]);
                addLodgeStore.lodgeInput.district = '';
                addLodgeStore.lodgeInput.address = '';
              }}
              options={configurationStore.filteredConstitutions}
            />
            </Col>
          </Row>
          <Row>
          <Col>
          <SelectInput
            id='district'
            label={districtTitleSwitch(addLodgeStore.lodgeInput.country, addLodgeStore.lodgeInput.constitution.split('_', 1)[0], configurationStore.filteredConstitutions)}
            invalid={!!validationResult.district}
            validationMessage={validationResult.district}
            value={lodgeInput.district}
            onChange={e => {
              addLodgeStore.lodgeInput.district = e.target.value;//.split("_", 1);
            }}
            options={configurationStore.filteredDistricts}
          />
          </Col>
            <Col>
            <SelectInput
              id='degree'
              label='Order'
              invalid={!!validationResult.degree}
              validationMessage={validationResult.degree}
              value={lodgeInput.degree}
              onChange={e => {
                addLodgeStore.lodgeInput.degree = e.target.value;
              }}
              options={configurationStore.degreeResults}
            />
            </Col>
          </Row>

        <Row>
          <Col>
          <SelectInput
            id='language'
            label='Language'
            invalid={!!validationResult.language}
            validationMessage={validationResult.language}
            value={lodgeInput.language}
            onChange={e => {
              addLodgeStore.lodgeInput.language = e.target.value;
            }}
            options={configurationStore.languageResults}
          />
          </Col>
        </Row>

        <Row>
          <Col>
          <SearchableSelectInput
            id='address'
            label='Address'
            invalid={!!validationResult.address}
            validationMessage={validationResult.address}
            value={lodgeInput.address}
            onChange={(selectedOption) => {
              if (selectedOption != null) {
                addLodgeStore.lodgeInput.address = selectedOption.value;
              }
            }}
            options={configurationStore.filteredAddresses}
          />
        </Col>
      </Row>
      </CardBody>
      </Card>


        <Button
        color=""
        onClick={() => {
          addLodgeStore.validate();
          const success = addLodgeStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            this.props.nextStep();
          }
        }}
        >
            <Icon id="continue" text="Next"></Icon>
        </Button>
        <Button color="" onClick={this.props.lodgesStep}>
            <Icon id="back" text="Back"></Icon>
        </Button>
      </div>
    );
  }
}

export default AddLodge;
